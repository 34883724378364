import { render, staticRenderFns } from "./card-header.vue?vue&type=template&id=1891318c"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/vsts/work/1/s/components/app/header.vue').default})
