export const state = () => ({
  pageSource: null
})

export const actions = {
  async SET_PAGESOURCE({ commit }, source) {
    await commit('SET_PAGESOURCE', source)
  }
}

export const mutations = {
  SET_PAGESOURCE(state, source) {
    state.pageSource = source
  }
}
