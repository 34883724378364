
export default {
  props: {
    variant: {
      type: String,
      default: 'light',
      validator: function (value) {
        return ['light', 'dark'].includes(value)
      }
    }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
