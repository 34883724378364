import isBot from '~/lib/isBot.js'
import { logDev } from '~/lib/dev-logging.js'

export default function ({ $external, $config, $device }, inject) {
  inject('geocode', async (searchAddress) => {
    // Stop the bleed: Prevent Bots to geocoding SearchAddress query params on RV links on POI pages
    // isBot is more flexible than isCrawler
    if (!searchAddress || isBot($device.userAgent)) {
      return null
    }

    logDev({ domain: 'plugin', func: 'geocode', message: '$external.get', color: 'red' })

    const { results } = await $external.$get(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${$config.googleMapsApiKey}&address=${encodeURIComponent(
        searchAddress
      )}&fields=formatted_address,name,geometry,types`
    )

    return results?.[0]
  })
}
