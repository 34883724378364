import Vue from 'vue'

import useGoogleMaps from '~/lib/useGoogleMaps'
import {
  searchFilters,
  extractSearchQuery,
  boundsToLocation,
  geolocationToLocation,
  queryStringToBounds,
  queryStringToDates,
  queryStringToGuests,
  queryStringToSearchFilters,
  queryStringToSortOrder,
  searchParametersToSearchQuery,
  searchParametersToApiRequest,
  searchCriteriaToSearchParameters
} from '~/lib/search/parameters.js'
import { parseGooglePlace, parseGoogleGeocode } from '~/lib/search/google.js'
import { logDev } from '~/lib/dev-logging.js'

export default async (
  { beforeNuxtRender, query, nuxtState, app, $config, $cookies, $geolocation, $geocode },
  inject
) => {
  const preventNavigationDuplicatedAndGoTo = ({ path = '', query = '' }) => {
    const newPath = path || app.router.currentRoute.path
    const newQuery = query ? `?${query}` : ''
    const newRoute = `${newPath}${newQuery}`

    if (app.router.currentRoute.fullPath === newRoute) {
      return
    }

    if (path) {
      app.router.push(newRoute)
    } else {
      app.router.replace(newRoute)
    }
  }

  const updateRoute = (path) => {
    const searchQuery = searchParametersToSearchQuery(nuxtState.searchParameters)
    const params = { ...searchQuery }
    params.Amenities = params.Amenities.length ? params.Amenities.join(',') : undefined
    params.Types = params.Types.length ? params.Types.join(',') : undefined

    const filtered = Object.keys(params)
      .filter((x) => params[x] != null)
      .reduce((acc, x) => {
        if (params[x] != null && params[x] !== '') acc[x] = String(params[x])
        return acc
      }, {})

    const query = new URLSearchParams(filtered).toString().replace(/=true(&|$)/g, '$1')

    const updateRoute = path && app.router.currentRoute.path !== path
    if (updateRoute) {
      preventNavigationDuplicatedAndGoTo({ path, query })
    } else {
      preventNavigationDuplicatedAndGoTo({ query })
    }
  }

  const clearBounds = () => {
    nuxtState.searchParameters.bounds.hasBounds = false
    nuxtState.searchParameters.bounds.east = undefined
    nuxtState.searchParameters.bounds.west = undefined
    nuxtState.searchParameters.bounds.north = undefined
    nuxtState.searchParameters.bounds.south = undefined
  }

  const updateBounds = ({ east, west, north, south }) => {
    if ((east || west) && (north || south)) {
      nuxtState.searchParameters.bounds.hasBounds = true
      nuxtState.searchParameters.bounds.east = east
      nuxtState.searchParameters.bounds.west = west
      nuxtState.searchParameters.bounds.north = north
      nuxtState.searchParameters.bounds.south = south

      const location = boundsToLocation({ fullName: '', bounds: nuxtState.searchParameters.bounds })
      updateLocationFields(location)
    }
  }

  /**
   * Temporary cookie to store location and share with Nuxt 3
   */
  const saveSearchLocationCookie = (location) => {
    $cookies.set('search-location', location, {
      domain: $config.cookieDomain,
      path: '/'
    })
  }

  const updateLocationFields = (
    {
      searchable = false,
      placeId = undefined,
      mainText = undefined,
      secondaryText = undefined,
      types = [],
      center = undefined,
      bounds = undefined,
      city = undefined,
      region = undefined,
      country = undefined,
      fullName = undefined
    },
    searchParameters = nuxtState.searchParameters
  ) => {
    searchParameters.location.searchable = searchable
    searchParameters.location.placeId = placeId
    searchParameters.location.mainText = mainText
    searchParameters.location.secondaryText = secondaryText
    searchParameters.location.types = types
    searchParameters.location.center = center
    searchParameters.location.bounds = bounds
    searchParameters.location.city = city
    searchParameters.location.region = region
    searchParameters.location.country = country
    searchParameters.location.fullName = fullName

    saveSearchLocationCookie(searchParameters.location)
  }

  const geocodedGooglePlace = async (placeId) => {
    if (!placeId) {
      return
    }

    const { geocoder } = await useGoogleMaps($config.googleMapsApiKey, true)
    const [geocodedPlace] = await geocoder({ placeId })

    if (geocodedPlace) {
      return parseGoogleGeocode({ geocode: geocodedPlace })
    }

    return
  }

  const clearLocation = () => {
    updateLocationFields({})
    clearBounds()
  }

  const updateDates = ({ start = null, end = null }) => {
    nuxtState.searchParameters.dates.dates.start = start
    nuxtState.searchParameters.dates.dates.end = end
  }

  const updateGuests = ({ adults = undefined, children = undefined, petFriendly = false }) => {
    nuxtState.searchParameters.guests.adults = adults
    nuxtState.searchParameters.guests.children = children
    nuxtState.searchParameters.filters.petFriendly = petFriendly
  }

  const closeDiscoverDelivery = () => {
    nuxtState.showDiscoverDelivery.value = false

    const expires = new Date()
    expires.setDate(expires.getDate() + 60)

    $cookies.set('discoverDeliveryTooltip', 1, {
      path: '/',
      expires
    })
  }

  const updateFilters = (filters) => {
    // Looks like Vue.Observable only look at values form initially set keys.
    // This doesn't work.
    // nuxtState.searchParameters.filters = filters

    // Have to loop through each parameters to update
    Object.entries(searchFilters).forEach(([parameter, defaultValue]) => {
      updateFilter(parameter, filters[parameter] ?? defaultValue)
    })
  }

  const updateFilter = (key, value) => {
    let formattedValue = value

    // Reset Range values when values are defaults
    const emptyRange = {
      min: undefined,
      max: undefined
    }

    const defaultMinRvYear = new Date().getUTCFullYear() - 20
    const defaultMaxRvYear = new Date().getUTCFullYear() + 1

    switch (key) {
      case 'rvPrice':
        if (value.min === 0 && value.max === 500) {
          formattedValue = emptyRange
        }
        break

      case 'rvWeight':
        if (value.min === 0 && value.max === 20000) {
          formattedValue = emptyRange
        }
        break

      case 'rvLength':
        if (value.min === 0 && value.max === 50) {
          formattedValue = emptyRange
        }
        break

      case 'rvYear':
        if (value.min === defaultMinRvYear && value.max === defaultMaxRvYear) {
          formattedValue = emptyRange
        }
        break
    }

    nuxtState.searchParameters.filters[key] = formattedValue

    if (key === 'delivery' && formattedValue) {
      closeDiscoverDelivery()
    }
  }

  const updateSortOrder = (value) => {
    // Added a nested value, because first level values aren't reactive
    nuxtState.searchParameters.sort.value = value
  }

  const updateParameters = async ({ location, dates, guests, filters }) => {
    if (location) {
      const geocode = await geocodedGooglePlace(location.placeId)

      if (geocode) {
        updateLocationFields({
          searchable: location?.searchable,
          placeId: geocode.placeId,
          mainText: location.mainText ?? geocode.mainText,
          secondaryText: location.secondaryText,
          types: location?.types ?? geocode?.types,
          center: geocode?.center,
          bounds: geocode?.bounds,
          city: geocode?.city,
          region: geocode?.region,
          country: geocode?.country,
          fullName: location?.fullName ?? geocode?.fullName
        })
      }
    } else {
      updateLocationFields({})
    }

    clearBounds()

    if (dates) {
      updateDates(dates)
    }

    if (guests) {
      updateGuests(guests)
    }

    if (filters) {
      updateFilter('delivery', filters.delivery)
      updateFilter('drivable', filters.drivable)
      updateFilter('towable', filters.towable)
    }
  }

  const applyMenuFilter = () => {
    nuxtState.isMenuFilterApplied.value = true
  }

  const resetMenuFilter = () => {
    nuxtState.isMenuFilterApplied.value = false
  }

  if (process.server) {
    const searchQuery = extractSearchQuery(query)

    // const location = queryStringToLocation(searchQuery)
    const bounds = queryStringToBounds(searchQuery)

    const searchParameters = {
      bounds,
      dates: queryStringToDates(searchQuery),
      guests: queryStringToGuests(searchQuery),
      filters: queryStringToSearchFilters(searchQuery),
      sort: queryStringToSortOrder(searchQuery)
    }

    const searchLocationCookie = $cookies.get('search-location')

    // Priority for setting location
    // 1. Set bounding coords (neLat, neLng, swLat, swLng)
    if (bounds?.hasBounds) {
      searchParameters.location = boundsToLocation({ fullName: '', bounds })
    } else if (searchQuery.SearchAddress && !searchLocationCookie?.searchable) {
      logDev({ domain: 'plugin', func: 'search', message: 'geocode (query)', color: 'red' })
      // 2. Set location from searchAdress
      const geocodedAddress = await $geocode(searchQuery.SearchAddress)
      if (geocodedAddress) {
        const location = parseGoogleGeocode({ fullName: searchQuery.SearchAddress, geocode: geocodedAddress })
        searchParameters.location = location
      }
    } else {
      if (searchLocationCookie?.searchable) {
        logDev({ domain: 'plugin', func: 'search', message: 'cookie', color: 'red' })
        searchParameters.location = searchLocationCookie
      } else {
        logDev({ domain: 'plugin', func: 'search', message: 'geocode (geolocation)', color: 'red' })
        // 3. Set location from geolocation position
        searchParameters.location = geolocationToLocation($geolocation)

        saveSearchLocationCookie(searchParameters.location)
      }
    }

    const showDiscoverDelivery = {
      // Deactivating DiscoverDelivery to avoid conflicts with HighlightBanner - RVZ-19430
      // value: Boolean(!$cookies.get('discoverDeliveryTooltip') && !searchParameters.filters.delivery)
      value: false
    }

    const isMenuFilterApplied = {
      value: false
    }

    beforeNuxtRender(({ nuxtState }) => {
      nuxtState.searchParameters = searchParameters
      nuxtState.showDiscoverDelivery = showDiscoverDelivery
      nuxtState.isMenuFilterApplied = isMenuFilterApplied
    })

    inject('search', {
      parameters: searchParameters,
      showDiscoverDelivery,
      isMenuFilterApplied,
      clearBounds,
      updateLocationFields: (params) => updateLocationFields(params, searchParameters),
      searchParametersToApiRequest: () => searchParametersToApiRequest(searchParameters),
      searchCriteriaToSearchParameters: (searchCriteria) =>
        searchCriteriaToSearchParameters(searchCriteria, searchParameters)
    })
  } else {
    const searchParameters = Vue.observable(nuxtState.searchParameters)
    const showDiscoverDelivery = Vue.observable(nuxtState.showDiscoverDelivery)
    const isMenuFilterApplied = Vue.observable(nuxtState.isMenuFilterApplied)

    inject('search', {
      parameters: searchParameters,
      showDiscoverDelivery,
      isMenuFilterApplied,
      applyMenuFilter,
      resetMenuFilter,
      closeDiscoverDelivery,
      updateRoute,
      updateParameters,
      clearBounds,
      updateBounds,
      clearLocation,
      updateLocationFields,
      updateDates,
      updateGuests,
      updateFilters,
      updateFilter,
      updateSortOrder,
      searchParametersToApiRequest: () => searchParametersToApiRequest(searchParameters),
      searchCriteriaToSearchParameters: (searchCriteria) =>
        searchCriteriaToSearchParameters(searchCriteria, searchParameters),
      parseGooglePlace
    })
  }
}
