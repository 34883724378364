import Vue from 'vue'
import { screenSizeBreakpoints } from '~/lib/screenSizeBreakpoints'

export default ({ $device }, inject) => {
  const breakpoints = [
    {
      key: 'isSmallBreakpoint',
      screenWidth: screenSizeBreakpoints.small,
      defaultValue: $device.isMobileOrTablet
    },
    {
      key: 'isMediumBreakpoint',
      screenWidth: screenSizeBreakpoints.medium,
      defaultValue: $device.isDesktopOrTablet
    },
    {
      key: 'isLargeBreakpoint',
      screenWidth: screenSizeBreakpoints.large,
      defaultValue: $device.isDesktopOrTablet
    },
    {
      key: 'isXlargeBreakpoint',
      screenWidth: screenSizeBreakpoints.xLarge,
      defaultValue: $device.isDesktop
    }
  ]

  let mediaQueries = {
    breakpointName: ''
  }

  breakpoints.forEach(({ key, defaultValue }) => {
    mediaQueries[key] = defaultValue
  })

  function setCurrentBreakbointName() {
    if (mediaQueries.isXlargeBreakpoint) {
      return 'xLarge'
    } else if (mediaQueries.isLargeBreakpoint) {
      return 'large'
    } else if (mediaQueries.isMediumBreakpoint) {
      return 'medium'
    } else if (mediaQueries.isSmallBreakpoint) {
      return 'small'
    }
    return 'xSmall'
  }

  function setMediaQueryValue(key, value) {
    mediaQueries[key] = value
    mediaQueries.breakpointName = setCurrentBreakbointName()
  }

  if (process.client) {
    mediaQueries = Vue.observable(mediaQueries)

    breakpoints.forEach(({ key, screenWidth }) => {
      const mediaQuery = window.matchMedia(`(min-width: ${screenWidth}px)`)

      setMediaQueryValue(key, mediaQuery.matches)

      if (mediaQuery?.addEventListener) {
        mediaQuery.addEventListener('change', (e) => setMediaQueryValue(key, e.matches), true)
      } else {
        mediaQuery.addListener((e) => setMediaQueryValue(key, e.matches))
      }
    })
  } else {
    mediaQueries.breakpointName = setCurrentBreakbointName()
  }

  inject('mediaQueries', mediaQueries)
}
