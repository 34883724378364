
export default {
  computed: {
    maintenanceMode() {
      return this.$experiment.isEnabled('maintenanceMode') && !['experiments'].includes(this.getRouteBaseName())
    }
  },

  beforeMount() {
    if (this.maintenanceMode) {
      document.title = this.$i18n.t('pages.maintenance.metaTitle')
    }
  }
}
