import { Cookies } from '~/lib/enums'

export const actions = {
  async nuxtServerInit({ dispatch }, { $captureError, $config, $redis, $axios, $cookies }) {
    if ($cookies.get(Cookies.Currency)) {
      dispatch('currency/SET_CURRENCY', $cookies.get(Cookies.Currency))
    }

    if ($config.canGetReviewsFile)
      await dispatch('verifiedReview/SET_AGGREGATE_REVIEW', { $axios, $captureError, $redis })
  }
}
