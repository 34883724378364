const middleware = {}

middleware['maintenanceMode'] = require('../middleware/maintenanceMode.js')
middleware['maintenanceMode'] = middleware['maintenanceMode'].default || middleware['maintenanceMode']

middleware['modals'] = require('../middleware/modals.js')
middleware['modals'] = middleware['modals'].default || middleware['modals']

middleware['redirectToNuxt3App'] = require('../middleware/redirectToNuxt3App.js')
middleware['redirectToNuxt3App'] = middleware['redirectToNuxt3App'].default || middleware['redirectToNuxt3App']

middleware['searchParameters'] = require('../middleware/searchParameters.js')
middleware['searchParameters'] = middleware['searchParameters'].default || middleware['searchParameters']

middleware['setSessionInfo'] = require('../middleware/setSessionInfo.js')
middleware['setSessionInfo'] = middleware['setSessionInfo'].default || middleware['setSessionInfo']

export default middleware
