
import appDownload from '@/mixins/app-download'

export default {
  mixins: [appDownload],

  props: {
    btnSize: {
      type: String,
      default: 'small'
    }
  },

  computed: {
    imgSize() {
      switch (this.btnSize) {
        case 'large':
          return {
            width: '189',
            height: '56'
          }
        case 'medium':
          return {
            width: '162',
            height: '48'
          }
        default:
          return {
            width: '108',
            height: '32'
          }
      }
    },

    playStoreButtonImage() {
      return {
        src: `${this.$config.staticAssetsURL}/web-app/sept-2019/GooglePlay.webp`,
        alt: this.$t('appDownloadBanner.googlePlayAction'),
        ...this.imgSize
      }
    },

    iTunesButtonImage() {
      return {
        src: `${this.$config.staticAssetsURL}/web-app/sept-2019/App_Store_Badge.webp`,
        alt: this.$t('appDownloadBanner.iTunesAction'),
        ...this.imgSize
      }
    }
  }
}
