import useBraze from '~/lib/useBraze'
import { trackUserAuthentication } from '~/lib/tracking'

export default ({ store, $config, $isServer }, inject) => {
  inject('identifyUser', async (user) => {
    if (!$isServer && user) {
      trackUserAuthentication({ user })

      // Braze
      let userID = user.Id
      if ($config.braze.userPrefix) {
        userID = `${$config.braze.userPrefix}_${user.Id}`
      }

      const { setUser } = await useBraze()
      setUser(userID)
    }
  })

  inject('getSessionID', () => {
    return store.state.me.rtSessionId
  })

  inject('setSessionID', async () => {
    await store.dispatch('me/GET_RTSESSIONID')
  })
}
