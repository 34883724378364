export default async ({ beforeNuxtRender, req, nuxtState, $config }, inject) => {
  if (process.server) {
    // Get location from IP
    const headers = req && req.headers ? { ...req.headers } : {}
    const xForwardedFor = headers['x-forwarded-for'] ? headers['x-forwarded-for'].split(', ') : []
    const xRealIp = headers['x-real-ip']
    const xLocalIp = req.connection.remoteAddress || req.socket.remoteAddress
    const ipAddress = (xForwardedFor.length && xForwardedFor[0]) || xRealIp || xLocalIp

    let geolocation = {
      ipAddress,
      city: headers['cf-ipcity'],
      countryCode: headers['cf-ipcountry'],
      continent: headers['cf-ipcontinent'],
      longitude: headers['cf-iplongitude'] ? Number(headers['cf-iplongitude']) : null,
      latitude: headers['cf-iplatitude'] ? Number(headers['cf-iplatitude']) : null,
      regionCode: headers['cf-region-code'],
      metroCode: headers['cf-metro-code'],
      postalCode: headers['cf-postal-code'],
      timezone: headers['cf-timezone']
    }

    if (ipAddress === '127.0.0.1' || ipAddress === '::1') {
      geolocation = $config.LocalGeolocation
    }

    beforeNuxtRender(({ nuxtState }) => {
      nuxtState.geolocation = geolocation
    })

    inject('geolocation', geolocation)
  } else {
    inject('geolocation', nuxtState.geolocation)
  }
}
