
import maintenanceMixin from '@/mixins/maintenance'
import { injectPartytownScripts } from '~/lib/partytown'
import { PortalTarget } from 'portal-vue'
export default {
  name: 'Blank',
  components: {
    PortalTarget
  },

  mixins: [maintenanceMixin],

  head() {
    return {
      ...injectPartytownScripts({
        $config: this.$config,
        $experiment: this.$experiment,
        $cookies: this.$cookies,
        $user: this.$auth.user
      })
    }
  }
}
