
export default {
  props: {
    type: {
      type: String,
      default: 'button'
    }
  },

  computed: {
    isLink() {
      return false
    }
  }
}
