
import { createPopper } from '@popperjs/core'
import ClickOutside from '@/vendor/vue-click-outside'

export default {
  directives: {
    ClickOutside
  },

  props: {
    variant: {
      type: String,
      default: 'primary'
    },

    nav: {
      type: Boolean,
      default: false
    },

    right: {
      type: Boolean,
      default: false
    },

    top: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    variantClass() {
      return `zbtn-${this.variant}`
    }
  },

  mounted() {
    this.popper()
  },

  methods: {
    popper() {
      this.popperInstance = createPopper(this.$refs.dropdown, this.$refs.dropdownMenu, {
        placement: `${this.top ? 'top' : 'bottom'}-${this.right ? 'start' : 'end'}`,
        modifiers: []
      })
    },

    toggleShow() {
      if (this.$refs.dropdownMenu.getAttribute('data-show') != null) {
        this.hide()
      } else {
        this.$emit('open')
        this.show()
      }
    },

    show() {
      this.$refs.dropdownMenu?.setAttribute('data-show', '')
      this.popperInstance.update()
    },

    hide() {
      this.$refs.dropdownMenu?.removeAttribute('data-show')
    }
  }
}
