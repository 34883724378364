
export default {
  computed: {
    tag() {
      if (this.$attrs.to) {
        return 'ZLink'
      } else {
        return 'div'
      }
    }
  }
}
