
import logoSvg from '~/assets/images/logo-rvezy.svg?inline'

export default {
  name: 'LogoRVezy',

  components: {
    logoSvg
  },

  props: {
    variant: {
      type: String,
      default: 'standard',
      validator: function (value) {
        return ['standard', 'white'].includes(value)
      }
    }
  }
}
