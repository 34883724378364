
export default {
  props: {
    showDashboardBadge: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isAdmin() {
      return this.$auth.$storage.getState('permissions')?.includes('ADMINISTRATOR')
    }
  }
}
