export const state = () => ({
  hasTrips: false,
  hasDrivers: false,
  drivers: [],
  messageTemplates: []
})

export const actions = {
  async GET_DRIVERS({ commit }) {
    const drivers = await this.$axios.$get(`${this.$config.apiUrl}/api/drivers/all`)
    commit('SET_DRIVERS', drivers)
    commit('SET_HAS_DRIVERS', drivers.length > 0)
  },

  async GET_TRIPS({ commit }) {
    const trips = await this.$axios.$get(
      `${this.$config.apiUrl}/api/v2/bookings/mobile/list-renter-booking?filter=booking_trips&pageSize=1&pagingIndex=0`
    )
    commit('SET_HAS_TRIPS', trips.Result.length > 0)
  },

  async GET_MESSAGE_TEMPLATES({ commit }) {
    const response = await this.$axios.$get(`${this.$config.apiUrl}/api/message-templates`)
    commit('SET_MESSAGE_TEMPLATES', response.MessageTemplates)
  },

  ADD_OR_UPDATE_MESSAGE_TEMPLATE({ commit }, messageTemplate) {
    commit('ADD_OR_UPDATE_MESSAGE_TEMPLATE', messageTemplate)
  },

  DELETE_MESSAGE_TEMPLATE({ commit }, id) {
    commit('DELETE_MESSAGE_TEMPLATE', id)
  }
}

export const mutations = {
  SET_MESSAGE_TEMPLATES(state, messageTemplates) {
    state.messageTemplates = messageTemplates
  },

  ADD_OR_UPDATE_MESSAGE_TEMPLATE(state, messageTemplate) {
    const index = state.messageTemplates.findIndex((x) => x.Id === messageTemplate.Id)
    if (index > -1) {
      const messageTemplateInList = state.messageTemplates[index]
      messageTemplateInList.Title = messageTemplate.Title
      messageTemplateInList.Content = messageTemplate.Content
      messageTemplateInList.Order = messageTemplate.Order
      messageTemplateInList.AutoSend = messageTemplate.AutoSend
    } else {
      state.messageTemplates.push(messageTemplate)
    }
  },

  DELETE_MESSAGE_TEMPLATE(state, id) {
    state.messageTemplates = state.messageTemplates.filter((template) => template.Id !== id)
  },

  SET_DRIVERS(state, drivers) {
    state.drivers = drivers
  },

  SET_HAS_DRIVERS(state, hasDrivers) {
    state.hasDrivers = hasDrivers
  },

  SET_HAS_TRIPS(state, hasTrips) {
    state.hasTrips = hasTrips
  }
}
