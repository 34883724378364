import useBraze from '~/lib/useBraze'
import * as ouibounce from 'ouibounce'

export async function handleExitIntent($auth) {
  ouibounce(false, {
    callback: async function () {
      const braze = await useBraze()
      braze.logCustomEvent('exit intent', { isLoggedIn: $auth.loggedIn, appName: 'rvezy-rentals' })
    }
  })
}
