
import getTrackingParameters from '@/lib/getTrackingParameters'
import { trackAppBannerClicked, trackExternalLinkClicked } from '~/lib/tracking'
import { RouteBaseNameEnum, QueryStrings } from '~/lib/enums'

export default {
  computed: {
    downloadButtonImage() {
      return this.$device.isAndroid ? this.playStoreButtonImage : this.iTunesButtonImage
    },

    playStoreUrl() {
      const { utms } = getTrackingParameters(
        { $cookies: this.$cookies, store: this.$store, experiment: this.$experiment },
        true
      )

      let playStoreUrl =
        'https://play.google.com/store/apps/details?id=com.rvezy&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
      if (Object.keys(utms).length) {
        playStoreUrl += `?${new URLSearchParams(utms)}`
      }
      return playStoreUrl
    },

    iTunesUrl() {
      const { utms } = getTrackingParameters(
        { $cookies: this.$cookies, store: this.$store, experiment: this.$experiment },
        true
      )

      let iTunesUrl = 'https://itunes.apple.com/ca/app/rvezy/id1419681521'
      if (Object.keys(utms).length) {
        iTunesUrl += `?${new URLSearchParams(utms)}`
      }
      return iTunesUrl
    },

    isOwner() {
      return this.$auth.user?.IsOwner ?? null
    }
  },

  methods: {
    trackAndroid() {
      trackExternalLinkClicked({
        externalLink: this.playStoreUrl,
        cta: 'Download the app',
        pageSource: 'hostdashboardpage',
        isOwner: this.isOwner
      })
    },

    trackiOs() {
      trackExternalLinkClicked({
        externalLink: this.iTunesUrl,
        cta: 'Download the app',
        pageSource: 'hostdashboardpage',
        isOwner: this.isOwner
      })
    },

    downloadApp({ pageSource, platform }) {
      if (platform === 'android') {
        this.trackAndroid()
        window.open(this.playStoreUrl)
        return
      }

      if (platform === 'ios') {
        this.trackiOs()
        window.open(this.iTunesUrl)
        return
      }

      if (!pageSource) {
        pageSource = this.getRouteBaseName()
      }

      const { utms, rtsessionid } = getTrackingParameters(
        { $cookies: this.$cookies, store: this.$store, experiment: this.$experiment },
        true
      )

      trackAppBannerClicked({ device: this.$device, pageSource })

      if (this.$device.isAndroid) this.trackAndroid()
      else if (this.$device.isIos) this.trackiOs()

      if (rtsessionid !== null && rtsessionid !== 'null' && !this.$isServer) {
        const { protocol, url } = this.$config.appsFlyer
        const appsflyerLink = `${url}?deep_link_value=`
        let base = protocol

        let queryParams = new URLSearchParams([])
        const utmParams = new URLSearchParams(utms ?? [])

        // We also pass the full query string to the dynamic link endpoint, but
        // we have to make sure of the following: If there's NO SearchAddress
        // and NO bounding box, we need to manually add the SearchAddress from
        // the search plugin location.
        //
        // This should only happen on the search page and on a listing page.
        if (pageSource === RouteBaseNameEnum.RV_SEARCH || pageSource === RouteBaseNameEnum.RV_RENTAL) {
          const url = window.location.href
          const urlObj = new URL(url)
          queryParams = new URLSearchParams(urlObj.search)

          let hasSearchAddress = false
          let hasBoundingBox = false

          // Check if SearchAddress OR a bounding box is provided.
          for (const [key] of queryParams) {
            if (key === QueryStrings.searchAddress) {
              hasSearchAddress = true
            }
            if (
              key === QueryStrings.neLat ||
              key === QueryStrings.neLng ||
              key === QueryStrings.swLat ||
              key === QueryStrings.swLng
            ) {
              hasBoundingBox = true
            }
          }

          // Set the search address if it's not provided, but only on the search page.
          if (!hasSearchAddress && !hasBoundingBox && pageSource === RouteBaseNameEnum.RV_SEARCH) {
            const searchAddress = this.$search?.parameters?.location?.fullName ?? ''
            queryParams.set('SearchAddress', searchAddress)
          }

          // We also set the `SearchLat` and `SearchLng` as parameters, because
          // those are the values needed for the unified search endpoint in case
          // of a search address.
          if (!hasBoundingBox && pageSource === RouteBaseNameEnum.RV_SEARCH) {
            const searchLat = this.$search?.parameters?.location?.center?.lat.toString()
            const searchLng = this.$search?.parameters?.location?.center?.lng.toString()

            if (searchLat && searchLng) {
              queryParams.set('SearchLat', searchLat)
              queryParams.set('SearchLng', searchLng)
            }
          }
        }

        // Append the path as the deep link URL.
        // Exception: `index`, which should not be appended.
        if (pageSource !== RouteBaseNameEnum.INDEX) {
          base += this.$route.path.substring(1)
        }

        // Combine the provided query params with the optional utm params.
        for (const [key, value] of utmParams) {
          queryParams.append(key, value)
        }

        // Add the (dev) env to the params.
        queryParams.append('rvezy_env', this.$config.environment.toLowerCase())

        // Create the final query string.
        const params = new URLSearchParams(queryParams).toString()

        const encodedParms = encodeURIComponent(`${base}?${params}`)
        const link = `${appsflyerLink}${encodedParms}`

        window.location.href = link
      } else {
        if (this.$device.isAndroid) {
          window.location.href = this.playStoreUrl
        } else if (this.$device.isMacOs) {
          window.location.href = this.iTunesUrl
        } else {
          this.$router.push('/')
        }
      }
    }
  }
}
