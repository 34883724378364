
import { ImageQuality, ImageResolution } from '~/lib/enums'
import { imageUrl } from '~/lib/useImageUrl'

export default {
  props: {
    src: {
      type: String,
      default: undefined
    },

    text: {
      type: String,
      default: undefined
    },

    size: {
      type: [String, Number],
      default: 'md'
    },

    variant: {
      type: String,
      default: 'primary'
    },

    alt: {
      type: String,
      default: undefined
    },

    to: {
      type: String,
      default: undefined
    }
  },

  computed: {
    hasImage() {
      return Boolean(this.src)
    },

    imgURL() {
      if (!this.src && !this.text) {
        // Default image if no avatar or initials have been passed in
        return `${this.$config.staticAssetsURL}/web-app/default.png`
      } else if (!this.src && this.text) {
        return null
      }

      // Assumes you're passing in a rem. If the size is less than 4rem then set resolution to medium, else go with large
      const resolution = this.defaultSize < 4 ? ImageResolution.MEDIUM : ImageResolution.LARGE

      return imageUrl({
        $config: this.$config,
        path: this.src,
        quality: ImageQuality.NONE,
        resolution
      })
    },

    defaultSize() {
      if (!this.isSupportedSize(this.size)) {
        return parseFloat(this.size).toFixed(2)
      }
      return undefined
    },

    defaultFontSize() {
      if (!this.isSupportedSize(this.size)) {
        return (0.4 * this.defaultSize).toFixed(2)
      }
      return undefined
    },

    formattedVariant() {
      if (!this.$slots.default && (!this.text || this.hasImage)) {
        // background should be transparent if an image is set
        return 'zavatar-transparent'
      }

      if (this.isSupportedVariant(this.variant)) {
        return `zavatar-${this.variant}`
      }
      return 'zavatar-primary'
    },

    formattedSize() {
      if (this.isSupportedSize(this.size)) {
        return `zavatar-${this.size}`
      }
      return undefined
    }
  },

  methods: {
    isSupportedVariant(variant) {
      return ['danger', 'primary', 'primary-light'].includes(variant)
    },

    isSupportedSize(size) {
      return ['sm', 'md', 'lg'].includes(size)
    }
  }
}
