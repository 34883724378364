
import { trackAppBannerClosed } from '~/lib/tracking'
import iconSvg from '~/assets/images/rvezy-mini.svg?inline'
import appDownload from '@/mixins/app-download'
import starsSvg from '~/assets/images/4stars.svg?inline'
import { Cookies } from '@/lib/enums'
import { isWebview } from '~/lib/useUtils'

export default {
  components: {
    iconSvg,
    starsSvg
  },

  mixins: [appDownload],
  data() {
    return {
      appBannerDismissed: this.$cookies.get(Cookies.AppDownloadBannerDismissed),
      hasAppleAppBannerAndHidingFeatureEnabled: false
    }
  },

  computed: {
    isDownloadAppStylingExperiment() {
      return this.$experiment.getVariant('rvz15991DownloadAppStylingVariant')
    },

    isGreen() {
      return this.isDownloadAppStylingExperiment === 'a' || this.isDownloadAppStylingExperiment === 'b'
    },

    isWebview() {
      return isWebview(this.$cookies.get('platform'))
    },

    isBannerShown() {
      return !this.isWebview && !this.appBannerDismissed && this.$device.isMobileOrTablet
    }
  },

  mounted() {
    if (this.isBannerShown) {
      this.$experiment.trackView({ experimentKey: 'rvz15991DownloadAppStylingVariant' })
    }
  },

  methods: {
    dismissBanner() {
      trackAppBannerClosed({ device: this.$device, pageSource: this.getRouteBaseName() })
      this.$cookies.set(Cookies.AppDownloadBannerDismissed, 1, {
        path: '/',
        expires: this.$moment().add(2, 'days').toDate()
      })
      this.appBannerDismissed = true
    }
  }
}
