import { render, staticRenderFns } from "./nav-item.vue?vue&type=template&id=6b32bb90"
import script from "./nav-item.vue?vue&type=script&lang=js"
export * from "./nav-item.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZLink: require('/home/vsts/work/1/s/components/style-guide/link.vue').default})
