
export default {
  components: {
    DownloadAppModal: () => import(/* webpackPrefetch: true */ '@/components/booking/download-app-modal'),
    InstantBookConfirmModal: () => import(/* webpackPrefetch: true */ '@/components/modals/instant-book-confirm'),
    BookingPaymentDetailsModal: () => import(/* webpackPrefetch: true */ '@/components/booking/payment/details-modal'),
    BookingEditHostModal: () => import('@/components/booking/edit/host-modal'),
    BookingEditDatesModal: () => import('@/components/booking/edit/dates-modal'),
    BookingEditGuestModal: () => import('@/components/booking/edit/guest-modal'),
    BookingEditDetailsModal: () => import('@/components/booking/edit/details-modal'),
    BookingEditReviewModal: () => import('@/components/booking/edit/review-modal'),
    BookingEditSuccessModal: () => import('@/components/booking/edit/success-modal'),
    BookingEditPendingModal: () => import('@/components/booking/edit/pending-modal'),
    BookingEditAddonsModal: () => import('@/components/booking/edit/addons-modal'),
    ChangeRequestErrorModal: () => import('@/components/booking/edit/change-request-error-modal'),
    BookingLaunchModal: () => import('@/components/booking/launch-modal'),
    WorkingInProgressModal: () => import('@/components/working-in-progress-modal')
  },

  computed: {
    visible() {
      return this.$store.state.modals.isVisible
    },

    modal() {
      return this.$store.getters['modals/modalShowing']?.name
    },

    data() {
      return this.$store.getters['modals/modalData']
    },

    onClose() {
      return this.$store.getters['modals/onClose']
    }
  },

  methods: {
    moveNext(data) {
      if (this.onClose) this.onClose(data)
      this.$store.dispatch('modals/SHOW_NEXT_MODAL')
    }
  }
}
