
export default {
  computed: {
    userEmail() {
      return this.$cookies.get('user_email')
    }
  },

  created() {
    if (this.$cookies.get('admin_access_token') === this.$cookies.get('access_token')) {
      this.clearCookies()
    }
  },

  methods: {
    async returnToOpsDashboard() {
      if (this.$auth.loggedIn) {
        const admin_access_token = this.$cookies.get('admin_access_token')
        if (admin_access_token) {
          this.clearCookies()
          this.$cookies.remove('access_token', {
            domain: this.$config.cookieDomain,
            path: '/'
          })

          await this.$auth.logout().then(() => {
            window.location.href = this.$config.apps.operationsDashboard.baseUrl
          })
        }
      }
    },

    clearCookies() {
      this.$cookies.remove('admin_access_token', {
        domain: this.$config.cookieDomain,
        path: '/'
      })
      this.$cookies.remove('user_email', {
        domain: this.$config.cookieDomain,
        path: '/'
      })
    }
  }
}
