
import { QuickFilters } from '@/lib/enums/frontendEnums'
import { selectMidweekDates, isMidweekSelected } from '@/lib/dates'
import { isBudgetFriendlySelected, BUDGET_FRIENDLY_MIN_PRICE, BUDGET_FRIENDLY_MAX_PRICE } from '@/lib/search'

export default {
  name: 'QuickFilters',

  data() {
    return {
      isStartScroll: true,
      isEndScroll: true,
      scrollPosition: 0
    }
  },

  computed: {
    items() {
      return [
        { slug: QuickFilters.Delivery, icon: ['fal', 'truck-fast'] },
        { slug: QuickFilters.MidweekDeals, icon: ['fal', 'calendar-range'] },
        { slug: QuickFilters.BudgetFriendly, icon: ['fal', 'piggy-bank'] },
        { slug: QuickFilters.PetFriendly, icon: ['fal', 'paw-simple'] },
        { slug: QuickFilters.FamilyFriendly, icon: ['fal', 'family-pants'] },
        { slug: QuickFilters.FestivalFriendly, icon: ['fal', 'calendar-star'] },
        { slug: QuickFilters.InstantBook, icon: ['fal', 'bolt'] }
      ]
    },

    largeItems() {
      return [QuickFilters.MidweekDeals, QuickFilters.BudgetFriendly]
    },

    smallItems() {
      return [QuickFilters.Delivery, QuickFilters.PetFriendly, QuickFilters.FestivalFriendly]
    }
  },

  mounted() {
    window.addEventListener('resize', this.resizeWindow)
    this.initScroll()

    if (this.$route.query?.MidweekDeals === 'true') {
      this.updateFilter(QuickFilters.MidweekDeals, undefined)
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeWindow)
  },

  methods: {
    resizeWindow() {
      this.initScroll()
    },

    budgetFriendlyFilter() {
      this.$search.updateFilter(
        'rvPrice',
        this.isActive(QuickFilters.BudgetFriendly)
          ? { min: undefined, max: undefined }
          : { min: BUDGET_FRIENDLY_MIN_PRICE, max: BUDGET_FRIENDLY_MAX_PRICE }
      )
    },

    familyFriendlyFilter() {
      if (!this.$search.parameters.filters.amenities?.Family_kidfriendly) {
        this.$search.parameters.filters.amenities = {
          ...this.$search.parameters.filters.amenities,
          Family_kidfriendly: false
        }
      }

      this.$search.parameters.filters.amenities.Family_kidfriendly = !this.isActive(QuickFilters.FamilyFriendly)
      this.$search.updateFilter('amenities', this.$search.parameters.filters.amenities)
    },

    midweekDealsFilter() {
      if (this.isActive(QuickFilters.MidweekDeals)) {
        this.$search.updateDates({ start: null, end: null })
        return
      }

      const result = selectMidweekDates(this.$route.query?.StartDate, this.$route.query?.EndDate)

      // Reset the filter to prevent a glitch to end date selected in v-calendar plugin
      this.$search.updateDates({ start: null, end: null })

      this.$nextTick(() => {
        this.$search.updateDates({ start: result.start, end: result.end })
      })
    },

    updateFilter(filter, action) {
      if (filter === QuickFilters.BudgetFriendly) {
        this.budgetFriendlyFilter()
        return
      }

      if (filter === QuickFilters.FamilyFriendly) {
        this.familyFriendlyFilter()
        return
      }

      if (filter === QuickFilters.MidweekDeals) {
        this.midweekDealsFilter()
        return
      }

      this.$search.updateFilter(filter, action)
    },

    isActive(slug) {
      if (slug === QuickFilters.BudgetFriendly) {
        return isBudgetFriendlySelected(
          this.$search.parameters.filters.rvPrice.min,
          this.$search.parameters.filters.rvPrice.max
        )
      }

      if (slug === QuickFilters.FamilyFriendly) {
        return Boolean(this.$search.parameters.filters.amenities?.Family_kidfriendly)
      }

      if (slug === QuickFilters.MidweekDeals) {
        return isMidweekSelected(this.$route.query?.StartDate, this.$route.query?.EndDate)
      }

      return this.$search.parameters.filters[slug]
    },

    navScroll($event) {
      this.isStartScroll = false
      this.isEndScroll = false

      const { scrollLeft, scrollWidth, offsetWidth } = $event?.target

      this.scrollPosition = scrollLeft

      if (scrollLeft === 0) {
        this.isStartScroll = true
        return
      }

      const diff = Number(scrollWidth - offsetWidth)
      const scroll = Number(Math.round(scrollLeft))

      if (scroll === diff || scroll + 1 === diff || scroll - 1 === diff) {
        this.isEndScroll = true
      }
    },

    initScroll() {
      this.scrollToStart()
      this.$refs?.navFilters?.scrollWidth > this.$refs?.navFilters?.clientWidth
        ? (this.isEndScroll = false)
        : (this.isEndScroll = true)
    },

    scrollToStart() {
      this.$refs?.navFilters?.scrollTo({ left: 0, behavior: 'smooth' })
    },

    scrollToEnd() {
      this.$refs?.navFilters?.scrollTo({ left: this.$refs?.navFilters?.scrollWidth, behavior: 'smooth' })
    }
  }
}
