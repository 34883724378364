import { Platform, Cookies } from '@/lib/enums'

export default function ($cookies, baseLayout = 'default') {
  if ($cookies.get(Cookies.Platform)) {
    const platform = $cookies.get(Cookies.Platform)
    if (platform === Platform.Android || platform === Platform.IOS) {
      return 'webview'
    }
  }
  return baseLayout
}
