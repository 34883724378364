
import layoutMixin from '~/mixins/layout'
import { injectPartytownScripts } from '~/lib/partytown'
import LazyHydrate from 'vue-lazy-hydration'
import { isWebview } from '~/lib/useUtils'

export default {
  name: 'SearchPage',
  components: {
    LazyHydrate
  },

  mixins: [layoutMixin],

  head() {
    return {
      ...injectPartytownScripts({
        $config: this.$config,
        $experiment: this.$experiment,
        $cookies: this.$cookies,
        $user: this.$auth.user
      })
    }
  },

  computed: {
    showAppBanner() {
      return !isWebview(this.$cookies.get('platform'))
    }
  }
}
