export const state = () => ({
  loading: false
})

export const getters = {
  isNavigationBlocked: (state) => state.loading
}

export const actions = {
  async REFRESH({ dispatch }, callback = async () => {}) {
    await dispatch('START_LOADING')
    if (window && window.$nuxt) {
      await window.$nuxt.refresh()
    }
    await dispatch('STOP_LOADING')
    await callback()
  },

  async START_LOADING({ commit }) {
    await commit('SET_LOADING', true)
  },

  async STOP_LOADING({ commit }) {
    await commit('SET_LOADING', false)
  }
}

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading
  }
}
