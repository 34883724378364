import { trackAction } from '~/lib/tracking'

export default function (_, inject) {
  const $trackAction = (name, context) => {
    if (!process.client) {
      return
    }
    trackAction(name, context)
  }

  inject('trackAction', $trackAction)
}
