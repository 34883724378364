
export default {
  props: {
    fluid: {
      type: Boolean,
      default: false
    },

    rounded: {
      type: Boolean,
      default: false
    },

    roundedTop: {
      type: Boolean,
      default: false
    },

    center: {
      type: Boolean,
      default: false
    },

    loading: {
      type: String,
      default: 'lazy'
    },

    card: {
      type: Boolean,
      default: false
    },

    preserveAspect: {
      type: Boolean,
      default: false
    }
  }
}
