import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5748475e = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _28b56f08 = () => interopDefault(import('../pages/affiliates/index.vue' /* webpackChunkName: "pages/affiliates/index" */))
const _62576d73 = () => interopDefault(import('../pages/app-download.vue' /* webpackChunkName: "pages/app-download" */))
const _65066024 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _44696e8e = () => interopDefault(import('../pages/callback-oauth.vue' /* webpackChunkName: "pages/callback-oauth" */))
const _74d02e46 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _7eeaa685 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _4417d5b0 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _1ff8d09f = () => interopDefault(import('../pages/destination.vue' /* webpackChunkName: "pages/destination" */))
const _6344eca7 = () => interopDefault(import('../pages/experiments.vue' /* webpackChunkName: "pages/experiments" */))
const _77002223 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _64d93e84 = () => interopDefault(import('../pages/index/signin.vue' /* webpackChunkName: "pages/index/signin" */))
const _3a3e5ab4 = () => interopDefault(import('../pages/index/logout.vue' /* webpackChunkName: "pages/index/logout" */))
const _3bac9198 = () => interopDefault(import('../pages/index/signup.vue' /* webpackChunkName: "pages/index/signup" */))
const _1e3ed908 = () => interopDefault(import('../pages/how-it-works.vue' /* webpackChunkName: "pages/how-it-works" */))
const _7af5f5be = () => interopDefault(import('../pages/insurance-and-protection.vue' /* webpackChunkName: "pages/insurance-and-protection" */))
const _afa1d8f8 = () => interopDefault(import('../pages/owner.vue' /* webpackChunkName: "pages/owner" */))
const _a591912a = () => interopDefault(import('../pages/payment-callback.vue' /* webpackChunkName: "pages/payment-callback" */))
const _45f15ad4 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _697c54a7 = () => interopDefault(import('../pages/recovery-password/index.vue' /* webpackChunkName: "pages/recovery-password/index" */))
const _3dfb0894 = () => interopDefault(import('../pages/refer-a-friend.vue' /* webpackChunkName: "pages/refer-a-friend" */))
const _80839c80 = () => interopDefault(import('../pages/refer-a-host.vue' /* webpackChunkName: "pages/refer-a-host" */))
const _0f09d2a8 = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _82b4ab24 = () => interopDefault(import('../pages/rv-delivery.vue' /* webpackChunkName: "pages/rv-delivery" */))
const _d461eabc = () => interopDefault(import('../pages/rv-search.vue' /* webpackChunkName: "pages/rv-search" */))
const _09db0004 = () => interopDefault(import('../pages/service-fees.vue' /* webpackChunkName: "pages/service-fees" */))
const _1a3538bc = () => interopDefault(import('../pages/share-your-trip.vue' /* webpackChunkName: "pages/share-your-trip" */))
const _17465818 = () => interopDefault(import('../pages/superhost.vue' /* webpackChunkName: "pages/superhost" */))
const _6bf59640 = () => interopDefault(import('../pages/terms-service.vue' /* webpackChunkName: "pages/terms-service" */))
const _bcc93648 = () => interopDefault(import('../pages/trust-and-safety.vue' /* webpackChunkName: "pages/trust-and-safety" */))
const _6c8a45b6 = () => interopDefault(import('../pages/careers-data-analyst.vue' /* webpackChunkName: "pages/careers-data-analyst" */))
const _1bf3fba2 = () => interopDefault(import('../pages/careers-owner-success.vue' /* webpackChunkName: "pages/careers-owner-success" */))
const _3709fff4 = () => interopDefault(import('../pages/careers-trust-and-safety.vue' /* webpackChunkName: "pages/careers-trust-and-safety" */))
const _d97a2652 = () => interopDefault(import('../pages/dashboard/bookings/index.vue' /* webpackChunkName: "pages/dashboard/bookings/index" */))
const _598a361a = () => interopDefault(import('../pages/dashboard/bookings/index/index.vue' /* webpackChunkName: "pages/dashboard/bookings/index/index" */))
const _f84d71da = () => interopDefault(import('../pages/dashboard/bookings/index/completed.vue' /* webpackChunkName: "pages/dashboard/bookings/index/completed" */))
const _b5942bb2 = () => interopDefault(import('../pages/dashboard/bookings/index/confirmed.vue' /* webpackChunkName: "pages/dashboard/bookings/index/confirmed" */))
const _63681649 = () => interopDefault(import('../pages/dashboard/bookings/index/ezymatch.vue' /* webpackChunkName: "pages/dashboard/bookings/index/ezymatch" */))
const _5111805f = () => interopDefault(import('../pages/dashboard/bookings/index/pending.vue' /* webpackChunkName: "pages/dashboard/bookings/index/pending" */))
const _5d47e950 = () => interopDefault(import('../pages/dashboard/calendar.vue' /* webpackChunkName: "pages/dashboard/calendar" */))
const _7e25b684 = () => interopDefault(import('../pages/dashboard/favourites.vue' /* webpackChunkName: "pages/dashboard/favourites" */))
const _2fa8a609 = () => interopDefault(import('../pages/dashboard/listings.vue' /* webpackChunkName: "pages/dashboard/listings" */))
const _ca774b34 = () => interopDefault(import('../pages/dashboard/messages.vue' /* webpackChunkName: "pages/dashboard/messages" */))
const _dfbe072e = () => interopDefault(import('../pages/dashboard/messages/index.vue' /* webpackChunkName: "pages/dashboard/messages/index" */))
const _0b0008ac = () => interopDefault(import('../pages/dashboard/messages/archives.vue' /* webpackChunkName: "pages/dashboard/messages/archives" */))
const _c644f590 = () => interopDefault(import('../pages/dashboard/messages/enquiries.vue' /* webpackChunkName: "pages/dashboard/messages/enquiries" */))
const _a6e3fc06 = () => interopDefault(import('../pages/dashboard/messages/requests.vue' /* webpackChunkName: "pages/dashboard/messages/requests" */))
const _25650511 = () => interopDefault(import('../pages/dashboard/messages/_id.vue' /* webpackChunkName: "pages/dashboard/messages/_id" */))
const _2abb7aff = () => interopDefault(import('../pages/dashboard/profile.vue' /* webpackChunkName: "pages/dashboard/profile" */))
const _6fd98642 = () => interopDefault(import('../pages/dashboard/profile/index.vue' /* webpackChunkName: "pages/dashboard/profile/index" */))
const _1a32503b = () => interopDefault(import('../pages/dashboard/profile/drivers.vue' /* webpackChunkName: "pages/dashboard/profile/drivers" */))
const _64127e7e = () => interopDefault(import('../pages/dashboard/profile/drivers/index.vue' /* webpackChunkName: "pages/dashboard/profile/drivers/index" */))
const _7b804568 = () => interopDefault(import('../pages/dashboard/profile/drivers/new.vue' /* webpackChunkName: "pages/dashboard/profile/drivers/new" */))
const _4ae41d7d = () => interopDefault(import('../pages/dashboard/profile/features.vue' /* webpackChunkName: "pages/dashboard/profile/features" */))
const _8df82c3a = () => interopDefault(import('../pages/dashboard/profile/message-templates.vue' /* webpackChunkName: "pages/dashboard/profile/message-templates" */))
const _07ff9f58 = () => interopDefault(import('../pages/dashboard/profile/notifications.vue' /* webpackChunkName: "pages/dashboard/profile/notifications" */))
const _72a13f76 = () => interopDefault(import('../pages/dashboard/profile/payment.vue' /* webpackChunkName: "pages/dashboard/profile/payment" */))
const _46f1e466 = () => interopDefault(import('../pages/dashboard/profile/payout.vue' /* webpackChunkName: "pages/dashboard/profile/payout" */))
const _6a197b4f = () => interopDefault(import('../pages/dashboard/profile/_.vue' /* webpackChunkName: "pages/dashboard/profile/_" */))
const _1130e178 = () => interopDefault(import('../pages/dashboard/trips.vue' /* webpackChunkName: "pages/dashboard/trips" */))
const _4a3492c7 = () => interopDefault(import('../pages/dashboard/trips/index.vue' /* webpackChunkName: "pages/dashboard/trips/index" */))
const _297b8540 = () => interopDefault(import('../pages/dashboard/trips/completed.vue' /* webpackChunkName: "pages/dashboard/trips/completed" */))
const _7ed13468 = () => interopDefault(import('../pages/dashboard/trips/pending.vue' /* webpackChunkName: "pages/dashboard/trips/pending" */))
const _a787b9e2 = () => interopDefault(import('../pages/dashboard/rvs/deleted.vue' /* webpackChunkName: "pages/dashboard/rvs/deleted" */))
const _22e6a016 = () => interopDefault(import('../pages/dashboard/rvs/new.vue' /* webpackChunkName: "pages/dashboard/rvs/new" */))
const _3873a975 = () => interopDefault(import('../pages/dashboard/rvs/new/_step/index.vue' /* webpackChunkName: "pages/dashboard/rvs/new/_step/index" */))
const _c278c930 = () => interopDefault(import('../pages/dashboard/rvs/new/_step/_field.vue' /* webpackChunkName: "pages/dashboard/rvs/new/_step/_field" */))
const _138f40da = () => interopDefault(import('../pages/dashboard/survey/host.vue' /* webpackChunkName: "pages/dashboard/survey/host" */))
const _50d31e8f = () => interopDefault(import('../pages/dashboard/rvs/published/_id.vue' /* webpackChunkName: "pages/dashboard/rvs/published/_id" */))
const _bac8777c = () => interopDefault(import('../pages/dashboard/bookings/_id/index.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index" */))
const _6a271025 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/accept.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/accept" */))
const _4d3ef3f8 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/accepted.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/accepted" */))
const _23db8097 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/cancel.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/cancel" */))
const _2deffe96 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/complete.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/complete" */))
const _0f011c7d = () => interopDefault(import('../pages/dashboard/bookings/_id/index/custom-quote.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/custom-quote" */))
const _40fc62ae = () => interopDefault(import('../pages/dashboard/bookings/_id/index/decline.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/decline" */))
const _000c72aa = () => interopDefault(import('../pages/dashboard/bookings/_id/index/edit/index.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/edit/index" */))
const _2ed11bf0 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/income-protection.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/income-protection" */))
const _032e3466 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/insurance.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/insurance" */))
const _cfb901ea = () => interopDefault(import('../pages/dashboard/bookings/_id/index/edit/addons.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/edit/addons" */))
const _22643edd = () => interopDefault(import('../pages/dashboard/bookings/_id/index/edit/dates.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/edit/dates" */))
const _b71617cc = () => interopDefault(import('../pages/dashboard/bookings/_id/index/edit/details.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/edit/details" */))
const _5ff6d8ef = () => interopDefault(import('../pages/dashboard/bookings/_id/index/edit/pending.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/edit/pending" */))
const _6694f5b0 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/edit/review.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/edit/review" */))
const _7e99c042 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id" */))
const _37fa0322 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/index.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/index" */))
const _5de7fd93 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/addons.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/addons" */))
const _2d36b9c4 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/calendar.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/calendar" */))
const _d17f72dc = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/details.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/details" */))
const _1d2ef27a = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/earn-more.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/earn-more" */))
const _478c212a = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/insurance.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/insurance" */))
const _ffaf6e3e = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/photos.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/photos" */))
const _1f8e6770 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/preferences.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/preferences" */))
const _6d459216 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/pricing.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/pricing" */))
const _0d7c118c = () => interopDefault(import('../pages/review-renter.vue' /* webpackChunkName: "pages/review-renter" */))
const _63695624 = () => interopDefault(import('../pages/rv-rental/request-to-book/_alias.vue' /* webpackChunkName: "pages/rv-rental/request-to-book/_alias" */))
const _4cbdb1a7 = () => interopDefault(import('../pages/rv-rental/request-to-book/_alias/index.vue' /* webpackChunkName: "pages/rv-rental/request-to-book/_alias/index" */))
const _38d7b741 = () => interopDefault(import('../pages/rv-rental/request-to-book/_alias/additionals.vue' /* webpackChunkName: "pages/rv-rental/request-to-book/_alias/additionals" */))
const _264de57a = () => interopDefault(import('../pages/dashboard/bookings/_id/agreement.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/agreement" */))
const _39aad432 = () => interopDefault(import('../pages/dashboard/bookings/_id/change-request.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/change-request" */))
const _28f33235 = () => interopDefault(import('../pages/dashboard/bookings/_id/change-request/accept.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/change-request/accept" */))
const _0b8c22ce = () => interopDefault(import('../pages/dashboard/bookings/_id/change-request/decline.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/change-request/decline" */))
const _0110cc85 = () => interopDefault(import('../pages/dashboard/bookings/_id/change-request/review.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/change-request/review" */))
const _3997be72 = () => interopDefault(import('../pages/dashboard/bookings/_id/insurance-efnol.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/insurance-efnol" */))
const _263b231d = () => interopDefault(import('../pages/dashboard/bookings/_id/invoice.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/invoice" */))
const _04680514 = () => interopDefault(import('../pages/dashboard/bookings/_id/payment.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/payment" */))
const _390333d1 = () => interopDefault(import('../pages/dashboard/bookings/_id/retry-payment.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/retry-payment" */))
const _15efd718 = () => interopDefault(import('../pages/dashboard/bookings/_id/review.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/review" */))
const _27710886 = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money" */))
const _1b458080 = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/index.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/index" */))
const _9f3dc41c = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/dispute.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/dispute" */))
const _d9561386 = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/request.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/request" */))
const _9b04bacc = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/review.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/review" */))
const _1c3bf56c = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/send.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/send" */))
const _1a79054c = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/sent.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/sent" */))
const _032f69b1 = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/success.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/success" */))
const _1b1759c4 = () => interopDefault(import('../pages/dashboard/bookings/_id/services.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/services" */))
const _1a01240d = () => interopDefault(import('../pages/dashboard/bookings/_id/services/update/index.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/services/update/index" */))
const _018916ce = () => interopDefault(import('../pages/dashboard/bookings/_id/services/update/complete.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/services/update/complete" */))
const _e9931ea6 = () => interopDefault(import('../pages/dashboard/bookings/_id/services/update/review.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/services/update/review" */))
const _7db2c7c2 = () => interopDefault(import('../pages/dashboard/bookings/_id/update-payment.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/update-payment" */))
const _6fab0baa = () => interopDefault(import('../pages/short-link.vue' /* webpackChunkName: "pages/short-link" */))
const _6365a8f4 = () => interopDefault(import('../pages/point-of-interest.vue' /* webpackChunkName: "pages/point-of-interest" */))
const _02447c21 = () => interopDefault(import('../pages/rv-rental/index.vue' /* webpackChunkName: "pages/rv-rental/index" */))
const _b05928f6 = () => interopDefault(import('../pages/pre-filtered-search.vue' /* webpackChunkName: "pages/pre-filtered-search" */))
const _1bf31ca8 = () => interopDefault(import('../pages/profile/_id/index.vue' /* webpackChunkName: "pages/profile/_id/index" */))
const _6b4705a1 = () => interopDefault(import('../pages/recovery-password/_code.vue' /* webpackChunkName: "pages/recovery-password/_code" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _5748475e,
    name: "about___en"
  }, {
    path: "/affiliates",
    component: _28b56f08,
    name: "affiliates___en"
  }, {
    path: "/app-download",
    component: _62576d73,
    name: "app-download___en"
  }, {
    path: "/blog",
    component: _65066024,
    name: "blog___en"
  }, {
    path: "/callback-oauth",
    component: _44696e8e,
    name: "callback-oauth___en"
  }, {
    path: "/careers",
    component: _74d02e46,
    name: "careers___en"
  }, {
    path: "/checkout",
    component: _7eeaa685,
    name: "checkout___en"
  }, {
    path: "/dashboard",
    component: _4417d5b0,
    name: "dashboard___en"
  }, {
    path: "/destination",
    component: _1ff8d09f,
    name: "destination___en"
  }, {
    path: "/experiments",
    component: _6344eca7,
    name: "experiments___en"
  }, {
    path: "/fr",
    component: _77002223,
    name: "index___fr",
    children: [{
      path: "/fr/connexion",
      component: _64d93e84,
      name: "index-signin___fr"
    }, {
      path: "/fr/deconnexion",
      component: _3a3e5ab4,
      name: "index-logout___fr"
    }, {
      path: "/fr/inscription",
      component: _3bac9198,
      name: "index-signup___fr"
    }]
  }, {
    path: "/how-it-works",
    component: _1e3ed908,
    name: "how-it-works___en"
  }, {
    path: "/insurance-and-protection",
    component: _7af5f5be,
    name: "insurance-and-protection___en"
  }, {
    path: "/owner",
    component: _afa1d8f8,
    name: "owner___en"
  }, {
    path: "/payment-callback",
    component: _a591912a,
    name: "payment-callback___en"
  }, {
    path: "/privacy-policy",
    component: _45f15ad4,
    name: "privacy-policy___en"
  }, {
    path: "/recovery-password",
    component: _697c54a7,
    name: "recovery-password___en"
  }, {
    path: "/refer-a-friend",
    component: _3dfb0894,
    name: "refer-a-friend___en"
  }, {
    path: "/refer-a-host",
    component: _80839c80,
    name: "refer-a-host___en"
  }, {
    path: "/reviews",
    component: _0f09d2a8,
    name: "reviews___en"
  }, {
    path: "/rv-delivery",
    component: _82b4ab24,
    name: "rv-delivery___en"
  }, {
    path: "/rv-search",
    component: _d461eabc,
    name: "rv-search___en"
  }, {
    path: "/service-fees",
    component: _09db0004,
    name: "service-fees___en"
  }, {
    path: "/share-your-trip",
    component: _1a3538bc,
    name: "share-your-trip___en"
  }, {
    path: "/superhost",
    component: _17465818,
    name: "superhost___en"
  }, {
    path: "/terms-service",
    component: _6bf59640,
    name: "terms-service___en"
  }, {
    path: "/trust-and-safety",
    component: _bcc93648,
    name: "trust-and-safety___en"
  }, {
    path: "/careers/spotlight-data-analyst",
    component: _6c8a45b6,
    name: "careers-data-analyst___en"
  }, {
    path: "/careers/spotlight-owner-success",
    component: _1bf3fba2,
    name: "careers-owner-success___en"
  }, {
    path: "/careers/spotlight-trust-and-safety",
    component: _3709fff4,
    name: "careers-trust-and-safety___en"
  }, {
    path: "/dashboard/bookings",
    component: _d97a2652,
    children: [{
      path: "",
      component: _598a361a,
      name: "dashboard-bookings-index___en"
    }, {
      path: "completed",
      component: _f84d71da,
      name: "dashboard-bookings-index-completed___en"
    }, {
      path: "confirmed",
      component: _b5942bb2,
      name: "dashboard-bookings-index-confirmed___en"
    }, {
      path: "ezymatch",
      component: _63681649,
      name: "dashboard-bookings-index-ezymatch___en"
    }, {
      path: "pending",
      component: _5111805f,
      name: "dashboard-bookings-index-pending___en"
    }]
  }, {
    path: "/dashboard/calendar",
    component: _5d47e950,
    name: "dashboard-calendar___en"
  }, {
    path: "/dashboard/favourites",
    component: _7e25b684,
    name: "dashboard-favourites___en"
  }, {
    path: "/dashboard/listings",
    component: _2fa8a609,
    name: "dashboard-listings___en"
  }, {
    path: "/dashboard/messages",
    component: _ca774b34,
    children: [{
      path: "",
      component: _dfbe072e,
      name: "dashboard-messages___en"
    }, {
      path: "archives",
      component: _0b0008ac,
      name: "dashboard-messages-archives___en"
    }, {
      path: "enquiries",
      component: _c644f590,
      name: "dashboard-messages-enquiries___en"
    }, {
      path: "requests",
      component: _a6e3fc06,
      name: "dashboard-messages-requests___en"
    }, {
      path: ":id",
      component: _25650511,
      name: "dashboard-messages-id___en"
    }]
  }, {
    path: "/dashboard/profile",
    component: _2abb7aff,
    children: [{
      path: "",
      component: _6fd98642,
      name: "dashboard-profile___en"
    }, {
      path: "drivers",
      component: _1a32503b,
      children: [{
        path: "",
        component: _64127e7e,
        name: "dashboard-profile-drivers___en"
      }, {
        path: "new",
        component: _7b804568,
        name: "dashboard-profile-drivers-new___en"
      }]
    }, {
      path: "features",
      component: _4ae41d7d,
      name: "dashboard-profile-features___en"
    }, {
      path: "message-templates",
      component: _8df82c3a,
      name: "dashboard-profile-message-templates___en"
    }, {
      path: "notifications",
      component: _07ff9f58,
      name: "dashboard-profile-notifications___en"
    }, {
      path: "payment",
      component: _72a13f76,
      name: "dashboard-profile-payment___en"
    }, {
      path: "payout",
      component: _46f1e466,
      name: "dashboard-profile-payout___en"
    }, {
      path: "*",
      component: _6a197b4f,
      name: "dashboard-profile-all___en"
    }]
  }, {
    path: "/dashboard/trips",
    component: _1130e178,
    children: [{
      path: "",
      component: _4a3492c7,
      name: "dashboard-trips___en"
    }, {
      path: "completed",
      component: _297b8540,
      name: "dashboard-trips-completed___en"
    }, {
      path: "pending",
      component: _7ed13468,
      name: "dashboard-trips-pending___en"
    }]
  }, {
    path: "/fr/a-propos",
    component: _5748475e,
    name: "about___fr"
  }, {
    path: "/fr/affilies",
    component: _28b56f08,
    name: "affiliates___fr"
  }, {
    path: "/fr/assurances-et-protection",
    component: _7af5f5be,
    name: "insurance-and-protection___fr"
  }, {
    path: "/fr/blogue",
    component: _65066024,
    name: "blog___fr"
  }, {
    path: "/fr/callback-oauth",
    component: _44696e8e,
    name: "callback-oauth___fr"
  }, {
    path: "/fr/careers",
    component: _74d02e46,
    name: "careers___fr"
  }, {
    path: "/fr/careers-data-analyst",
    component: _6c8a45b6,
    name: "careers-data-analyst___fr"
  }, {
    path: "/fr/careers-owner-success",
    component: _1bf3fba2,
    name: "careers-owner-success___fr"
  }, {
    path: "/fr/careers-trust-and-safety",
    component: _3709fff4,
    name: "careers-trust-and-safety___fr"
  }, {
    path: "/fr/checkout",
    component: _7eeaa685,
    name: "checkout___fr"
  }, {
    path: "/fr/comment-ca-fonctionne",
    component: _1e3ed908,
    name: "how-it-works___fr"
  }, {
    path: "/fr/confiance-et-securite",
    component: _bcc93648,
    name: "trust-and-safety___fr"
  }, {
    path: "/fr/dashboard",
    component: _4417d5b0,
    name: "dashboard___fr"
  }, {
    path: "/fr/destination",
    component: _1ff8d09f,
    name: "destination___fr"
  }, {
    path: "/fr/evaluations",
    component: _0f09d2a8,
    name: "reviews___fr"
  }, {
    path: "/fr/experiments",
    component: _6344eca7,
    name: "experiments___fr"
  }, {
    path: "/fr/frais-de-service",
    component: _09db0004,
    name: "service-fees___fr"
  }, {
    path: "/fr/livraison-vr",
    component: _82b4ab24,
    name: "rv-delivery___fr"
  }, {
    path: "/fr/partagez-votre-voyage",
    component: _1a3538bc,
    name: "share-your-trip___fr"
  }, {
    path: "/fr/payment-callback",
    component: _a591912a,
    name: "payment-callback___fr"
  }, {
    path: "/fr/politique-de-confidentialite",
    component: _45f15ad4,
    name: "privacy-policy___fr"
  }, {
    path: "/fr/proprietaire",
    component: _afa1d8f8,
    name: "owner___fr"
  }, {
    path: "/fr/recherche-vr",
    component: _d461eabc,
    name: "rv-search___fr"
  }, {
    path: "/fr/recovery-password",
    component: _697c54a7,
    name: "recovery-password___fr"
  }, {
    path: "/fr/referer-un-ami",
    component: _3dfb0894,
    name: "refer-a-friend___fr"
  }, {
    path: "/fr/referer-un-hote",
    component: _80839c80,
    name: "refer-a-host___fr"
  }, {
    path: "/fr/superhotes",
    component: _17465818,
    name: "superhost___fr"
  }, {
    path: "/fr/telecharger-application",
    component: _62576d73,
    name: "app-download___fr"
  }, {
    path: "/fr/termes-utilisation",
    component: _6bf59640,
    name: "terms-service___fr"
  }, {
    path: "/dashboard/rvs/deleted",
    component: _a787b9e2,
    name: "dashboard-rvs-deleted___en"
  }, {
    path: "/dashboard/rvs/new",
    component: _22e6a016,
    name: "dashboard-rvs-new___en",
    children: [{
      path: "/dashboard/rvs/new/:step",
      component: _3873a975,
      name: "dashboard-rvs-new-step___en"
    }, {
      path: "/dashboard/rvs/new/:step/:field",
      component: _c278c930,
      name: "dashboard-rvs-new-step-field___en"
    }]
  }, {
    path: "/dashboard/survey/host",
    component: _138f40da,
    name: "dashboard-survey-host___en"
  }, {
    path: "/fr/dashboard/bookings",
    component: _d97a2652,
    children: [{
      path: "",
      component: _598a361a,
      name: "dashboard-bookings-index___fr"
    }, {
      path: "completed",
      component: _f84d71da,
      name: "dashboard-bookings-index-completed___fr"
    }, {
      path: "confirmed",
      component: _b5942bb2,
      name: "dashboard-bookings-index-confirmed___fr"
    }, {
      path: "ezymatch",
      component: _63681649,
      name: "dashboard-bookings-index-ezymatch___fr"
    }, {
      path: "pending",
      component: _5111805f,
      name: "dashboard-bookings-index-pending___fr"
    }]
  }, {
    path: "/fr/dashboard/calendar",
    component: _5d47e950,
    name: "dashboard-calendar___fr"
  }, {
    path: "/fr/dashboard/favourites",
    component: _7e25b684,
    name: "dashboard-favourites___fr"
  }, {
    path: "/fr/dashboard/listings",
    component: _2fa8a609,
    name: "dashboard-listings___fr"
  }, {
    path: "/fr/dashboard/messages",
    component: _ca774b34,
    children: [{
      path: "",
      component: _dfbe072e,
      name: "dashboard-messages___fr"
    }, {
      path: "archives",
      component: _0b0008ac,
      name: "dashboard-messages-archives___fr"
    }, {
      path: "enquiries",
      component: _c644f590,
      name: "dashboard-messages-enquiries___fr"
    }, {
      path: "requests",
      component: _a6e3fc06,
      name: "dashboard-messages-requests___fr"
    }, {
      path: ":id",
      component: _25650511,
      name: "dashboard-messages-id___fr"
    }]
  }, {
    path: "/fr/dashboard/profile",
    component: _2abb7aff,
    children: [{
      path: "",
      component: _6fd98642,
      name: "dashboard-profile___fr"
    }, {
      path: "drivers",
      component: _1a32503b,
      children: [{
        path: "",
        component: _64127e7e,
        name: "dashboard-profile-drivers___fr"
      }, {
        path: "new",
        component: _7b804568,
        name: "dashboard-profile-drivers-new___fr"
      }]
    }, {
      path: "features",
      component: _4ae41d7d,
      name: "dashboard-profile-features___fr"
    }, {
      path: "message-templates",
      component: _8df82c3a,
      name: "dashboard-profile-message-templates___fr"
    }, {
      path: "notifications",
      component: _07ff9f58,
      name: "dashboard-profile-notifications___fr"
    }, {
      path: "payment",
      component: _72a13f76,
      name: "dashboard-profile-payment___fr"
    }, {
      path: "payout",
      component: _46f1e466,
      name: "dashboard-profile-payout___fr"
    }, {
      path: "*",
      component: _6a197b4f,
      name: "dashboard-profile-all___fr"
    }]
  }, {
    path: "/fr/dashboard/trips",
    component: _1130e178,
    children: [{
      path: "",
      component: _4a3492c7,
      name: "dashboard-trips___fr"
    }, {
      path: "completed",
      component: _297b8540,
      name: "dashboard-trips-completed___fr"
    }, {
      path: "pending",
      component: _7ed13468,
      name: "dashboard-trips-pending___fr"
    }]
  }, {
    path: "/fr/dashboard/rvs/deleted",
    component: _a787b9e2,
    name: "dashboard-rvs-deleted___fr"
  }, {
    path: "/fr/dashboard/rvs/new",
    component: _22e6a016,
    name: "dashboard-rvs-new___fr",
    children: [{
      path: "/fr/dashboard/rvs/new/:step",
      component: _3873a975,
      name: "dashboard-rvs-new-step___fr"
    }, {
      path: "/fr/dashboard/rvs/new/:step/:field",
      component: _c278c930,
      name: "dashboard-rvs-new-step-field___fr"
    }]
  }, {
    path: "/fr/dashboard/survey/host",
    component: _138f40da,
    name: "dashboard-survey-host___fr"
  }, {
    path: "/",
    component: _77002223,
    name: "index___en",
    children: [{
      path: "/logout",
      component: _3a3e5ab4,
      name: "index-logout___en"
    }, {
      path: "/signin",
      component: _64d93e84,
      name: "index-signin___en"
    }, {
      path: "/signup",
      component: _3bac9198,
      name: "index-signup___en"
    }]
  }, {
    path: "/fr/dashboard/rvs/published/:id",
    component: _50d31e8f,
    name: "dashboard-rvs-published-id___fr"
  }, {
    path: "/dashboard/rvs/published/:id",
    component: _50d31e8f,
    name: "dashboard-rvs-published-id___en"
  }, {
    path: "/fr/dashboard/bookings/:id",
    component: _bac8777c,
    name: "dashboard-bookings-id___fr",
    children: [{
      path: "accept",
      component: _6a271025,
      name: "dashboard-bookings-id-index-accept___fr"
    }, {
      path: "accepted",
      component: _4d3ef3f8,
      name: "dashboard-bookings-id-index-accepted___fr"
    }, {
      path: "cancel",
      component: _23db8097,
      name: "dashboard-bookings-id-index-cancel___fr"
    }, {
      path: "complete",
      component: _2deffe96,
      name: "dashboard-bookings-id-index-complete___fr"
    }, {
      path: "custom-quote",
      component: _0f011c7d,
      name: "dashboard-bookings-id-index-custom-quote___fr"
    }, {
      path: "decline",
      component: _40fc62ae,
      name: "dashboard-bookings-id-index-decline___fr"
    }, {
      path: "edit",
      component: _000c72aa,
      name: "dashboard-bookings-id-index-edit___fr"
    }, {
      path: "income-protection",
      component: _2ed11bf0,
      name: "dashboard-bookings-id-index-income-protection___fr"
    }, {
      path: "insurance",
      component: _032e3466,
      name: "dashboard-bookings-id-index-insurance___fr"
    }, {
      path: "edit/addons",
      component: _cfb901ea,
      name: "dashboard-bookings-id-index-edit-addons___fr"
    }, {
      path: "edit/dates",
      component: _22643edd,
      name: "dashboard-bookings-id-index-edit-dates___fr"
    }, {
      path: "edit/details",
      component: _b71617cc,
      name: "dashboard-bookings-id-index-edit-details___fr"
    }, {
      path: "edit/pending",
      component: _5ff6d8ef,
      name: "dashboard-bookings-id-index-edit-pending___fr"
    }, {
      path: "edit/review",
      component: _6694f5b0,
      name: "dashboard-bookings-id-index-edit-review___fr"
    }]
  }, {
    path: "/fr/dashboard/rvs/:rvID",
    component: _7e99c042,
    children: [{
      path: "",
      component: _37fa0322,
      name: "dashboard-rvs-rv-Id___fr"
    }, {
      path: "addons",
      component: _5de7fd93,
      name: "dashboard-rvs-rv-Id-addons___fr"
    }, {
      path: "calendar",
      component: _2d36b9c4,
      name: "dashboard-rvs-rv-Id-calendar___fr"
    }, {
      path: "details",
      component: _d17f72dc,
      name: "dashboard-rvs-rv-Id-details___fr"
    }, {
      path: "earn-more",
      component: _1d2ef27a,
      name: "dashboard-rvs-rv-Id-earn-more___fr"
    }, {
      path: "insurance",
      component: _478c212a,
      name: "dashboard-rvs-rv-Id-insurance___fr"
    }, {
      path: "photos",
      component: _ffaf6e3e,
      name: "dashboard-rvs-rv-Id-photos___fr"
    }, {
      path: "preferences",
      component: _1f8e6770,
      name: "dashboard-rvs-rv-Id-preferences___fr"
    }, {
      path: "pricing",
      component: _6d459216,
      name: "dashboard-rvs-rv-Id-pricing___fr"
    }]
  }, {
    path: "/fr/evaluations/renter/:bookingId",
    component: _0d7c118c,
    name: "review-renter___fr"
  }, {
    path: "/fr/location-vr/request-to-book/:alias",
    component: _63695624,
    children: [{
      path: "",
      component: _4cbdb1a7,
      name: "rv-rental-request-to-book-alias___fr"
    }, {
      path: "additionals",
      component: _38d7b741,
      name: "rv-rental-request-to-book-alias-additionals___fr"
    }]
  }, {
    path: "/fr/dashboard/bookings/:id/agreement",
    component: _264de57a,
    name: "dashboard-bookings-id-agreement___fr"
  }, {
    path: "/fr/dashboard/bookings/:id/change-request",
    component: _39aad432,
    name: "dashboard-bookings-id-change-request___fr",
    children: [{
      path: "accept",
      component: _28f33235,
      name: "dashboard-bookings-id-change-request-accept___fr"
    }, {
      path: "decline",
      component: _0b8c22ce,
      name: "dashboard-bookings-id-change-request-decline___fr"
    }, {
      path: "review",
      component: _0110cc85,
      name: "dashboard-bookings-id-change-request-review___fr"
    }]
  }, {
    path: "/fr/dashboard/bookings/:id/insurance-efnol",
    component: _3997be72,
    name: "dashboard-bookings-id-insurance-efnol___fr"
  }, {
    path: "/fr/dashboard/bookings/:id/invoice",
    component: _263b231d,
    name: "dashboard-bookings-id-invoice___fr"
  }, {
    path: "/fr/dashboard/bookings/:id/payment",
    component: _04680514,
    name: "dashboard-bookings-id-payment___fr"
  }, {
    path: "/fr/dashboard/bookings/:id/retry-payment",
    component: _390333d1,
    name: "dashboard-bookings-id-retry-payment___fr"
  }, {
    path: "/fr/dashboard/bookings/:id/review",
    component: _15efd718,
    name: "dashboard-bookings-id-review___fr"
  }, {
    path: "/fr/dashboard/bookings/:id/send-request-money",
    component: _27710886,
    children: [{
      path: "",
      component: _1b458080,
      name: "dashboard-bookings-id-send-request-money___fr"
    }, {
      path: "dispute",
      component: _9f3dc41c,
      name: "dashboard-bookings-id-send-request-money-dispute___fr"
    }, {
      path: "request",
      component: _d9561386,
      name: "dashboard-bookings-id-send-request-money-request___fr"
    }, {
      path: "review",
      component: _9b04bacc,
      name: "dashboard-bookings-id-send-request-money-review___fr"
    }, {
      path: "send",
      component: _1c3bf56c,
      name: "dashboard-bookings-id-send-request-money-send___fr"
    }, {
      path: "sent",
      component: _1a79054c,
      name: "dashboard-bookings-id-send-request-money-sent___fr"
    }, {
      path: "success",
      component: _032f69b1,
      name: "dashboard-bookings-id-send-request-money-success___fr"
    }]
  }, {
    path: "/fr/dashboard/bookings/:id/services",
    component: _1b1759c4,
    name: "dashboard-bookings-id-services___fr",
    children: [{
      path: "update",
      component: _1a01240d,
      name: "dashboard-bookings-id-services-update___fr"
    }, {
      path: "update/complete",
      component: _018916ce,
      name: "dashboard-bookings-id-services-update-complete___fr"
    }, {
      path: "update/review",
      component: _e9931ea6,
      name: "dashboard-bookings-id-services-update-review___fr"
    }]
  }, {
    path: "/fr/dashboard/bookings/:id/update-payment",
    component: _7db2c7c2,
    name: "dashboard-bookings-id-update-payment___fr"
  }, {
    path: "/dashboard/bookings/:id",
    component: _bac8777c,
    name: "dashboard-bookings-id___en",
    children: [{
      path: "accept",
      component: _6a271025,
      name: "dashboard-bookings-id-index-accept___en"
    }, {
      path: "accepted",
      component: _4d3ef3f8,
      name: "dashboard-bookings-id-index-accepted___en"
    }, {
      path: "cancel",
      component: _23db8097,
      name: "dashboard-bookings-id-index-cancel___en"
    }, {
      path: "complete",
      component: _2deffe96,
      name: "dashboard-bookings-id-index-complete___en"
    }, {
      path: "custom-quote",
      component: _0f011c7d,
      name: "dashboard-bookings-id-index-custom-quote___en"
    }, {
      path: "decline",
      component: _40fc62ae,
      name: "dashboard-bookings-id-index-decline___en"
    }, {
      path: "edit",
      component: _000c72aa,
      name: "dashboard-bookings-id-index-edit___en"
    }, {
      path: "income-protection",
      component: _2ed11bf0,
      name: "dashboard-bookings-id-index-income-protection___en"
    }, {
      path: "insurance",
      component: _032e3466,
      name: "dashboard-bookings-id-index-insurance___en"
    }, {
      path: "edit/addons",
      component: _cfb901ea,
      name: "dashboard-bookings-id-index-edit-addons___en"
    }, {
      path: "edit/dates",
      component: _22643edd,
      name: "dashboard-bookings-id-index-edit-dates___en"
    }, {
      path: "edit/details",
      component: _b71617cc,
      name: "dashboard-bookings-id-index-edit-details___en"
    }, {
      path: "edit/pending",
      component: _5ff6d8ef,
      name: "dashboard-bookings-id-index-edit-pending___en"
    }, {
      path: "edit/review",
      component: _6694f5b0,
      name: "dashboard-bookings-id-index-edit-review___en"
    }]
  }, {
    path: "/dashboard/rvs/:rvID",
    component: _7e99c042,
    children: [{
      path: "",
      component: _37fa0322,
      name: "dashboard-rvs-rv-Id___en"
    }, {
      path: "addons",
      component: _5de7fd93,
      name: "dashboard-rvs-rv-Id-addons___en"
    }, {
      path: "calendar",
      component: _2d36b9c4,
      name: "dashboard-rvs-rv-Id-calendar___en"
    }, {
      path: "details",
      component: _d17f72dc,
      name: "dashboard-rvs-rv-Id-details___en"
    }, {
      path: "earn-more",
      component: _1d2ef27a,
      name: "dashboard-rvs-rv-Id-earn-more___en"
    }, {
      path: "insurance",
      component: _478c212a,
      name: "dashboard-rvs-rv-Id-insurance___en"
    }, {
      path: "photos",
      component: _ffaf6e3e,
      name: "dashboard-rvs-rv-Id-photos___en"
    }, {
      path: "preferences",
      component: _1f8e6770,
      name: "dashboard-rvs-rv-Id-preferences___en"
    }, {
      path: "pricing",
      component: _6d459216,
      name: "dashboard-rvs-rv-Id-pricing___en"
    }]
  }, {
    path: "/fr/(c|cliquer)/:slug(.+)",
    component: _6fab0baa,
    name: "short-link___fr"
  }, {
    path: "/fr/destination/:requestedPath(.+)",
    component: _6365a8f4,
    name: "point-of-interest___fr"
  }, {
    path: "/fr/location-vr/:alias",
    component: _02447c21,
    name: "rv-rental___fr"
  }, {
    path: "/fr/locations-vr/:keyword",
    component: _b05928f6,
    name: "pre-filtered-search___fr"
  }, {
    path: "/fr/profil/:id",
    component: _1bf31ca8,
    name: "profile-id___fr"
  }, {
    path: "/fr/recovery-password/:code",
    component: _6b4705a1,
    name: "recovery-password-code___fr"
  }, {
    path: "/reviews/renter/:bookingId",
    component: _0d7c118c,
    name: "review-renter___en"
  }, {
    path: "/rv-rental/request-to-book/:alias",
    component: _63695624,
    children: [{
      path: "",
      component: _4cbdb1a7,
      name: "rv-rental-request-to-book-alias___en"
    }, {
      path: "additionals",
      component: _38d7b741,
      name: "rv-rental-request-to-book-alias-additionals___en"
    }]
  }, {
    path: "/dashboard/bookings/:id/agreement",
    component: _264de57a,
    name: "dashboard-bookings-id-agreement___en"
  }, {
    path: "/dashboard/bookings/:id/change-request",
    component: _39aad432,
    name: "dashboard-bookings-id-change-request___en",
    children: [{
      path: "accept",
      component: _28f33235,
      name: "dashboard-bookings-id-change-request-accept___en"
    }, {
      path: "decline",
      component: _0b8c22ce,
      name: "dashboard-bookings-id-change-request-decline___en"
    }, {
      path: "review",
      component: _0110cc85,
      name: "dashboard-bookings-id-change-request-review___en"
    }]
  }, {
    path: "/dashboard/bookings/:id/insurance-efnol",
    component: _3997be72,
    name: "dashboard-bookings-id-insurance-efnol___en"
  }, {
    path: "/dashboard/bookings/:id/invoice",
    component: _263b231d,
    name: "dashboard-bookings-id-invoice___en"
  }, {
    path: "/dashboard/bookings/:id/payment",
    component: _04680514,
    name: "dashboard-bookings-id-payment___en"
  }, {
    path: "/dashboard/bookings/:id/retry-payment",
    component: _390333d1,
    name: "dashboard-bookings-id-retry-payment___en"
  }, {
    path: "/dashboard/bookings/:id/review",
    component: _15efd718,
    name: "dashboard-bookings-id-review___en"
  }, {
    path: "/dashboard/bookings/:id/send-request-money",
    component: _27710886,
    children: [{
      path: "",
      component: _1b458080,
      name: "dashboard-bookings-id-send-request-money___en"
    }, {
      path: "dispute",
      component: _9f3dc41c,
      name: "dashboard-bookings-id-send-request-money-dispute___en"
    }, {
      path: "request",
      component: _d9561386,
      name: "dashboard-bookings-id-send-request-money-request___en"
    }, {
      path: "review",
      component: _9b04bacc,
      name: "dashboard-bookings-id-send-request-money-review___en"
    }, {
      path: "send",
      component: _1c3bf56c,
      name: "dashboard-bookings-id-send-request-money-send___en"
    }, {
      path: "sent",
      component: _1a79054c,
      name: "dashboard-bookings-id-send-request-money-sent___en"
    }, {
      path: "success",
      component: _032f69b1,
      name: "dashboard-bookings-id-send-request-money-success___en"
    }]
  }, {
    path: "/dashboard/bookings/:id/services",
    component: _1b1759c4,
    name: "dashboard-bookings-id-services___en",
    children: [{
      path: "update",
      component: _1a01240d,
      name: "dashboard-bookings-id-services-update___en"
    }, {
      path: "update/complete",
      component: _018916ce,
      name: "dashboard-bookings-id-services-update-complete___en"
    }, {
      path: "update/review",
      component: _e9931ea6,
      name: "dashboard-bookings-id-services-update-review___en"
    }]
  }, {
    path: "/dashboard/bookings/:id/update-payment",
    component: _7db2c7c2,
    name: "dashboard-bookings-id-update-payment___en"
  }, {
    path: "/(c|click)/:slug(.+)",
    component: _6fab0baa,
    name: "short-link___en"
  }, {
    path: "/destination/:requestedPath(.+)",
    component: _6365a8f4,
    name: "point-of-interest___en"
  }, {
    path: "/profile/:id",
    component: _1bf31ca8,
    name: "profile-id___en"
  }, {
    path: "/recovery-password/:code",
    component: _6b4705a1,
    name: "recovery-password-code___en"
  }, {
    path: "/rv-rental/:alias",
    component: _02447c21,
    name: "rv-rental___en"
  }, {
    path: "/rv-rentals/:keyword",
    component: _b05928f6,
    name: "pre-filtered-search___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
