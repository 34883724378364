
import layoutMixin from '~/mixins/layout'
import routeMixin from '~/mixins/route'
import { injectPartytownScripts } from '~/lib/partytown'
import { isWebview } from '~/lib/useUtils'

export default {
  name: 'SimpleMobile',

  mixins: [layoutMixin, routeMixin],

  data() {
    return {
      bookingId: null
    }
  },

  head() {
    const i18nSeo = this.$nuxtI18nHead({ addSeoAttributes: true })

    i18nSeo.bodyAttrs = {
      class: 'simple-mobile'
    }

    return {
      ...i18nSeo,
      ...injectPartytownScripts({
        $config: this.$config,
        $experiment: this.$experiment,
        $cookies: this.$cookies,
        $user: this.$auth.user
      })
    }
  },

  computed: {
    isWebview() {
      return isWebview(this.$cookies.get('platform'))
    },

    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    }
  },

  watch: {
    $route() {
      this.getBookingId()
    }
  },

  beforeMount() {
    this.getBookingId()
  },

  methods: {
    getBookingId() {
      const params = this.$route.params
      if (params.id) {
        this.bookingId = params.id
      }
    },

    backButton() {
      if (this.bookingId) {
        this.$router.push(this.localePath(`/dashboard/bookings/${this.bookingId}`))
        return
      }

      this.$router.go(-1)
    }
  }
}
