
import ownerCommon from '~/mixins/owner-common.js'
import { trackHelpCenterClicked } from '~/lib/tracking'

export default {
  mixins: [ownerCommon],

  props: {
    inboxBadge: {
      type: Number,
      default: 0
    },

    dashboardBadge: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    trackHelpCenterClicked() {
      trackHelpCenterClicked({
        cta: 'header',
        pageSource: this.getRouteBaseName()
      })
    }
  }
}
