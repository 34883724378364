
import { trackBuyRvClicked } from '~/lib/tracking/events.js'
export default {
  name: 'BuyAnRvButton',
  props: {
    textBtn: {
      type: String,
      required: true
    },

    useHighlight: {
      type: Boolean,
      default: false
    },

    useWhite: {
      type: Boolean,
      default: false
    },

    isLink: {
      type: Boolean,
      default: false
    },

    usePrimary200: {
      type: Boolean,
      default: false
    },

    cta: {
      type: String,
      required: true
    },

    useOutline: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    btnVariant() {
      return this.useHighlight ? 'highlight' : this.useWhite ? 'white' : 'primary'
    }
  },

  methods: {
    buyAnRvLinkClicked() {
      trackBuyRvClicked({ pageSource: this.getRouteBaseName(), cta: this.cta })
      const isCanadianVisitor = this.$geolocation.countryCode === 'CA'
      const isUnitedStatesVisitor = this.$geolocation.countryCode === 'US'

      const targetUrl =
        this.$experiment.isEnabled('rvz18892BuyAnRvTypeform') && (isCanadianVisitor || isUnitedStatesVisitor)
          ? isCanadianVisitor
            ? 'https://rvezy.typeform.com/to/vzPQrnXw'
            : 'https://rvezy.typeform.com/to/pW68D3i9'
          : this.$config.apps.marketplace.baseUrl

      window.open(targetUrl)
    }
  }
}
