export default ({ app }, inject) => {
  function decode(path) {
    return decodeURIComponent(path)
  }

  function decodeLocale(params) {
    return decode(app.localePath(params))
  }

  inject('path', {
    decode,
    decodeLocale
  })
}
