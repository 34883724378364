export const FEATURES = {
  CAN_USE_CUSTOM_BACK: 'canUseCustomBack',
  CAN_TRACK_SEGMENT_VERSION: 'canTrackSegmentVersion',
  CAN_SHOW_PROTECTION_PLANS_ON_PAYMENT: 'canShowProtectionPlansOnPayment'
}

const parseCapabilitiesToArray = (capabilitiesStr) =>
  capabilitiesStr?.match(/"([A-z]*)"/g)?.map((str) => str.replace(/"/g, ''))

export default function (capabilitiesCookie, features) {
  if (!capabilitiesCookie) return false
  if (!features) return false
  let hasFeatures = 0

  const capabilitiesCookies = capabilitiesCookie

  const capabilities = Array.isArray(capabilitiesCookies)
    ? capabilitiesCookies
    : parseCapabilitiesToArray(capabilitiesCookies)

  if (!Array.isArray(features)) return capabilities.includes(features)

  capabilities.forEach((capability) => {
    features.forEach((feature) => {
      if (capability === feature) {
        hasFeatures++
      }
    })
  })

  return features.length === hasFeatures
}
