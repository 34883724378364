
export default {
  computed: {
    aggregateReview() {
      return Number(this.$store.getters['verifiedReview/aggregateReview']?.rating) || 0
    }
  },

  methods: {
    calculateMask(index) {
      return Math.max(Math.min((this.aggregateReview - index + 1) * 100, 100), 0)
    }
  }
}
