import { MItoKM } from '~/lib/useUtils'
import { COUNTRY_SHORTS } from '@/constants'

export default function (rvSnapshot, financial) {
  if (rvSnapshot?.IsChargeMileage) {
    let freeKms = rvSnapshot.DailyKMAllowed * (financial.TotalNights + 1)
    //If RV is from US, convert miles to Kms
    if (rvSnapshot.Country === COUNTRY_SHORTS.US || financial.Country === COUNTRY_SHORTS.US) {
      freeKms = MItoKM(freeKms)
    }
    return Math.round(freeKms)
  }
  return null
}
