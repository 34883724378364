
export default {
  props: {
    location: {
      type: Object,
      default: null
    }
  },

  computed: {
    compLocation() {
      return this.location ?? this.$search.parameters.location
    }
  }
}
