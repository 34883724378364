
export default {
  name: 'ZButton',

  inheritAttrs: false,

  props: {
    to: {
      type: String,
      default: undefined
    },

    href: {
      type: String,
      default: undefined
    },

    variant: {
      type: String,
      default: null,
      validator: function (value) {
        return ['primary', 'primary-350', 'highlight', 'success', 'warning', 'danger', 'white'].includes(value)
      }
    },

    size: {
      type: String,
      default: 'md',
      validator: function (value) {
        return ['sm', 'md', 'lg', 'no-padding'].includes(value)
      }
    },

    outline: {
      type: Boolean,
      default: false
    },

    link: {
      type: Boolean,
      default: false
    },

    icon: {
      type: Boolean,
      default: false
    },

    block: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: 'button'
    },

    label: {
      type: Boolean,
      default: false
    },

    noPadding: {
      type: Boolean,
      default: false
    },

    noBold: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    resolveVariant() {
      if (!this.variant) {
        return this.link ? 'highlight' : 'primary'
      }

      return this.variant
    },

    tag() {
      if (this.href) {
        return 'a'
      } else if (this.to) {
        return 'nuxt-link'
      }
      return 'button'
    },

    linkClasses() {
      return [
        'zlink',
        `zlink-${this.size}`,
        `zlink-${this.resolveVariant}`,
        {
          'zlink-block': this.block,
          'no-padding': this.noPadding,
          'no-bold': this.noBold
        }
      ]
    },

    labelClasses() {
      return [
        'zbtn-label',
        `zbtn-label-${this.size}`,
        {
          'zbtn-label-block': this.block
        }
      ]
    },

    buttonClasses() {
      return [
        'zbtn',
        `zbtn-${this.resolveVariant}${this.outline ? '-outline' : ''}`,
        `zbtn-${this.size}${this.icon ? '-icon' : ''}`,
        {
          'zbtn-block': this.block,
          'zbtn-loading': this.loading,
          'zbtn-success-state': this.isSuccess,
          'zbtn-error-state': this.isError
        }
      ]
    },

    classes() {
      if (this.link) return this.linkClasses
      if (this.label) return this.labelClasses
      return this.buttonClasses
    }
  }
}
