
import LazyHydrate from 'vue-lazy-hydration'
import layoutMixin from '~/mixins/layout'
import { injectPartytownScripts } from '~/lib/partytown'

export default {
  name: 'Default',
  components: {
    LazyHydrate
  },

  mixins: [layoutMixin],

  head() {
    const i18nSeo = this.$nuxtI18nHead({ addSeoAttributes: true })

    const baseRouteName = this.getRouteBaseName(this.$route)

    i18nSeo.title = this.$te(`pages.${baseRouteName}.metaTitle`) ? this.$t(`pages.${baseRouteName}.metaTitle`) : ''

    i18nSeo.meta.push(
      {
        hid: 'description',
        name: 'description',
        content: this.$te(`pages.${baseRouteName}.metaDesc`) ? this.$t(`pages.${baseRouteName}.metaDesc`) : ''
      },
      {
        hid: 'og_url',
        property: 'og:url',
        content: `https://www.rvezy.com${this.$route.path}`
      },
      {
        hid: 'og_type',
        property: 'og:type',
        content: 'website'
      },
      {
        hid: 'og_title',
        property: 'og:title',
        content: this.$te(`pages.${baseRouteName}.metaTitle`)
          ? `${this.$t(`pages.${baseRouteName}.metaTitle`)} | RVezy`
          : ''
      },
      {
        hid: 'og_description',
        property: 'og:description',
        content: this.$te(`pages.${baseRouteName}.metaDesc`) ? this.$t(`pages.${baseRouteName}.metaDesc`) : ''
      },
      {
        hid: 'fb_app_id',
        property: 'fb:app_id',
        content: this.$config.facebook.appId
      },
      {
        hid: 'og_image',
        property: 'og:image',
        content: `${this.$config.staticAssetsURL}/web-app/share-img.jpg`
      },
      {
        hid: 'twitter_card',
        name: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        hid: 'twitter_title',
        name: 'twitter:title',
        content: this.$te(`pages.${baseRouteName}.metaTitle`)
          ? `${this.$t(`pages.${baseRouteName}.metaTitle`)} | RVezy`
          : ''
      },
      {
        hid: 'twitter_description',
        name: 'twitter:description',
        content: this.$te(`pages.${baseRouteName}.metaDesc`) ? this.$t(`pages.${baseRouteName}.metaDesc`) : ''
      },
      {
        hid: 'twitter_image',
        name: 'twitter:image',
        content: `${this.$config.staticAssetsURL}/web-app/share-img.jpg`
      },
      {
        hid: 'twitter_url',
        name: 'twitter:url',
        content: `https://www.rvezy.com${this.$route.path}`
      }
    )

    Object.keys(i18nSeo.meta).forEach((key) => {
      if (i18nSeo.meta[key].content) i18nSeo.meta[key].content = this.$path.decode(i18nSeo.meta[key].content)
    })

    Object.keys(i18nSeo.link).forEach((key) => {
      if (i18nSeo.link[key].href) i18nSeo.link[key].href = this.$path.decode(i18nSeo.link[key].href)
    })

    return {
      ...i18nSeo,
      ...injectPartytownScripts({
        $config: this.$config,
        $experiment: this.$experiment,
        $cookies: this.$cookies,
        $user: this.$auth.user
      })
    }
  }
}
