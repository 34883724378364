
import layoutMixin from '~/mixins/layout'
import { injectPartytownScripts } from '~/lib/partytown'
import { isWebview } from '~/lib/useUtils'

export default {
  name: 'Simple',

  mixins: [layoutMixin],

  head() {
    const i18nSeo = this.$nuxtI18nHead({ addSeoAttributes: true })

    return {
      ...i18nSeo,
      ...injectPartytownScripts({
        $config: this.$config,
        $experiment: this.$experiment,
        $cookies: this.$cookies,
        $user: this.$auth.user
      })
    }
  },

  computed: {
    isWebview() {
      return isWebview(this.$cookies.get('platform'))
    },

    isOwnerLandingPage() {
      return this.getRouteBaseName(this.$route) === 'owner'
    }
  }
}
