
export default {
  name: 'ZCollapse',

  props: {
    visible: {
      type: Boolean,
      default: false
    },

    disableTrigger: {
      type: Boolean,
      default: false
    },

    fullWidth: {
      type: Boolean,
      default: false
    },

    padded: {
      type: Boolean,
      default: false
    },

    contentHtml: {
      type: String,
      default: null
    },

    contentClass: {
      type: String,
      default: null
    },

    hideBorder: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOpen: false
    }
  },

  computed: {
    //if no trigger, rely on visible prop
    computedOpen() {
      return this.computedDisableTrigger ? this.visible : this.isOpen
    },

    hasTriggerContent() {
      return Boolean(this.$slots.trigger)
    },

    computedDisableTrigger() {
      return !this.hasTriggerContent || this.disableTrigger
    }
  },

  mounted() {
    this.isOpen = this.visible
  },

  methods: {
    // called by ZCollapseGroup
    close() {
      this.isOpen = false
    },

    toggle() {
      if (this.$parent.accordion) {
        this.$parent.$emit('close-all')
        this.isOpen = true
        return
      }
      this.isOpen = !this.isOpen
    }
  }
}
