import { DELIVERY_ADDON_PREFIX, UNIT_MEASUREMENT } from '@/constants'
import { getDeliveryDistanceBySlug } from '~/lib/rvs/index.js'
import { AddOnType } from '@/lib/enums/frontendEnums'

/**
 * @deprecated Replaced with per km pricing, use only for legacy addons
 */
export const isDeliveryAddon = (addon) => {
  return addon.Slug !== '' && String(addon.Slug).startsWith(DELIVERY_ADDON_PREFIX)
}

export const getFinancialFriendlyAddonName = (addons, country, i18n) => {
  if (!addons) return []

  return addons
    .filter((addon) => addon.AddOnType === 'RVAddOn')
    .map((addon) => {
      let tempName

      if (addon.Slug === 'airport_pickup') {
        tempName = `${i18n.t('DASHBOARD.ADDONS.AIRPORT_PICKUP')} - ${addon.Name}`
      } else if (addon.Slug.startsWith(DELIVERY_ADDON_PREFIX)) {
        const distance = getDeliveryDistanceBySlug(addon.Slug)

        if (country === 'US') {
          tempName = i18n.t('DASHBOARD.ADDONS.DELIVERY', {
            distance: distance.miles,
            unit: UNIT_MEASUREMENT.MILES
          })
        } else {
          tempName = i18n.t('DASHBOARD.ADDONS.DELIVERY', {
            distance: distance.km,
            unit: UNIT_MEASUREMENT.KM
          })
        }
      } else {
        tempName = addon.Name
      }

      return {
        ...addon,
        Name: i18n.te(tempName) ? i18n.t(tempName) : tempName
      }
    })
}

export const getAddons = (rvAddons, storeAddons) => {
  if (!rvAddons) return []

  return rvAddons
    .filter((x) => !x?.Slug?.startsWith(DELIVERY_ADDON_PREFIX))
    .map((addon) => {
      return {
        ...addon,
        isSelected: storeAddons.some((storeAddon) => storeAddon.Slug === addon.Slug)
      }
    })
}

export const getAddonsPicked = (addons) => {
  if (!addons) return []

  return addons
    .filter((addon) => addon?.isSelected)
    .map((addon) => {
      return {
        ...addon,
        IdInRV: addon.Id
      }
    })
}

export const changeRequestItems = (addons) => {
  if (!addons) return []
  return addons.filter((x) => x.AddOnType !== AddOnType.CleaningFee && x.AddOnType !== AddOnType.Roadside)
}
