import Vue from 'vue'
import { createPopper } from '@popperjs/core'
import TooltipComponent from '@/components/style-guide/tooltip'

export default function ({ $device }) {
  const getTooltipPlacement = (modifiers) => {
    if (modifiers.top) {
      return 'top'
    } else if (modifiers.bottom) {
      return 'bottom'
    } else if (modifiers.left) {
      return 'left'
    } else if (modifiers.right) {
      return 'right'
    }
    return 'top'
  }
  const isTooltipContentTypeHtml = (modifiers) => {
    return modifiers?.html
  }

  const enterEvent = $device.isMobileOrTablet ? 'click' : 'mouseenter'
  const leaveEvent = $device.isMobileOrTablet ? 'document' : 'mouseleave'

  Vue.directive('tooltip', {
    bind: (el, binding) => {
      // Once we want to upgrade to Vue3
      // https://stackoverflow.com/questions/65282557/vue3-render-a-component-in-a-custom-directive
      const ZTooltip = Vue.extend(TooltipComponent)
      const tooltip = new ZTooltip()

      const content = binding.value?.text || binding.value
      const slotContent = isTooltipContentTypeHtml(binding.modifiers)
        ? tooltip.$createElement('div', {
            domProps: { innerHTML: content }
          })
        : content

      tooltip.$slots.default = [slotContent]
      tooltip.$mount()

      el.popperInstance = null

      const init = () => {
        if (slotContent && !el.popperInstance) {
          el.insertAdjacentElement('afterend', tooltip.$el)

          el.popperInstance = createPopper(el, tooltip.$el, {
            placement: getTooltipPlacement(binding.modifiers),
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 8]
                }
              },
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['top', 'bottom', 'left', 'right']
                }
              },
              {
                name: 'preventOverflow',
                options: {
                  altAxis: true
                }
              }
            ]
          })
        }
      }

      const show = () => {
        if (slotContent && el.popperInstance) {
          tooltip.$el.setAttribute('data-show', '')
          el.popperInstance.update()
        }
      }

      const hide = () => {
        if (slotContent) {
          tooltip.$el.removeAttribute('data-show')
        }
      }

      const toggle = () => {
        if (tooltip.$el.hasAttribute('data-show')) {
          hide()
        } else {
          show()
        }
      }

      el.clickOutsideEvent = (event) => {
        if (!(el === event.target || el.contains(event.target))) {
          hide()
        }
      }

      el.addEventListener(
        enterEvent,
        (e) => {
          e.preventDefault()
          init()
          toggle()
        },
        false
      )

      if (leaveEvent === 'document') {
        document.body.addEventListener('click', el.clickOutsideEvent)
      } else {
        el.addEventListener(leaveEvent, () => {
          toggle()
        })
      }
    },

    unbind: (el) => {
      el.removeEventListener(enterEvent, () => {})
      if (leaveEvent === 'document') {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      } else {
        el.removeEventListener(leaveEvent, () => {})
      }
      el?.popperInstance?.destroy()
    }
  })

  Vue.directive('slider', {
    bind: (el) => {
      el.classList.add('zslider')

      let startX = null
      let scrollLeft = null
      let isDown = false
      let timeout = null

      function mouseDown(e) {
        isDown = true
        startX = e.pageX - el.offsetLeft
        scrollLeft = el.scrollLeft

        preventScrollSnap(true)

        // Allow enough time for a click to go through
        timeout = setTimeout(() => {
          removePointerEvents(true)
        }, 200)
      }

      function mouseUp() {
        preventScrollSnap(false)
        removePointerEvents(false)
        isDown = false
        clearTimeout(timeout)
      }

      function mouseLeave() {
        preventScrollSnap(false)
        removePointerEvents(false)
        isDown = false
        clearTimeout(timeout)
      }

      function mouseMove(e) {
        if (!isDown) return

        e.preventDefault()
        const x = e.pageX - el.offsetLeft
        const walkX = (x - startX) * 1.25
        el.scrollLeft = scrollLeft - walkX
      }

      function preventScrollSnap(val) {
        if (val) {
          el.classList.add('no-snap')
        } else {
          el.classList.remove('no-snap')
        }
      }

      function removePointerEvents(val) {
        if (val) {
          el.classList.add('no-pointer-events')
        } else {
          el.classList.remove('no-pointer-events')
        }
      }

      if ($device.isDesktop) {
        el.addEventListener('mousedown', (e) => mouseDown(e))
        el.addEventListener('mouseup', (e) => mouseUp(e))
        el.addEventListener('mouseleave', (e) => mouseLeave(e))
        el.addEventListener('mousemove', (e) => mouseMove(e))
      }
    },

    unbind: (el) => {
      if ($device.isDesktop) {
        el.removeEventListener('mousedown', () => {})
        el.removeEventListener('mouseup', () => {})
        el.removeEventListener('mouseleave', () => {})
        el.removeEventListener('mousemove', () => {})
      }
    }
  })
}
