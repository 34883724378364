
import layoutMixin from '~/mixins/layout'
import { injectPartytownScripts } from '~/lib/partytown'

export default {
  name: 'Dashboard',

  mixins: [layoutMixin],

  head() {
    const i18nSeo = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      ...i18nSeo,
      ...injectPartytownScripts({
        $config: this.$config,
        $experiment: this.$experiment,
        $cookies: this.$cookies,
        $user: this.$auth.user
      })
    }
  },

  computed: {
    loadingData() {
      return this.$store.state.view.loading
    }
  }
}
