
export default {
  props: {
    guests: {
      type: Object,
      default: null
    },

    petFriendly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    compGuests() {
      return this.guests ?? this.$search.parameters.guests
    },

    compPetFriendly() {
      return this.petFriendly ?? this.$search.parameters.filters.petFriendly
    },

    guestsCount() {
      return (this.compGuests?.adults ?? 0) + (this.compGuests?.children ?? 0)
    },

    isChanged() {
      return Boolean(this.compGuests?.adults || this.compGuests?.children || this.compPetFriendly !== false)
    }
  }
}
