
import layoutMixin from '@/mixins/layout'
import { injectPartytownScripts } from '~/lib/partytown'

export default {
  name: 'Minimal',

  mixins: [layoutMixin],

  head() {
    return {
      ...injectPartytownScripts({
        $config: this.$config,
        $experiment: this.$experiment,
        $cookies: this.$cookies,
        $user: this.$auth.user
      })
    }
  }
}
