
import ownerCommon from '~/mixins/owner-common.js'

export default {
  mixins: [ownerCommon],
  props: {
    error: {
      type: Object,
      required: true
    }
  }
}
