import locale6c33731f from '../../locales/en'
import locale6c337342 from '../../locales/fr'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"silentFallbackWarn":true},
  vueI18nLoader: true,
  locales: [{"code":"en","iso":"en","file":"en","name":"English"},{"code":"fr","iso":"fr","file":"fr","name":"Français"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://www.rvezy.com",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en","file":"en","name":"English"},{"code":"fr","iso":"fr","file":"fr","name":"Français"}],
  localeCodes: ["en","fr"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "\"",
  5: ":",
  6: "\"",
  7: ".",
  8: ".",
  9: "/",
  10: "l",
  11: "o",
  12: "c",
  13: "a",
  14: "l",
  15: "e",
  16: "s",
  17: "/",
  18: "e",
  19: "n",
  20: "\"",
  21: ",",
  22: "\"",
  23: "f",
  24: "r",
  25: "\"",
  26: ":",
  27: "\"",
  28: ".",
  29: ".",
  30: "/",
  31: "l",
  32: "o",
  33: "c",
  34: "a",
  35: "l",
  36: "e",
  37: "s",
  38: "/",
  39: "f",
  40: "r",
  41: "\"",
  42: "}",
}

export const localeMessages = {
  'en': () => Promise.resolve(locale6c33731f),
  'fr': () => Promise.resolve(locale6c337342),
}
