
import maintenanceMixin from '@/mixins/maintenance'
import { PortalTarget } from 'portal-vue'
import { injectPartytownScripts } from '~/lib/partytown'
import '@stripe/stripe-js'

export default {
  name: 'Webview',
  components: {
    PortalTarget
  },

  mixins: [maintenanceMixin],

  head() {
    return {
      ...injectPartytownScripts({
        $config: this.$config,
        $experiment: this.$experiment,
        $cookies: this.$cookies,
        $user: this.$auth.user,
        $query: this.$route.query
      })
    }
  },

  beforeMount() {
    this.$moment.locale(this.$i18n.locale)
  }
}
