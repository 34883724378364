import { Platform } from '@/lib/enums'

export const isWebview = (platformCookie) => {
  if (platformCookie) {
    const platform = platformCookie

    return platform === Platform.Android || platform === Platform.IOS
  }

  return false
}

/**
 * Checks if the current platform is automated tests
 */
export const isAutomatedTests = (automatedtestCookie) => Boolean(automatedtestCookie)
