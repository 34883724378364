
export default {
  inheritAttrs: false,
  props: {
    button: {
      type: Boolean,
      default: false
    },

    active: {
      type: Boolean,
      default: false
    },

    linkClasses: {
      type: [Array, String],
      default: ''
    }
  },

  computed: {
    tag() {
      return this.button ? 'button' : 'ZLink'
    }
  }
}
