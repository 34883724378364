import { RouteManager } from '~/routeManager.json'
import { pathMatcher } from './pathMatcher'

export default function ({ toPath, $config, $experiment }) {
  const { nuxt3BaseUrl, nuxt3Redirect } = $config

  const isDisabledUser = $experiment.getVariant('rvz16504NewWebclientInNuxt3') === 'disabled'
  const isOffUser = $experiment.getVariant('rvz16504NewWebclientInNuxt3') === 'off'
  const isAlphaUser = $experiment.getVariant('rvz16504NewWebclientInNuxt3') === 'alpha'
  const isBetaUser = $experiment.getVariant('rvz16504NewWebclientInNuxt3') === 'beta'

  const hasMatchingRoutes = RouteManager?.Routes.some((route) => {
    if (isOffUser) {
      return false
    }

    const pathMatches = pathMatcher(route.Pattern, toPath)

    // If route release group is alpha, only alpha users can access it
    if (route.ReleaseGroup === 'Alpha') {
      return pathMatches && isAlphaUser
    }

    // If route release group is beta, only alpha and beta users can access it
    if (route.ReleaseGroup === 'Beta') {
      return pathMatches && (isAlphaUser || isBetaUser)
    }

    // If route release group is control, any users can access it
    return pathMatches
  })

  const shouldRedirectToNuxt3App =
    !['/experiments', '/callback-oauth'].includes(toPath) && !isDisabledUser && nuxt3Redirect && hasMatchingRoutes

  return {
    shouldRedirectToNuxt3App,
    nuxt3BaseUrl
  }
}
