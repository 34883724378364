// Show insurance:
// RV Cottage and TravelTrailer can be insured
// Canadian MotorHome from Ontario, Quebec, Alberta, Nova Scotia, British Columbia and Newfoundland and Labrador can be insured
// American MotorHome from any state

export default function (rv) {
  if (!rv) return false

  if (!rv.Location || !rv.Location.State || !rv.RentalType) return true

  if (rv.RentalType === 'RVCottage' || rv.RentalType === 'TravelTrailer') return true

  if (
    rv.RentalType === 'MotorHome' &&
    rv.Country === 'CA' &&
    [
      'Ontario',
      'Quebec',
      'Alberta',
      'Nova Scotia',
      'British Columbia',
      'Newfoundland and Labrador',
      'New Brunswick',
      'Prince Edward Island'
    ].includes(rv.Location.State)
  ) {
    return true
  }

  if (rv.Country === 'US' && rv.RentalType === 'MotorHome') return true

  return false
}
