import { v4 as uuidv4 } from 'uuid'

import { getSegmentAnonymousId } from '~/lib/tracking/track'

export default function ({ req, $axios, $config, $device, $cookies }, inject) {
  const isClientOrHasNoRequest = !process.server || !req
  const external = $axios.create()
  delete external.defaults.headers.common.Authorization

  const headers = req?.headers ? { ...req.headers } : {}

  // Set user-agent when making server side requests
  if (process.server) {
    $axios.onRequest((config) => {
      if (req && !config.headers.common) {
        if (!config.headers['user-agent'] && !config.headers['User-Agent']) {
          config.headers['User-Agent'] = req.headers['user-agent'] || req.headers['User-Agent'] || false
        }
      }

      if (headers['cf-ipcountry']) {
        config.headers['RVZ-ipcountry'] = headers['cf-ipcountry']
      }

      if (headers['cf-region']) {
        config.headers['RVZ-region'] = headers['cf-region']
      }

      if (headers['cf-ipcity']) {
        config.headers['RVZ-ipcity'] = headers['cf-ipcity']
      }
    })
  }

  // Set Authorization token and meta headers on Axios headers
  const secureBaseUrls = [$config.apiUrl]

  $axios.onRequest((config) => {
    if (secureBaseUrls.some((baseUrl) => config.url.startsWith(baseUrl))) {
      config.withCredentials = true
      if ($cookies.get('access_token')) {
        config.headers['Authorization'] = `Bearer ${$cookies.get('access_token')}`
      }
    }

    // Global headers
    config.headers['Platform'] = $cookies.get('platform') || ($device.isMobileOrTablet ? 'mobile_web' : 'web')
    config.headers['RVZ-Correlation-Id'] = uuidv4()

    // Configure DataDog Distributed header forwarding
    if (!isClientOrHasNoRequest) {
      if (req.headers['x-datadog-trace-id']) {
        config.headers['x-datadog-trace-id'] = req.headers['x-datadog-trace-id']
      }

      if (req.headers['x-datadog-parent-id']) {
        config.headers['x-datadog-parent-id'] = req.headers['x-datadog-parent-id']
      }

      if (req.headers['x-datadog-origin']) {
        config.headers['x-datadog-origin'] = req.headers['x-datadog-origin']
      }

      if (req.headers['x-datadog-sampling-priority']) {
        config.headers['x-datadog-sampling-priority'] = req.headers['x-datadog-sampling-priority']
      }
    }

    if (process.client) {
      // Set Segment's anonymousId from cookie or localstorage
      config.headers['RVZ-Anonymous-Id'] = getSegmentAnonymousId()
    }
  })

  // Inject to context as $external
  inject('external', external)
}
