/* eslint-disable no-console */
/**
 * Helper utility that logs messages in dev mode on the clinet and server and
 * formats them with color for easy visibility.
 *
 * @example logDev({ domain: 'lib', func: 'useGoogleMaps', message: 'placePredictions', color: 'red' })
 */
export function logDev({ domain, func, color = 'blue', message }) {
  if (process.env.NODE_ENV === 'development') {
    let color1 = '#1d4ed8'
    let color2 = '#dbeafe'

    switch (color) {
      case 'green':
        color1 = '#047857'
        color2 = '#d1fae5'
        break

      case 'red':
        color1 = '#be123c'
        color2 = '#ffe4e6'
        break

      case 'orange':
        color1 = '#c2410c'
        color2 = '#ffedd5'
        break

      case 'blue':
      default:
        color1 = '#1d4ed8'
        color2 = '#dbeafe'
        break
    }

    // Log with colors in the browser console, but use plain output on the
    // server side.
    if (process.client) {
      console.log(
        `%c ${domain} %c %c ${func} %c`,
        `background: ${color1}; color: #fff`,
        'color: #333',
        `background: ${color2}; color: ${color1}`,
        'color: #333',
        message
      )
    } else {
      console.log(`[${domain}] ${func} - ${message}`)
    }
  }
}
