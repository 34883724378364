import { DELIVERY_ADDON_PREFIX } from '@/constants'
import { GoalsAnswerEnum, PreferenceEnumKey, BookingPreferencesTypeEnumKey } from '~/lib/enums'

export const state = () => ({
  popularRVs: [],
  popularRVsSearchResultId: null,
  popularFeaturedRVs: [],
  popularFeaturedRVsSearchResultId: null,

  myRVsIndex: 0,
  myRVs: [],
  rv: null
})

export const newDraftRvTemplate = ({ securityDeposit = 1000 }) => ({
  RentalType: null,
  Make: null,
  RVBrandOther: null,
  Model: null,
  RVType: null,
  Year: null,
  Length: null,
  Weight: null,
  HitchWeight: null,
  HitchSize: null,
  Slideouts: 0,
  SeatbeltTwoPoint: 0,
  SeatbeltThreePoint: 0,
  BedQueen: 0,
  BedSofa: 0,
  BedDinette: 0,
  BedKing: 0,
  BedBunkDouble: 0,
  BedBunkTwin: 0,
  Guests: 2,
  Location: {
    Country: null,
    State: null,
    City: null,
    Street: null,
    PostCode: null,
    ParkName: null
  },
  InteriorFeatures: ['Petfriendly'],
  ParkAmenities: [],

  RVName: null,
  RVDescription: null,
  Photos: [],

  DefaultPrice: 0,
  WeeklyDiscount: 10,
  MonthlyDiscount: 20,
  SecurityDeposit: securityDeposit,
  CleaningFee: 60,
  MinimumRentalDay: 2,
  AllowShortStays: true,

  IsChargeMileage: false,
  DailyKMAllowed: null,
  SurchargePerExtraKM: null,

  FreeHoursPerDay: null,
  ChargePerHourOver: null,

  TaxAccountQSTNumber: null,
  TaxAccountGSTNumber: null,
  TaxAccountPSTNumber: null,
  TaxAccountNumber: null,

  ListingFlowVersion: 'v2',
  ListingStepName: 'goal-settings',
  ListingProgress: 0,

  // Other fields
  InsuranceProvide: 'Other',
  CourtesyRequests: [],
  WeeklyDiscountTypeEnum: 1,
  MonthDiscountTypeEnum: 1,
  IsRegisteredCollection: false,
  IsDraft: true,
  HSStartDate: null,
  HSEndDate: null,

  IsRegisteredInAPersonalName: true,

  RVPreferences: [],

  ApplyIncomeProtectionByDefault: true,
  InsideLeadDays: 2,
  OutsideLeadDays: 730,
  DynamicPricingOptIn: true,
  ShortStaysOptIn: true,
  CheckInAvailabilityDays: [],
  CheckoutAvailabilityDays: [],
  CheckInEndTime: null,
  CheckInStartTime: null,
  CheckoutEndTime: null,
  CheckoutStartTime: null,
  AllowOnlyExperiencedSimilarRV: false,
  AllowCrossBorderTravel: true,
  AllowFestivalEventsParticipation: PreferenceEnumKey.Yes,
  BookingSeasonRequired: false,
  BookingSeasonStart: -1,
  BookingSeasonEnd: -1,
  FrequencyPersonalTrips: null,
  FrequencyRentOnRvezy: null,
  InstabookOwnerOptedIn: false,
  InstantBookEligible: false,
  PreBookingMessage: null
})

export const getters = {
  rvSelected: (state) => {
    // THIS GETTER IS ONLY FOR THE MYRVS STATE
    return state.myRVs[state.myRVsIndex]
  },

  rvSelectedIndex: (state) => {
    return state.myRVsIndex
  }
}

export const actions = {
  async UPDATE_RV({ commit }, { rvId, data }) {
    // This is for updating an RV inside myRVs array
    const rv = await this.$axios.$patch(`${this.$config.apiUrl}/api/rvlistings/${rvId}`, data)

    commit('UPDATE_RV', rv)
  },

  async SELECT_RV({ state, dispatch }, rvId) {
    const index = state.myRVs.findIndex((rv) => rv.Id === rvId)
    await dispatch('SET_MY_RVS_INDEX', index)
  },

  async SET_MY_RVS_INDEX({ commit }, index) {
    await commit('SET_MY_RVS_INDEX', index)
  },

  async SET_POPULAR_RVS({ commit }, { rvs, searchResultId }) {
    await commit('SET_POPULAR_RVS', { rvs, searchResultId })
  },

  async SET_POPULAR_FEATURED_RVS({ commit }, { rvs, searchResultId }) {
    await commit('SET_POPULAR_FEATURED_RVS', { rvs, searchResultId })
  },

  async CREATE_NEW_DRAFT_RV({ commit }) {
    const template = newDraftRvTemplate({
      securityDeposit: this.$experiment.isEnabled('zeroDollarSecurityDeposit') ? 0 : 1000
    })

    await commit('SET_RV', template)
  },

  async GET_MY_RVS({ commit }) {
    const rvs = await this.$axios.$get(`${this.$config.apiUrl}/api/rvlistings/myrvs`)
    commit('SET_MY_RVS', rvs)
  },

  async GET_MY_RV_BY_ID({ commit }, id) {
    const rv = await this.$axios.$get(`${this.$config.apiUrl}/api/rvlistings/my-rvs/${id}`)
    commit('SET_RV', rv)
  },

  async UPDATE_MY_RV({ commit, state }) {
    const rv = await this.$axios.$put(`${this.$config.apiUrl}/api/rvlistings/${state.rv.Id}`, state.rv)
    commit('SET_RV', rv)
  },

  async REVALIDATE_FIELD({ commit, state }, field) {
    const oldValue = state.rv[field]
    await commit('UPDATE_FIELD', { key: field, value: null })
    await commit('UPDATE_FIELD', { key: field, value: oldValue })
  },

  async UPDATE_FIELDS({ commit }, data) {
    // This should have some sort of sanitization of the data object
    // We don't have that yet, so I won't do it here

    await commit('UPDATE_FIELDS', data)
  },

  async ADD_PET_FRIENDLY({ commit, state }) {
    if (state.rv.InteriorFeatures.includes('Petfriendly')) return

    await commit('UPDATE_FIELDS', { InteriorFeatures: [...state.rv.InteriorFeatures, 'Petfriendly'] })
  },

  async REMOVE_PET_FRIENDLY({ commit, state }) {
    if (!state.rv.InteriorFeatures.includes('Petfriendly')) return

    await commit('UPDATE_FIELDS', {
      InteriorFeatures: state.rv.InteriorFeatures.filter((feature) => feature !== 'Petfriendly')
    })
  },

  async UPDATE_CHECK_IN({ commit, state }, { start, end }) {
    if (start !== state.rv.CheckInStartTime) {
      await commit('UPDATE_FIELD', { key: 'CheckInStartTime', value: start })
    }
    if (end !== state.rv.CheckInEndTime) {
      await commit('UPDATE_FIELD', { key: 'CheckInEndTime', value: end })
    }
  },

  async UPDATE_CHECK_OUT({ commit, state }, { start, end }) {
    if (start !== state.rv.CheckoutStartTime) {
      await commit('UPDATE_FIELD', { key: 'CheckoutStartTime', value: start })
    }
    if (end !== state.rv.CheckoutEndTime) {
      await commit('UPDATE_FIELD', { key: 'CheckoutEndTime', value: end })
    }
  },

  async UPDATE_MIN_DAYS({ commit }, minDays) {
    await commit('UPDATE_FIELD', { key: 'MinimumRentalDay', value: minDays })
  },

  async UPDATE_MIN_LEAD_TIME({ commit }, minLeadTime) {
    await commit('UPDATE_FIELD', { key: 'InsideLeadDays', value: minLeadTime })
  },

  async UPDATE_MAX_LEAD_TIME({ commit }, maxLeadTime) {
    await commit('UPDATE_FIELD', { key: 'OutsideLeadDays', value: maxLeadTime })
  },

  async UPDATE_CHECKOUT_DAYS({ commit }, days) {
    await commit('UPDATE_FIELD', { key: 'CheckInAvailabilityDays', value: days })
    await commit('UPDATE_FIELD', { key: 'CheckoutAvailabilityDays', value: days })
  },

  async UPDATE_BOOKING_SEASON({ commit }, { start, end }) {
    if (start) await commit('UPDATE_FIELD', { key: 'BookingSeasonStart', value: start })
    if (end) await commit('UPDATE_FIELD', { key: 'BookingSeasonEnd', value: end })
  },

  async UPDATE_TOWING_EXPERIENCE({ commit }, value) {
    await commit('UPDATE_FIELD', { key: BookingPreferencesTypeEnumKey.AllowOnlyExperiencedSimilarRV, value })
  },

  async UPDATE_EVENTS_PREFERENCE({ commit }, value) {
    await commit('UPDATE_FIELD', { key: BookingPreferencesTypeEnumKey.AllowFestivalEventsParticipation, value })
  },
  async UPDATE_FREQUENCY_PERSONAL_TRIPS({ commit }, { value }) {
    if (value && !Object.keys(GoalsAnswerEnum).includes(value)) return

    await commit('UPDATE_FIELD', { key: 'FrequencyPersonalTrips', value })
  },
  async UPDATE_FREQUENCY_RENT_ON_RVEZY({ commit }, { value }) {
    if (value && !Object.keys(GoalsAnswerEnum).includes(value)) return

    await commit('UPDATE_FIELD', { key: 'FrequencyRentOnRvezy', value })
  },
  async UPDATE_SLIDEOUTS({ commit }, value) {
    await commit('UPDATE_FIELD', { key: 'Slideouts', value })
  },
  async UPDATE_SEATBELT_TWO_POINT({ commit }, value) {
    await commit('UPDATE_FIELD', { key: 'SeatbeltTwoPoint', value })
  },
  async UPDATE_SEATBELT_THREE_POINT({ commit }, value) {
    await commit('UPDATE_FIELD', { key: 'SeatbeltThreePoint', value })
  },
  async UPDATE_GUESTS({ commit }, value) {
    await commit('UPDATE_FIELD', { key: 'Guests', value })
  },
  async UPDATE_BED({ commit }, { value, type }) {
    await commit('UPDATE_FIELD', { key: type, value })
  },
  async UPDATE_INSTANT_BOOK({ commit }, value) {
    await commit('UPDATE_FIELD', { key: 'InstabookOwnerOptedIn', value })
  },
  async UPDATE_INSTANT_BOOK_ELIGIBILITY({ commit }, value) {
    await commit('UPDATE_FIELD', { key: 'InstantBookEligible', value })
  },
  //TODO this should be replaced with a more specific functions
  //I just added this to make the edit page work without changing much
  async UPDATE_FIELD({ commit }, { key, value }) {
    await commit('UPDATE_FIELD', { key, value })
  }
}

export const mutations = {
  UPDATE_RV(state, rv) {
    state.myRVs = state.myRVs.map((myRV) => (myRV.Id === rv.Id ? rv : myRV))
  },

  SET_MY_RVS_INDEX(state, index) {
    state.myRVsIndex = index
  },

  SET_POPULAR_RVS(state, { rvs, searchResultId }) {
    state.popularRVs = rvs
    state.popularRVsSearchResultId = searchResultId
  },

  SET_POPULAR_FEATURED_RVS(state, { rvs, searchResultId }) {
    state.popularFeaturedRVs = rvs
    state.popularFeaturedRVsSearchResultId = searchResultId
  },

  SET_MY_RVS(state, rvs) {
    state.myRVs = rvs
  },

  CLEAR_MY_RVS(state) {
    state.myRVs = []
  },

  CLEAR_RV(state) {
    state.rv = null
  },

  SET_RV(state, rv) {
    if (rv) {
      rv.OfferDelivery = false

      if (rv.ListAddOns && rv.ListAddOns.length) {
        // Sanitize Add-ons
        const addons = []
        const map = new Map()
        for (const item of rv.ListAddOns) {
          if (item.Status === 'Active') {
            if (!map.has(item.Slug)) {
              map.set(item.Slug, true) // set any value to Map
              addons.push(item)
            }

            // Set OfferDelivery flag
            if (item.Slug && item.Slug.includes(DELIVERY_ADDON_PREFIX)) {
              rv.OfferDelivery = true
            }
          }
        }
        rv.ListAddOns = addons
      }

      // Convert delivery amounts because BE expects integer and FE is using decimal
      if (Number(rv.DeliveryMaxDistance) >= 1) {
        rv.OfferDelivery = true
      } else {
        rv.DeliveryMinimumCostCents = null
        rv.DeliveryCentsPerDistanceUnit = null
      }

      // Sanitize RVDescription
      if (rv.RVDescription) {
        rv.RVDescription = rv.RVDescription.replace('</p>', ' \n')
          .replace('</br>', ' \n')
          .replace('<br>', ' \n')
          .replace('<br/>', ' \n')
        rv.RVDescription = rv.RVDescription.replace(/(<([^>]+)>)/gi, '')
      }

      state.rv = rv
    } else {
      state.rv = null
    }
  },

  UPDATE_FIELD(state, { key, value }) {
    state.rv[key] = value
  },

  UPDATE_FIELDS(state, data) {
    state.rv = { ...state.rv, ...data }
  },

  UPDATE_LOCATION_FIELD(state, { key, value }) {
    state.rv.Location[key] = value
  },

  UPDATE_LOCATION_COUNTRY(state, Country) {
    state.rv.Location = {
      Country,
      Street: null,
      City: null,
      State: null,
      PostCode: null,
      Latitude: null,
      Longitude: null
    }
  },

  CLEAR_LOCATION_FIELDS(state) {
    state.rv.Location.Street = null
    state.rv.Location.City = null
    state.rv.Location.State = null
    state.rv.Location.PostCode = null
    state.rv.Location.Latitude = null
    state.rv.Location.Longitude = null
  },

  UPDATE_LISTADDON_FIELD(state, { key, index, value }) {
    state.rv.ListAddOns[index][key] = value
  },

  UPDATE_INSURANCE_FIELD(state, { key, value }) {
    state.rv.InsuranceProfile[key] = value
  }
}
