
export default {
  props: {
    cols: {
      type: [Number, String],
      default: 12
    },

    xs: {
      type: [Number, String],
      default: undefined
    },

    sm: {
      type: [Number, String],
      default: undefined
    },

    md: {
      type: [Number, String],
      default: undefined
    },

    lg: {
      type: [Number, String],
      default: undefined
    },

    xl: {
      type: [Number, String],
      default: undefined
    },

    xxl: {
      type: [Number, String],
      default: undefined
    },

    xxxl: {
      type: [Number, String],
      default: undefined
    },

    start: {
      type: [Number, String],
      default: undefined
    },

    startLg: {
      type: [Number, String],
      default: undefined
    },

    startXl: {
      type: [Number, String],
      default: undefined
    }
  },

  computed: {
    spanCols() {
      if (this.isValidSpan(this.cols)) return `g-col-${this.cols}`
      return undefined
    },

    spanXS() {
      if (this.isValidSpan(this.xs)) return `g-col-xs-${this.xs}`
      return undefined
    },

    spanSM() {
      if (this.isValidSpan(this.sm)) return `g-col-sm-${this.sm}`
      return undefined
    },

    spanMD() {
      if (this.isValidSpan(this.md)) return `g-col-md-${this.md}`
      return undefined
    },

    spanLG() {
      if (this.isValidSpan(this.lg)) return `g-col-lg-${this.lg}`
      return undefined
    },

    spanXL() {
      if (this.isValidSpan(this.xl)) return `g-col-xl-${this.xl}`
      return undefined
    },

    spanXXL() {
      if (this.isValidSpan(this.xl)) return `g-col-xxl-${this.xxl}`
      return undefined
    },

    spanXXXL() {
      if (this.isValidSpan(this.xl)) return `g-col-xxxl-${this.xxxl}`
      return undefined
    },

    startClass() {
      if (this.isValidSpan(this.start)) return `g-col-start-${this.start}`
      return undefined
    },

    startLgClass() {
      if (this.isValidSpan(this.startLg)) return `g-col-start-lg-${this.startLg}`
      return undefined
    },

    startXlClass() {
      if (this.isValidSpan(this.startXl)) return `g-col-start-xl-${this.startXl}`
      return undefined
    }
  },

  methods: {
    isValidSpan(span) {
      span = parseInt(span)
      return span >= 1 && span <= 12
    }
  }
}
