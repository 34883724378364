
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },

    noWrap: {
      type: Boolean,
      default: false
    },

    fixed: {
      type: Boolean,
      default: false
    },

    noCenter: {
      type: Boolean,
      default: false
    },

    zIndex: {
      type: String,
      default: '10'
    },

    variant: {
      type: String,
      default: 'light'
    },

    opaque: {
      type: Boolean,
      default: false
    },

    message: {
      type: String,
      default: ''
    }
  },

  computed: {
    formattedVariant() {
      if (this.isSupportedVariant(this.variant)) {
        return `zoverlay-${this.variant}`
      }
      return 'zoverlay-light'
    }
  },

  methods: {
    isSupportedVariant(variant) {
      return ['primary', 'light', 'transparent', 'clear', 'white'].includes(variant)
    }
  }
}
