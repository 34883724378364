
export default {
  props: {
    size: {
      type: String,
      default: 'md'
    },

    variant: {
      type: String,
      default: 'primary'
    }
  },

  computed: {
    formattedSize() {
      if (this.isSupportedSize(this.size)) {
        return `zspinner-${this.size}`
      }
      return 'zspinner-md'
    },

    formattedVariant() {
      if (this.isSupportedVariant(this.variant)) {
        return `zspinner-${this.variant}`
      }
      return 'zspinner-highlight'
    }
  },

  methods: {
    isSupportedSize(size) {
      return ['sm', 'md'].includes(size)
    },

    isSupportedVariant(variant) {
      return ['primary', 'highlight', 'light'].includes(variant)
    }
  }
}
