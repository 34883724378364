import { render, staticRenderFns } from "./localization.vue?vue&type=template&id=2697bd77&scoped=true"
import script from "./localization.vue?vue&type=script&lang=js"
export * from "./localization.vue?vue&type=script&lang=js"
import style0 from "./localization.vue?vue&type=style&index=0&id=2697bd77&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2697bd77",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Fa: require('/home/vsts/work/1/s/components/fa.vue').default,ZButton: require('/home/vsts/work/1/s/components/style-guide/button.vue').default,CurrencySelector: require('/home/vsts/work/1/s/components/app/currency-selector.vue').default})
