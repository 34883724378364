
import { CurrencyEnumKey } from '~/lib/enums/index.js'
import { trackCurrencyUpdated } from '@/lib/tracking'

export default {
  computed: {
    currencies() {
      return CurrencyEnumKey.$all()
    },

    selectedCurrency() {
      return this.$store.getters['currency/selectedCurrencyCode']
    }
  },

  methods: {
    toggleCurrency() {
      const newCurrency = this.selectedCurrency === CurrencyEnumKey.USD ? CurrencyEnumKey.CAD : CurrencyEnumKey.USD
      const previousCurrency = this.selectedCurrency

      this.$store.dispatch('currency/SET_CURRENCY', newCurrency)

      trackCurrencyUpdated({
        currentPage: this.getRouteBaseName(),
        previousCurrency,
        updatedCurrency: newCurrency
      })
    }
  }
}
