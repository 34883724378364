
import { trackSearchFilterMenuOpened } from '~/lib/tracking'
import { SearchCollapsibleFilters } from '@/lib/enums/frontendEnums'

export default {
  data() {
    return {
      mobileSearchFiltersLoaded: false,
      showMobileSearchFilters: false
    }
  },

  computed: {
    filters() {
      return this.$search.parameters.filters
    },

    activeFiltersCount() {
      let count = 0

      if (Object.values(this.$search.parameters.filters.drivable ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (Object.values(this.$search.parameters.filters.towable ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (this.$search.parameters.filters.rvCottage) {
        count++
      }

      if (this.$search.parameters.filters.delivery) {
        count++
      }

      if (Object.values(this.$search.parameters.filters.rvPrice ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (this.$search.parameters.filters?.instantBook) {
        count++
      }

      if (Object.values(this.$search.parameters.filters.amenities ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (Object.values(this.$search.parameters.filters.rvWeight ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (Object.values(this.$search.parameters.filters.rvLength ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (Object.values(this.$search.parameters.filters.rvYear ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (Object.values(this.$search.parameters.filters.rvBrand ?? {}).filter((x) => x).length > 0) {
        count++
      }

      if (this.$search.parameters.filters.festivalFriendly) {
        count++
      }

      if (this.$search.parameters.filters.experienceNotRequired) {
        count++
      }

      if (this.$search.parameters.filters?.petFriendly) {
        count++
      }

      if (this.$search.parameters.filters?.superhost) {
        count++
      }

      return count
    }
  },

  methods: {
    openMobileFilters() {
      this.mobileSearchFiltersLoaded = true
      this.showMobileSearchFilters = !this.showMobileSearchFilters

      trackSearchFilterMenuOpened({
        menuName: SearchCollapsibleFilters.Filters,
        pageSource: this.getRouteBaseName()
      })
    }
  }
}
