
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },

    target: {
      type: String,
      default: null
    },

    rel: {
      type: String,
      default: null
    }
  }
}
