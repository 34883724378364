import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isBetween from 'dayjs/plugin/isBetween'
import weekday from 'dayjs/plugin/weekday'

// Not referenced, but used internally by dayjs.
// eslint-disable-next-line no-unused-vars
import french from 'dayjs/locale/fr'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.extend(weekday)

/**
 * This is a drop-in replacement for the moment module. Which means that
 * anything in our source that uses $moment and any of it's methods, should
 * continue to work.
 *
 * Once the moment module has been completely replaced, we can refactor the
 * source code to use dayjs directly instead.
 *
 * Important to note is that dayjs objects are immutable, as opposed to momentjs
 * objects. So you can't assign the result to a variable and mutate it
 * afterwards.
 */
export default dayjs
