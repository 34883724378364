import { render, staticRenderFns } from "./logo-rvezy.vue?vue&type=template&id=470319f9&scoped=true"
import script from "./logo-rvezy.vue?vue&type=script&lang=js"
export * from "./logo-rvezy.vue?vue&type=script&lang=js"
import style0 from "./logo-rvezy.vue?vue&type=style&index=0&id=470319f9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "470319f9",
  null
  
)

export default component.exports