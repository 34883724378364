import Enum from './enum.js'

/**
 * @enum {string}
 */
export class SegmentEvents extends Enum {
  static AffiliateLinkClicked = 'AffiliateLinkClicked'
  static AppBannerClicked = 'AppBannerClicked'
  static AppBannerClosed = 'AppBannerClosed'
  static BuyNowPayLaterContinueClicked = 'BuyNowPayLaterContinueClicked'
  static BannerClicked = 'BannerClicked'
  static BookingDetailsViewed = 'BookingDetailsViewed'
  static BookingPaymentFormViewed = 'BookingPaymentFormViewed'
  static BookingPaymentInfoSubmitClicked = 'BookingPaymentInfoSubmitClicked'
  static BookingPaymentInfoSubmitted = 'BookingPaymentInfoSubmitted'
  static BookingPaymentInfoSubmitFailed = 'BookingPaymentInfoSubmitFailed'
  static CalendarImportFieldClicked = 'CalendarImportFieldClicked'
  static CalendarImportModalViewed = 'CalendarImportModalViewed'
  static CalendarImportSubmitted = 'CalendarImportSubmitted'
  static CalendarUpdateSuccessful = 'CalendarUpdateSuccessful'
  static CTAClicked = 'CTAClicked'
  static CurrencySelectorClicked = 'CurrencySelectorClicked'
  static CurrencyUpdated = 'CurrencyUpdated'
  static DeliveryEducationalModalClicked = 'DeliveryEducationalModalClicked'
  static DisputeChargesClicked = 'DisputeChargesClicked'
  static EditListingSaved = 'EditListingSaved'
  static EditListingViewed = 'EditListingViewed'
  static ExperimentViewed = 'ExperimentViewed'
  static ExternalLinkClicked = 'ExternalLinkClicked'
  static GuestRequestMoneySent = 'GuestRequestMoneySent'
  static HelpCenterClicked = 'HelpCenterClicked'
  static ListingClicked = 'ListingClicked'
  static ListingStepSubmitted = 'ListingStepSubmitted'
  static ListingSetPresented = 'ListingSetPresented'
  static ListingSetViewed = 'ListingSetViewed'
  static ListingViewed = 'ListingViewed'
  static MessageTemplateSelected = 'MessageTemplateSelected'
  static OwnerCalendarViewed = 'OwnerCalendarViewed'
  static OwnerCancellationModalClosed = 'OwnerCancellationModalClosed'
  static OwnerCancellationModalViewed = 'OwnerCancellationModalViewed'
  static PayoutGuaranteeModalViewed = 'PayoutGuaranteeModalViewed'
  static PaymentUpdateSubmitted = 'PaymentUpdateSubmitted'
  static PaymentUpdateSuccessful = 'PaymentUpdateSuccessful'
  static PaymentUpdateViewed = 'PaymentUpdateViewed'
  static PhotoCarouselUpdateSuccessful = 'PhotoCarouselUpdateSuccessful'
  static PhotoLightBoxUpdateSuccessful = 'PhotoLightBoxUpdateSuccessful'
  static PointOfInterestViewed = 'PointOfInterestViewed'
  static ProfileViewed = 'ProfileViewed'
  static RefundMethodSelected = 'refundMethodSelected'
  static RequestBookingBasicsSubmitted = 'RequestBookingBasicsSubmitted'
  static RequestBookingBasicsViewed = 'RequestBookingBasicsViewed'
  static RequestBookingEnhanceViewed = 'RequestBookingEnhanceViewed'
  static RequestMoneyClicked = 'RequestMoneyClicked'
  static RequestMoneyReviewed = 'RequestMoneyReviewed'
  static SearchFilterMenuOpened = 'SearchFilterMenuOpened'
  static SearchForRvClicked = 'searchForRvClicked'
  static SearchViewed = 'SearchViewed'
  static SellMyRvClicked = 'SellMyRvClicked'
  static SendMoneyBookingClicked = 'SendMoneyBookingClicked'
  static SendMoneyExited = 'SendMoneyExited'
  static ShareButtonClicked = 'ShareButtonClicked'
  static SignupExited = 'SignupExited'
  static SignupFailed = 'SignupFailed'
  static SignupViewed = 'SignupViewed'
  static SortMenuClosed = 'SortMenuClosed'
  static SortMenuViewed = 'SortMenuViewed'
  static SortOptionClicked = 'SortOptionClicked'
  static SpecialOfferFormSubmittedSuccessfully = 'SpecialOfferFormSubmittedSuccessfully'
  static TripQuotePresented = 'TripQuotePresented'
  static TripQuoteViewed = 'TripQuoteViewed'
  static ViewDetailsPriceBreakdownViewed = 'ViewDetailsPriceBreakdownViewed'
  static WebPushPermissionRequested = 'WebPushPermissionRequested'
  static WebPushPermissionResponded = 'WebPushPermissionResponded'
  static TipsAndResourcesCardClicked = 'TipsAndResourcesCardClicked'
  static SuperHostLandingPageViewed = 'SuperHostLandingPageViewed'
  static SuperhostLearnMoreClicked = 'SuperhostLearnMoreClicked'
  static BuyRvClicked = 'BuyRvClicked'
  static ReviewRequestViewed = 'ReviewRequestViewed'
  static ReviewRequestSubmitted = 'ReviewRequestSubmitted'
  static RequestBookingSent = 'RequestBookingSent'
  // Segment Ecommerce V2 Events
  static OrderCompleted = 'Order Completed'
  static ProductAdded = 'Product Added'
  static ProductListViewed = 'Product List Viewed'
  static ProductViewed = 'Product Viewed'
  static ProductsSearched = 'Products Searched'
  static Signup = 'Signed Up'
}

/**
 * @enum {string}
 */
export class SegmentUserSignupSources extends Enum {
  static 0 = 'Web'
  static 1 = 'IOS'
  static 2 = 'Android'
}

Object.freeze(SegmentEvents)
Object.freeze(SegmentUserSignupSources)
