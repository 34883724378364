export const parseGooglePlace = (place) => {
  return {
    searchable: true,
    placeId: place?.place_id ?? undefined,
    mainText: place?.structured_formatting.main_text ?? undefined,
    secondaryText: place?.structured_formatting?.secondary_text ?? undefined,
    types: place?.types ?? undefined,
    fullName: place?.description ?? undefined
  }
}

export const parseGoogleGeocode = ({ fullName, geocode }) => {
  const parsed = {
    searchable: true,
    placeId: geocode.place_id,
    mainText: geocode.address_components[0].long_name,
    types: geocode?.types ?? undefined,
    center: geocode.geometry.location?.toJSON ? geocode.geometry.location.toJSON() : geocode.geometry.location,
    bounds: geocode.geometry.bounds?.toJSON ? geocode.geometry.bounds.toJSON() : geocode.geometry.bounds
  }

  geocode.address_components.forEach((addrComponent) => {
    if (addrComponent.types.includes('locality')) {
      parsed.city = addrComponent
    } else if (addrComponent.types.includes('administrative_area_level_1')) {
      parsed.region = addrComponent
    } else if (addrComponent.types.includes('country')) {
      parsed.country = addrComponent
    }
  })

  parsed.fullName =
    fullName ??
    [parsed.city?.long_name, parsed.region?.short_name, parsed.country?.long_name].filter((x) => x).join(', ')

  return parsed
}
