import { RvUpdateState } from '~/lib/enums/index.js'

export const state = () => ({
  currentUpdateState: RvUpdateState.Pending
})

export const actions = {
  async setUpdateState({ commit }, val) {
    await commit('SET_UPDATE_STATE', val)
  }
}

export const mutations = {
  SET_UPDATE_STATE(state, updateState) {
    state.currentUpdateState = updateState
  }
}

export const getters = {
  canUpdateCalendar: (state) => {
    return state.currentUpdateState === RvUpdateState.DeliverPerKM
  }
}
