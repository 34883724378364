export default {
  computed: {
    routeBaseName() {
      return this.getRouteBaseName(this.$route) || ''
    },

    isRentalPage() {
      return this.routeBaseName === 'rv-rental'
    },

    isRequestToBookPage() {
      return this.routeBaseName.startsWith('rv-rental-request-to-book')
    },

    isPaymentPage() {
      return this.routeBaseName === 'dashboard-bookings-id-payment'
    }
  },

  methods: {
    getCurrentRouteToLocaleCode(code) {
      if (!this.$route.name) {
        return this.localePath('/', code)
      }

      return this.$path.decode(this.switchLocalePath(code))
    }
  }
}
