
export default {
  props: {
    noGutters: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    noGuttersClass() {
      return this.noGutters ? 'zgrid-no-gutters' : undefined
    }
  }
}
