import maintenanceMixin from '@/mixins/maintenance'
import { PortalTarget } from 'portal-vue'
import '@stripe/stripe-js'
import useBraze from '~/lib/useBraze'

export default {
  components: {
    PortalTarget
  },

  mixins: [maintenanceMixin],

  data() {
    return {
      braze: null
    }
  },

  computed: {
    isMasquerading() {
      return this.$auth.loggedIn && this.$cookies.get('admin_access_token')
    },

    hasHostFooter() {
      return (
        this.$auth?.user.IsOwner && (this.$route.name === 'dashboard___en' || this.$route.name === 'dashboard___fr')
      )
    }
  },

  beforeMount() {
    this.$moment.locale(this.$i18n.locale)
  },

  async mounted() {
    this.braze = await useBraze()
    this.braze?.openSession()
  }
}
