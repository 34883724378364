
import getUserInitials from '@/lib/getUserInitials'

export default {
  computed: {
    initials() {
      if (this.$auth.loggedIn && this.$auth.user) {
        return getUserInitials(this.$auth.user)
      }
      return false
    }
  },

  methods: {
    logout() {
      this.$authUtils.logout()
      this.$experiment.refreshExperiments()
    }
  }
}
