export const state = () => ({
  collections: null,
  favouriteRVs: []
})

export const actions = {
  async GET_COLLECTIONS({ commit }) {
    const collections = await this.$axios.$get(`${this.$config.apiUrl}/api/user-profiles/v2/me/get-list-collection`)
    commit('SET_COLLECTIONS', collections)
  },

  async CREATE_COLLECTION({ commit, dispatch }, { collectionName, rvID = null }) {
    const collection = await this.$axios.$post(`${this.$config.apiUrl}/api/user-profiles/v2/me/create-new-collection`, {
      CollectionName: collectionName
    })

    if (!collection.Items) {
      collection.Items = []
    }

    if (rvID) {
      dispatch('ADD_TO_COLLECTION', { rvID, collectionID: collection.Id })
    }

    commit('ADD_COLLECTION', collection)
  },

  async UPDATE_COLLECTION({ commit }, { collectionID, newCollectionName }) {
    await this.$axios.$put(`${this.$config.apiUrl}/api/user-profiles/v2/me/update-collection`, {
      Id: collectionID,
      CollectionName: newCollectionName
    })
    commit('UPDATE_COLLECTION', { collectionID, newCollectionName })
  },

  async DELETE_COLLECTION({ commit }, collectionID) {
    await this.$axios.$delete(`${this.$config.apiUrl}/api/user-profiles/v2/me/remove-collection?Id=${collectionID}`)
    commit('REMOVE_COLLECTION', collectionID)
  },

  async ADD_TO_COLLECTION({ commit, state }, { rvID, collectionID }) {
    const collectionListID = collectionID || state.collections[0].Id
    const rv = await this.$axios.$post(`${this.$config.apiUrl}/api/user-profiles/v2/me/add-to-favourites-list`, {
      RvId: rvID,
      CollectionId: collectionListID
    })
    commit('ADD_FAVOURITE_RV', { rv, collectionID: collectionListID })
  },

  async REMOVE_FROM_COLLECTION({ commit }, favourite) {
    await this.$axios.$delete(
      `${this.$config.apiUrl}/api/user-profiles/v2/me/remove-from-collection?Id=${favourite.Id}`
    )

    commit('REMOVE_FAVOURITE_RV', favourite)
  }
}

export const getters = {
  isRvFavourited: (state) => (id) => {
    if (state.favouriteRVs.length > 0) {
      return state.favouriteRVs.find((favourite) => favourite && favourite.RVId === id)
    }
    return false
  }
}

export const mutations = {
  SET_COLLECTIONS(state, collections) {
    state.collections = collections

    state.favouriteRVs = [...state.favouriteRVs].concat(...collections.map((collection) => [...collection.Items]))
  },

  CLEAR_COLLECTIONS(state) {
    state.collections = null
    state.favouriteRVs = []
  },

  ADD_COLLECTION(state, collection) {
    state.collections.push(collection)
  },

  UPDATE_COLLECTION(state, { collectionID, newCollectionName }) {
    const collectionIndex = state.collections.findIndex((collection) => {
      return collection.Id === collectionID
    })
    if (collectionIndex >= 0) {
      state.collections[collectionIndex].CollectionName = newCollectionName
    }
  },

  REMOVE_COLLECTION(state, collectionID) {
    const collectionIndex = state.collections.findIndex((collection) => {
      return collection.Id === collectionID
    })
    if (collectionIndex >= 0) {
      state.collections.splice(collectionIndex, 1)
    }
  },

  ADD_FAVOURITE_RV(state, { rv, collectionID }) {
    const collection = state.collections.find((collection) => {
      return collection.Id === collectionID
    })
    if (collection) {
      collection.Items.push(rv)
    }
    state.favouriteRVs.push(rv)
  },

  REMOVE_FAVOURITE_RV(state, favourite) {
    let foundCollectionIndex = 0

    if (state.collections.length > 0) {
      foundCollectionIndex = state.collections.findIndex((collection) => {
        return collection.Id === favourite.FavouriteCollectionId
      })
    }

    const findItemIndex = state.collections[foundCollectionIndex].Items.findIndex((item) => {
      return item.Id === favourite.Id
    })

    if (findItemIndex >= 0) {
      state.collections[foundCollectionIndex].Items.splice(findItemIndex, 1)
    }

    const findFavouriteIndex = state.favouriteRVs.findIndex((item) => {
      return item.Id === favourite.Id
    })

    if (findFavouriteIndex >= 0) {
      state.favouriteRVs.splice(findFavouriteIndex, 1)
    }
  }
}
