import { logDev } from './dev-logging.js'

let googlemaps

export default async function (apiKey = window.$nuxt.$config.googleMapsApiKey, ignoreRouteCheck = false) {
  if (!ignoreRouteCheck && ['callback'].includes(window.$nuxt.getRouteBaseName())) {
    return
  }

  if (!googlemaps) {
    const { Loader } = await import('@googlemaps/js-api-loader')

    const loader = new Loader({
      apiKey,
      libraries: ['places', 'geocoding'],
      defer: true,
      async: true
    })

    const places = loader.importLibrary('places')
    const geocoding = loader.importLibrary('geocoding')
    const maps = loader.importLibrary('maps')
    const marker = loader.importLibrary('marker')

    const { AutocompleteService } = await places
    const { Geocoder } = await geocoding
    const { Map } = await maps
    const { AdvancedMarkerElement } = await marker

    googlemaps = {
      autocomplete: new AutocompleteService(),
      geocoding: new Geocoder(),
      map: Map,
      marker: AdvancedMarkerElement
    }
  }

  const placePredictions = async (input, countries, types = null, exludeTypes = []) => {
    logDev({ domain: 'lib', func: 'useGoogleMaps', message: 'placePredictions' })
    return new Promise((resolve) => {
      googlemaps.autocomplete.getPlacePredictions(
        {
          input,
          componentRestrictions: {
            country: countries
          },
          types
        },
        (predictions, status) => {
          if (status === 'OK') {
            if (exludeTypes?.length) {
              // Exclude irrelevant types
              predictions = predictions.filter((prediction) => {
                return !prediction.types.some((t) => exludeTypes.includes(t))
              })
            }

            resolve(predictions)
          }

          resolve([])
        }
      )
    })
  }

  const geocoder = async ({ placeId, address }) => {
    logDev({ domain: 'lib', func: 'useGoogleMaps', message: 'geocoder' })
    return new Promise((resolve, reject) => {
      try {
        googlemaps.geocoding.geocode(
          {
            address,
            placeId
          },
          (results, status) => {
            if (status === 'OK') {
              resolve(results)
            }
            resolve([])
          }
        )
      } catch (err) {
        reject(err)
      }
    })
  }

  const map = (element, mapOptions = {}) => {
    logDev({ domain: 'lib', func: 'useGoogleMaps', message: 'map' })
    return new googlemaps.map(element, mapOptions)
  }

  const advancedMarker = ({ map, position, content }) => {
    return new googlemaps.marker({
      map,
      position,
      content
    })
  }

  return {
    placePredictions,
    geocoder,
    map,
    advancedMarker
  }
}

export async function getDestinationFromPlaceId(placeId, geocoder) {
  const googleMapsResults = await geocoder({ placeId })
  if (!googleMapsResults.length) throw new Error('No Google Maps results found')

  const result = {}
  result.lat = googleMapsResults[0].geometry.location.lat()
  result.long = googleMapsResults[0].geometry.location.lng()
  result.types = googleMapsResults[0].types
  result.name = googleMapsResults[0].formatted_address

  return result
}
