
export default {
  props: {
    action: {
      type: Boolean,
      default: false
    },

    active: {
      type: Boolean,
      default: false
    },

    button: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    computedAction() {
      if (Boolean(this.$attrs.to) || this.action || Boolean(this.$attrs.href) || this.button) {
        return true
      }
      return false
    },

    tag() {
      if (this.button) {
        return 'button'
      } else if (Boolean(this.$attrs.to) || Boolean(this.$attrs.href)) {
        return 'ZLink'
      }
      return 'div'
    },

    actionClass() {
      return this.computedAction ? 'zlist-group-item-action' : undefined
    },

    activeClass() {
      return this.active ? 'active' : undefined
    }
  }
}
