const DEFAULT_STATE = {
  queue: [],
  index: -1,
  isVisible: false
}

export const state = () => ({
  ...DEFAULT_STATE
})

export const getters = {
  modalShowing: (state) => state.isVisible && state.queue[state.index],
  modalData: (state) => state.queue[state.index]?.data || {},
  onClose: (state) => state.queue[state.index]?.onClose,
  nextModal: (state) => state.queue[state.index + 1],
  previousModal: (state) => state.queue[state.index - 1],
  hasQueue: (state) => state.queue.length > 0
}

const normalizeModal = (modal) => {
  if (typeof modal === 'string') {
    return { name: modal }
  }
  return modal
}

export const actions = {
  async NAVIGATION_PURGE({ commit, dispatch }) {
    await commit('PURGE')
    await dispatch('SHOW_NEXT_MODAL')
  },

  async CLEAR_QUEUE({ commit }) {
    await commit('CLEAR_QUEUE')
  },

  async DEQUEUE_MODAL({ commit, getters }, name) {
    await commit('REMOVE_MODALS_FROM_QUEUE', [name].flat())

    if (!getters.hasQueue) {
      await commit('HIDE_MODAL')
      return
    }
  },

  async QUEUE_MODALS({ commit, state, dispatch }, modals) {
    if (!modals || !modals.length) return

    const _modals = modals.map(normalizeModal)
    await commit('ADD_MODALS_TO_QUEUE', _modals)

    if (!state.isVisible) {
      dispatch('SHOW_NEXT_MODAL')
    }
  },

  async QUEUE_PRIORITY_MODAL({ commit, state, dispatch }, modal) {
    if (!modal) return

    await commit('ADD_PRIORITY_MODAL_TO_QUEUE', [normalizeModal(modal)].flat())
    if (!state.isVisible) {
      dispatch('SHOW_NEXT_MODAL')
    }
  },

  async QUEUE_MODAL({ commit, state, dispatch }, modal) {
    if (!modal) return

    await commit('ADD_MODALS_TO_QUEUE', [normalizeModal(modal)].flat())
    if (!state.isVisible) {
      dispatch('SHOW_NEXT_MODAL')
    }
  },

  async SHOW_NEXT_MODAL({ commit, getters }) {
    if (!getters.nextModal) {
      await commit('HIDE_MODAL')
      await commit('CLEAR_QUEUE')
      return
    }
    await commit('MOVE_FORWARD')
    await commit('SHOW_MODAL')
  },

  async SHOW_PREVIOUS_MODAL({ commit, getters }) {
    if (!getters.previousModal) {
      await commit('HIDE_MODAL')
      await commit('CLEAR_QUEUE')
      return
    }
    await commit('MOVE_BACKWARD')
    await commit('SHOW_MODAL')
  },

  async HIDE_MODAL({ commit }) {
    await commit('HIDE_MODAL')
  },

  async SHOW_MODAL({ commit }) {
    await commit('SHOW_MODAL')
  }
}

export const mutations = {
  MOVE_FORWARD(state) {
    state.index++
  },

  MOVE_BACKWARD(state) {
    state.index--
  },

  ADD_PRIORITY_MODAL_TO_QUEUE(state, modal) {
    if (!modal || !modal.length) return

    state.queue = [...modal, ...state.queue]
  },

  ADD_MODALS_TO_QUEUE(state, modal) {
    if (!modal || !modal.length) return

    state.queue = [...state.queue, ...modal]
  },

  REMOVE_MODALS_FROM_QUEUE(state, modal) {
    if (!modal || !modal.length) return

    state.queue = state.queue.filter((name) => !modal.includes(name))
  },

  SHOW_MODAL(state) {
    if (state.isVisible) return
    state.isVisible = true
  },

  HIDE_MODAL(state) {
    if (!state.isVisible) return
    state.isVisible = false
  },

  PURGE(state) {
    state.queue = state.queue.filter((modal) => modal.persist)
    state.index = DEFAULT_STATE.index
    state.isVisible = DEFAULT_STATE.isVisible
  },

  CLEAR_QUEUE(state) {
    state.queue = DEFAULT_STATE.queue
    state.index = DEFAULT_STATE.index
  }
}
