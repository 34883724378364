
export default {
  props: {
    noBody: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: undefined
    },

    noBorder: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasHeader() {
      return Boolean(this.$slots.header)
    }
  },

  methods: {
    click() {
      this.$emit('click')
    }
  }
}
