export const state = () => ({
  rating: '4.6',
  reviewCount: 2008
})

export const mutations = {
  SET_AGGREGATE_REVIEW(state, aggregateReview) {
    state.reviewCount = aggregateReview.reviewCount
    state.rating = aggregateReview.rating
  }
}

export const actions = {
  async SET_AGGREGATE_REVIEW({ commit }, { $axios, $captureError, $redis }) {
    try {
      const currentDay = new Date().getDay()
      const cached = await $redis.get({ key: 'VerifiedReview' })
      if (cached?.updatedDay === currentDay) {
        await commit('SET_AGGREGATE_REVIEW', cached)
      } else {
        const res = await $axios.get(
          'https://cl.avis-verifies.com/us/cache/2/4/4/24432d77-8ee9-b6d4-5d00-e540258f2595/AWS/24432d77-8ee9-b6d4-5d00-e540258f2595_infosite.txt'
        )
        const values = res.data.split(';')
        if (Boolean(values) && values.length === 2 && values[0] > 100 && values[1] <= 5) {
          const aggregateReview = {
            reviewCount: values[0],
            rating: values[1],
            updatedDay: currentDay
          }

          await commit('SET_AGGREGATE_REVIEW', aggregateReview)
          await $redis.set({ key: 'VerifiedReview', data: aggregateReview })
        }
      }
    } catch (err) {
      $captureError(new Error(`Error retrieving VerifiedReview. Cause: ${err}`))
    }
  }
}

export const getters = {
  aggregateReview(state) {
    return {
      rating: state.rating,
      reviewCount: state.reviewCount
    }
  }
}
