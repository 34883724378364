export default function ({ $cookies, store, experiment }, isDeepLinkRequest = false, addRtsessionid = true) {
  const utms = $cookies.get('utm_parameters') ?? {}
  const rtsessionid = store.state.me.rtSessionId

  if (experiment.getVariant('rvz16132IncentivizeDownloadingTheApp') === 'variant') {
    utms['incentive'] = 'true'
  }

  if (isDeepLinkRequest && utms) {
    if (addRtsessionid) utms['rtsessionid'] = rtsessionid
    for (const [key, value] of Object.entries(utms)) {
      if (!value?.length) {
        delete utms[key]
      }
    }
  }

  return { utms, rtsessionid }
}
