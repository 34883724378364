
export default {
  props: {
    fluid: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    containerClass() {
      return this.fluid ? 'zcontainer-fluid' : 'zcontainer'
    }
  }
}
