const defaultState = {
  data: null,
  hasRequested: false,
  isLoading: false,
  errored: false
}

export const state = () => ({
  draft: {
    ...defaultState
  },
  post: {
    ...defaultState
  }
})

export const actions = {
  RESET_DRAFT({ commit }) {
    commit('DRAFT_RESET')
  },
  async GET_CHANGE_QUOTE({ commit }, items) {
    const { bookingId, roadsideAssistance, protectionPlan } = items
    const payload = { roadSideInsurance: roadsideAssistance }
    const PROTECTION_PLANS = {
      standard: 'Standard',
      premium: 'Premium',
      basic: 'Basic'
    }
    commit('DRAFT_HAS_REQUESTED')
    try {
      const data = await this.$axios.$put(
        `${this.$config.apiUrl}/api/v2/bookings/${bookingId}/change-request/draft`,
        protectionPlan
          ? {
              ...payload,
              insuranceProtectionLevel: PROTECTION_PLANS[protectionPlan]
            }
          : payload
      )
      commit('DRAFT_SET_RESPONSE', data)
      return data.Financial
    } catch (err) {
      return commit('DRAFT_SET_ERROR', err)
    }
  },
  RESET_POST({ commit }) {
    commit('POST_RESET')
  },
  async POST_UPDATE({ commit }, items) {
    const { bookingId, roadsideAssistance, protectionPlan, expectedTotal, notifyOwner } = items
    const payload = {
      expectedTotal,
      notifyOwner,
      includeRoadside: roadsideAssistance
    }
    commit('POST_HAS_REQUESTED')
    try {
      const data = await this.$axios.$post(
        `${this.$config.apiUrl}/api/v2/bookings/${bookingId}/change-request/protection`,
        protectionPlan
          ? {
              ...payload,
              insurance: protectionPlan
            }
          : payload
      )
      return commit('POST_SET_RESPONSE', data)
    } catch (err) {
      return commit('POST_SET_ERROR', err)
    }
  }
}

export const mutations = {
  DRAFT_HAS_REQUESTED(state) {
    state.draft = {
      ...state.draft,
      hasRequested: true
    }
  },

  DRAFT_IS_LOADING(state) {
    state.draft = {
      ...state.draft,
      hasRequested: true,
      isLoading: true
    }
  },

  DRAFT_SET_RESPONSE(state, data) {
    state.draft = {
      ...state.draft,
      data,
      hasRequested: true,
      isLoading: false,
      errored: false
    }
  },

  DRAFT_SET_ERROR(state, error) {
    state.draft = {
      ...state.draft,
      hasRequested: true,
      isLoading: false,
      data: error,
      errored: true
    }
  },

  DRAFT_RESET(state) {
    state.draft = { ...defaultState }
  },

  POST_HAS_REQUESTED(state) {
    state.post = {
      ...state.post,
      hasRequested: true
    }
  },

  POST_IS_LOADING(state) {
    state.post = {
      ...state.post,
      hasRequested: true,
      isLoading: true
    }
  },

  POST_SET_RESPONSE(state, data) {
    state.post = {
      ...state.post,
      data: data || true,
      hasRequested: true,
      isLoading: false,
      errored: false
    }
  },

  POST_SET_ERROR(state, error) {
    state.post = {
      ...state.post,
      hasRequested: true,
      isLoading: false,
      data: error,
      errored: true
    }
  },

  POST_RESET(state) {
    state.post = { ...defaultState }
  }
}
