
import { isWebview } from '~/lib/useUtils'

export default {
  computed: {
    isWebview() {
      return isWebview(this.$cookies.get('platform'))
    },

    breadcrumbItems() {
      return [
        {
          text: this.$t('pageLink.listAnRV')
        },
        {
          text: this.$route.params.field ? this.$t(`breadcrumb.${this.$route.params.field}`) : ''
        }
      ]
    },

    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    }
  },

  methods: {
    saveAndExit() {
      this.$nuxt.$emit('new-rv-save-and-exit')
    },

    previousStep() {
      this.$nuxt.$emit('new-rv-previous-step')
    },

    goTo(code) {
      this.$router.replace(
        this.localePath(
          {
            name: this.$route.name.substr(0, -5),
            params: this.$route.$params,
            query: { locale: code, ...this.$route.query }
          },
          code
        )
      )
    }
  }
}
