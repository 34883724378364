import { searchSortTypes } from '~/lib/search/sortTypes.js'
import { countryMap } from '@/lib/countryMap.js'

const iso8601Date = (date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`
}

const parseDate = (date) => {
  const b = date.split(/\D/).map((p) => Number(p))
  if (b.length < 3) throw new Error('Invalid Date')

  return iso8601Date(new Date(b[0], b[1] - 1, b[2]))
}

const tryParseDate = (date) => {
  try {
    return parseDate(date ?? '')
  } catch {
    return
  }
}

export const extractSearchQuery = (query = {}) => {
  // Standardize every keys to lowercase
  const all = Object.keys(query).reduce((acc, k) => {
    acc[k.toLowerCase()] = query[k]?.toString()
    return acc
  }, {})

  return {
    Adults: all.adults ? Number(all.adults) : undefined,
    Children: all.children ? Number(all.children) : undefined,

    SearchAddress: all.searchaddress,

    SortOrder: all.sortorder ? searchSortTypes[all.sortorder] : undefined,

    StartDate: tryParseDate(all.startdate),
    EndDate: tryParseDate(all.enddate),

    Types: all.types?.split(','),
    Amenities: all.amenities?.split(','),
    RvBrand: all.rvbrand,

    Delivery:
      (Object.prototype.hasOwnProperty.call(all, 'delivery') && all.delivery === undefined) || Boolean(all.delivery),
    ExperienceNotRequired:
      (Object.prototype.hasOwnProperty.call(all, 'experiencenotrequired') && all.experiencenotrequired === undefined) ||
      Boolean(all.experiencenotrequired),
    IsSuperHost:
      (Object.prototype.hasOwnProperty.call(all, 'issuperhost') && all.issuperhost === undefined) ||
      Boolean(all.issuperhost),
    FestivalFriendly:
      (Object.prototype.hasOwnProperty.call(all, 'festivalfriendly') && all.festivalfriendly === undefined) ||
      Boolean(all.festivalfriendly),
    InstantBook:
      (Object.prototype.hasOwnProperty.call(all, 'instantbook') && all.instantbook === undefined) ||
      Boolean(all.instantbook),
    PetFriendly:
      (Object.prototype.hasOwnProperty.call(all, 'petfriendly') && all.petfriendly === undefined) ||
      Boolean(all.petfriendly),

    FlexibleDays: all.flexibledays ? Number(all.flexibledays) : undefined,

    MinLength: all.minlength ? Number(all.minlength) : undefined,
    MaxLength: all.maxlength ? Number(all.maxlength) : undefined,

    MinPrice: all.minprice ? Number(all.minprice) : undefined,
    MaxPrice: all.maxprice ? Number(all.maxprice) : undefined,

    MinRvYear: all.minrvyear ? Number(all.minrvyear) : undefined,
    MaxRvYear: all.maxrvyear ? Number(all.maxrvyear) : undefined,

    MinWeight: all.minweight ? Number(all.minweight) : undefined,
    MaxWeight: all.maxweight ? Number(all.maxweight) : undefined,

    NeLat: all.nelat ? Number(all.nelat) : undefined,
    NeLng: all.nelng ? Number(all.nelng) : undefined,
    SwLat: all.swlat ? Number(all.swlat) : undefined,
    SwLng: all.swlng ? Number(all.swlng) : undefined
  }
}

export const boundsToLocation = ({ fullName, bounds }) => {
  if (isNaN(bounds?.east) || isNaN(bounds?.west) || isNaN(bounds?.north) || isNaN(bounds?.south)) {
    return
  }
  return {
    bounds,
    mapArea: true,
    searchable: false,
    placeId: undefined,
    fullName,
    mainText: fullName,
    center: undefined,
    secondaryText: undefined,
    city: undefined,
    region: undefined,
    country: undefined,
    types: []
  }
}

export const queryStringToBounds = (query) => {
  if (query.NeLat == null || query.NeLng == null || query.SwLat == null || query.SwLng == null) {
    return {
      hasBounds: false,
      east: undefined,
      west: undefined,
      north: undefined,
      south: undefined
    }
  }

  return {
    hasBounds: true,
    east: query.NeLng,
    west: query.SwLng,
    north: query.NeLat,
    south: query.SwLat
  }
}

export const geolocationToLocation = (geolocation) => {
  if (geolocation?.latitude == null || geolocation?.longitude == null) return
  // only do a partial implementation
  const location = {
    searchable: false,
    placeId: undefined,
    center: {
      lat: geolocation.latitude,
      lng: geolocation.longitude
    },
    bounds: undefined,
    city: {
      long_name: geolocation.city ?? '',
      short_name: geolocation.city ?? '',
      types: ['political', 'locality']
    },
    region: {
      long_name: geolocation.regionCode ?? '',
      short_name: geolocation.regionCode ?? '',
      types: ['political', 'administrative_area_level_1']
    },
    country: {
      long_name: countryMap[geolocation.countryCode] ?? '',
      short_name: geolocation.countryCode ?? '',
      types: ['political', 'country']
    },
    fullName: [geolocation?.city, geolocation?.regionCode, geolocation?.countryCode].filter((x) => x).join(', '),
    mainText: geolocation?.city ?? '',
    secondaryText: '',
    types: ['political', 'locality']
  }

  return location
}

export const queryStringToGuests = (query) => {
  return {
    adults: query.Adults,
    children: query.Children
  }
}

export const searchFilters = {
  drivable: undefined,
  towable: undefined,
  rvCottage: false,
  delivery: false,
  rvPrice: {
    min: undefined,
    max: undefined
  },
  petFriendly: false,
  instantBook: false,
  amenities: undefined,
  rvWeight: {
    min: undefined,
    max: undefined
  },
  rvLength: {
    min: undefined,
    max: undefined
  },
  rvYear: {
    min: undefined,
    max: undefined
  },
  rvBrand: '',
  festivalFriendly: false,
  experienceNotRequired: false,
  superhost: false
}

export const queryStringToSearchFilters = (query) => {
  const amenitiesArr = query.Amenities ? String(query.Amenities).split(',') : []
  return {
    drivable: query.Types
      ? String(query.Types)
          .split(',')
          .reduce((acc, v) => {
            if (['MotorHome', 'ClassA', 'ClassB', 'ClassC', 'MotoVintage', 'TruckCamper', 'Campervan'].includes(v))
              acc[v] = true
            return acc
          }, {})
      : undefined,
    towable: query.Types
      ? String(query.Types)
          .split(',')
          .reduce((acc, v) => {
            if (
              [
                'TravelTrailer',
                'FifthWheel',
                'TentTrailer',
                'SubTravelTrailer',
                'Vintage',
                'Hybrid',
                'ToyHauler',
                'MicroTrailer'
              ].includes(v)
            )
              acc[v] = true
            return acc
          }, {})
      : undefined,
    rvCottage: query.Types ? query.Types.includes('RVCottage') : false,
    delivery: query.Delivery ?? false,
    rvPrice: {
      min: query.MinPrice !== undefined ? Number(query.MinPrice) : undefined,
      max: query.MaxPrice !== undefined ? Number(query.MaxPrice) : undefined
    },
    petFriendly: query.PetFriendly ?? false,
    instantBook: query.InstantBook ?? false,
    amenities: [
      'AirConditioner',
      'AirportPickup',
      'DiningTable',
      'Family_kidfriendly',
      'Heater',
      'InsideShower',
      'KitchenSink',
      'Microwave',
      'OutsideShower',
      'Refrigerator',
      'Stove_Range',
      'Toilet'
    ].reduce((acc, v) => {
      acc[v] = amenitiesArr.includes(v)
      return acc
    }, {}),
    rvWeight: {
      min: query.MinWeight !== undefined ? Number(query.MinWeight) : undefined,
      max: query.MaxWeight !== undefined ? Number(query.MaxWeight) : undefined
    },
    rvLength: {
      min: query.MinLength !== undefined ? Number(query.MinLength) : undefined,
      max: query.MaxLength !== undefined ? Number(query.MaxLength) : undefined
    },
    rvYear: {
      min: query.MinRvYear !== undefined ? Number(query.MinRvYear) : undefined,
      max: query.MaxRvYear !== undefined ? Number(query.MaxRvYear) : undefined
    },
    rvBrand: query.RvBrand,
    festivalFriendly: query.FestivalFriendly ?? false,
    experienceNotRequired: query.ExperienceNotRequired ?? false,
    superhost: query.IsSuperHost ?? false
  }
}

export const queryStringToDates = (query) => {
  if (!query.StartDate || !query.EndDate) {
    return {
      dates: {
        start: null,
        end: null
      }
    }
  }

  return {
    dates: {
      start: query.StartDate,
      end: query.EndDate
    }
  }
}

export const queryStringToSortOrder = (query) => {
  return {
    value: query.SortOrder ?? searchSortTypes.Recommended
  }
}

export const searchParametersToSearchQuery = (params) => {
  return {
    Adults: params.guests.adults,
    Children: params.guests.children,

    StartDate: params.dates.dates.start,
    EndDate: params.dates.dates.end,

    Amenities: Object.keys(params.filters.amenities ?? [])
      .filter((x) => params.filters.amenities?.[x])
      .sort(),

    Types: Object.keys(params.filters.drivable || [])
      .filter((x) => params.filters.drivable?.[x])
      .concat(Object.keys(params.filters.towable || []).filter((x) => params.filters.towable?.[x]))
      .concat(params.filters.rvCottage ? ['RVCottage'] : [])
      .sort(),

    Delivery: params.filters.delivery || undefined,
    ExperienceNotRequired: params.filters.experienceNotRequired || undefined,
    FestivalFriendly: params.filters.festivalFriendly || undefined,
    InstantBook: params.filters.instantBook || undefined,
    IsSuperHost: params.filters.superhost || undefined,

    PetFriendly: params.filters.petFriendly || undefined,

    RvBrand: params.filters.rvBrand || undefined,

    MinLength: params.filters.rvLength?.min,
    MaxLength: params.filters.rvLength?.max,

    MinPrice: params.filters.rvPrice?.min,
    MaxPrice: params.filters.rvPrice?.max,

    MinRvYear: params.filters.rvYear?.min,
    MaxRvYear: params.filters.rvYear?.max,

    MinWeight: params.filters.rvWeight?.min,
    MaxWeight: params.filters.rvWeight?.max,

    SearchAddress: params.location?.searchable ? params.location?.fullName : undefined,
    NeLat: params.bounds?.north,
    NeLng: params.bounds?.east,
    SwLat: params.bounds?.south,
    SwLng: params.bounds?.west,

    SortOrder: params.sort.value !== searchSortTypes.Recommended ? params.sort.value : undefined
  }
}

export const searchParametersToApiRequest = (params) => {
  const request = {}

  /** filters */
  request.Instabook = params.filters.instantBook === true || undefined
  request.Delivery = params.filters.delivery === true || undefined
  request.FestivalFriendly = params.filters.festivalFriendly === true || undefined
  request.ExperienceNotRequired = params.filters.experienceNotRequired === true || undefined
  request.IsSuperHost = params.filters.superhost === true || undefined
  request.MinPrice = params.filters.rvPrice?.min
  request.MaxPrice = params.filters.rvPrice?.max
  request.MinWeight = params.filters.rvWeight?.min
  request.MaxWeight = params.filters.rvWeight?.max
  request.MinYear = params.filters.rvYear?.min
  request.MaxYear = params.filters.rvYear?.max
  request.RVBrand = params.filters.rvBrand || undefined
  request.MinLength = params.filters.rvLength?.min
  request.MaxLength = params.filters.rvLength?.max
  request.Amenities = []
  request.RVCottage = params.filters.rvCottage || undefined

  for (const key in params.filters.amenities) {
    if (key === 'AirportPickup' && params.filters.amenities.AirportPickup) {
      request.AirportPickup = true
    } else if (params.filters.amenities[key] === true) {
      request.Amenities.push(key)
    }
  }

  if (params.filters.petFriendly && !request.Amenities.includes('Petfriendly')) request.Amenities.push('Petfriendly')

  request.Amenities = request.Amenities.length ? request.Amenities.join(',') : ''

  for (const key in params.filters.drivable) {
    request[key] = params.filters.drivable[key] === true || undefined
  }

  for (const key in params.filters.towable) {
    request[key] = params.filters.towable[key] === true || undefined
  }

  /** guests */
  if (params.guests) {
    request.Guest = (params.guests.adults ?? 0) + (params.guests.children ?? 0)
  }

  /** dates */
  if (params.dates.dates.start && params.dates.dates.end) {
    request.StartDate = params.dates.dates.start
    request.EndDate = params.dates.dates.end
  }

  /** location */
  if (params.bounds?.hasBounds) {
    request.NeLat = params.bounds.north
    request.NeLng = params.bounds.east
    request.SwLat = params.bounds.south
    request.SwLng = params.bounds.west
  } else if (!isNaN(params.location?.center?.lat) && !isNaN(params.location?.center?.lng)) {
    request.Country = params.location.country?.short_name
    if (params.location.types.includes('political')) {
      if (params.location.city) {
        request.SearchLat = params.location.center?.lat
        request.SearchLng = params.location.center?.lng
      } else if (
        !params.location.types.includes('administrative_area_level_1') &&
        params.location.types.find((x) => /^administrative_area_level_/.test(x))
      ) {
        request.SearchLat = params.location.center?.lat
        request.SearchLng = params.location.center?.lng
      } else if (params.location.region) {
        request.Region = params.location.region.long_name
      } else {
        request.SearchLat = params.location.center?.lat
        request.SearchLng = params.location.center?.lng
      }
    } else if (params.location.center) {
      request.SearchLat = params.location.center.lat
      request.SearchLng = params.location.center.lng
    }
  }

  /** sort */
  request.SortOrder = params.sort.value

  return request
}

export const searchCriteriaToSearchParameters = (searchCriteria, params) => {
  params.filters = {
    drivable: {
      MotorHome: searchCriteria.MotorHome,
      ClassA: searchCriteria.ClassA,
      ClassB: searchCriteria.ClassB,
      ClassC: searchCriteria.ClassC,
      MotoVintage: searchCriteria.MotoVintage,
      TruckCamper: searchCriteria.TruckCamper,
      Campervan: searchCriteria.Campervan
    },
    towable: {
      TravelTrailer: searchCriteria.TravelTrailer,
      FifthWheel: searchCriteria.FifthWheel,
      TentTrailer: searchCriteria.TentTrailer,
      SubTravelTrailer: searchCriteria.SubTravelTrailer,
      Vintage: searchCriteria.Vintage,
      Hybrid: searchCriteria.Hybrid,
      ToyHauler: searchCriteria.ToyHauler,
      MicroTrailer: searchCriteria.MicroTrailer
    },
    rvCottage: searchCriteria.RVCottage,
    delivery: searchCriteria.Delivery,
    rvPrice: {
      min: searchCriteria.MinPrice,
      max: searchCriteria.MaxPrice
    },
    petFriendly: searchCriteria.Amenities?.includes('Petfriendly'),
    instantBook: searchCriteria.Instabook,
    amenities:
      searchCriteria.Amenities?.reduce((acc, v) => {
        if (
          [
            'AirConditioner',
            'AirportPickup',
            'DiningTable',
            'Family_kidfriendly',
            'Heater',
            'InsideShower',
            'KitchenSink',
            'Microwave',
            'OutsideShower',
            'Petfriendly',
            'Refrigerator',
            'Stove_Range',
            'Toilet'
          ].includes(v)
        )
          acc[v] = true
        return acc
      }, {}) ?? undefined,
    rvWeight: {
      min: searchCriteria.MinWeight,
      max: searchCriteria.MaxWeight
    },
    rvLength: {
      min: searchCriteria.MinLength,
      max: searchCriteria.MaxLength
    },
    rvYear: {
      min: searchCriteria.MinYear,
      max: searchCriteria.MaxYear
    },
    rvBrand: searchCriteria.RVBrand ?? '',
    festivalFriendly: searchCriteria.FestivalFriendly,
    experienceNotRequired: searchCriteria.ExperienceNotRequired,
    superhost: searchCriteria.IsSuperHost
  }
}
