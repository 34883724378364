
export default {
  props: {
    title: {
      type: String,
      default: undefined
    },

    noPadding: {
      type: Boolean,
      default: false
    }
  }
}
