import dayjs from '~/lib/useDates'

import getDistanceUnitByCountry from '@/lib/getDistanceUnitByCountry'
import getRvCategoryByType from '@/lib/getRvCategoryByType'
import {
  experienceDriverPreference,
  festivalsEventsPreference,
  festivalsEventsPreferenceDestination,
  hasExperiencedDriver,
  isGoingToFestivalOrEvent,
  stationaryAfterDelivery,
  bookingHasDelivery,
  getDistancePriceKms
} from '~/lib/bookings'
import { BookingStageEnum, CountryCodeToCurrencyCode } from '~/lib/enums'
import { getOriginalNightlyRate, getSmartNightlyRate } from '~/lib/rvs'
import getFreeDistanceKms from '@/lib/getFreeDistanceKms'

export class BookingCommonEventFields {
  commonFields = {}

  constructor(booking) {
    const rvSnapshot = booking.RVSnapshot
    const financial = booking.Financial

    const hasDelivery = bookingHasDelivery(booking)

    const hasTowingExperience = experienceDriverPreference(booking) ? hasExperiencedDriver(booking) : undefined
    const isAttendingFestival = festivalsEventsPreference(booking) ? isGoingToFestivalOrEvent(booking) : undefined

    this.commonFields = {
      bookingId: booking.Id,
      bookingState: BookingStageEnum[booking.Stage],
      bookingType: booking.Type.toLowerCase(),
      rvId: booking.RVId,

      startDate: dayjs.utc(booking.DateStart).format('YYYY-MM-DD'),
      endDate: dayjs.utc(booking.DateEnd).format('YYYY-MM-DD'),
      adults: booking.Adults,
      children: booking.Children,

      currency: CountryCodeToCurrencyCode(booking.Country),
      roadsidePrice: financial.TotalRoadsides,
      insurancePrice: financial.TotalInsurances,
      cleaningPrice: financial.TotalCleanings,
      securityDepositPrice: financial.TotalSecurityDeposits,
      generatorPriceHourly: rvSnapshot.IsChargeGenerator ? rvSnapshot.ChargePerHourOver ?? null : null,
      distancePriceKms: getDistancePriceKms(booking),
      freeDistanceKms: getFreeDistanceKms(rvSnapshot, financial),
      freeGeneratorHours: rvSnapshot.IsChargeGenerator ? rvSnapshot.FreeHoursPerDay ?? null : null,
      rentalTotal: financial.RenterDiscountedTotal,
      pricePaid: financial.TotalRentalPaid,
      totalPayout: financial.TotalPayoutAmounts,
      hasPaymentInfo: !booking.NoPaymentMethod,

      isEzymatch: booking.IsEZYmatch,
      hasPets: booking.Pets > 0,
      hasDelivery,
      deliveryDistance: hasDelivery && booking.DeliveryDistance ? booking.DeliveryDistance : undefined,
      deliveryPrice: hasDelivery ? financial.TotalDelivery : undefined,

      isStationaryDelivery: booking.RvUsage ? stationaryAfterDelivery(booking) : undefined,
      tripLength: booking.NumberOfNight,

      listingType: rvSnapshot.RentalType,
      rvType: rvSnapshot.RVType,
      listingCountry: rvSnapshot.Country.toLowerCase(),
      listingRegion: rvSnapshot.RVState,
      listingCity: rvSnapshot.City,

      hasTowingExperience,
      isAttendingFestival,
      festivalName: isAttendingFestival ? festivalsEventsPreferenceDestination(booking) : undefined,

      tripLeadTime: dayjs(booking.StartDate).diff(dayjs(), 'days')
    }
  }

  get commons() {
    return this.commonFields
  }
}

export const buildListingSetItem = ({ rv }) => {
  const listTemplate = {
    rvId: null,
    rvName: null,
    rentalType: null,
    listingType: null,
    isInstantBook: null,
    isFeatured: null,
    isFavourite: null,
    nightlyRate: null,
    smartNightlyRate: null,
    currency: null,
    distanceKm: null,
    listingCity: null,
    listingRegion: null,
    listingSleepingSpots: null,
    starRating: null,
    numReviews: null,
    reviewsShown: false,
    listPosition: null,
    heroImage: null,
    distanceUnit: null,
    imageAttributes: [],
    listingCountry: null,
    hasDelivery: null
  }

  const item = { ...listTemplate }

  item.rvId = rv.Id
  item.rvName = rv.RVName
  item.rentalType = getRvCategoryByType(rv.RVType) || 'Unknown'
  item.listingType = rv.RVType
  item.isInstantBook = rv.InstabookOwnerOptedIn
  item.isFeatured = rv.IsFeatured
  item.hasDelivery = rv.HasDelivery
  item.isFavourite = rv.isFavourite

  item.smartNightlyRate = getSmartNightlyRate(rv) ?? null
  item.nightlyRate = getOriginalNightlyRate(rv)

  item.currency = CountryCodeToCurrencyCode(rv.Country) || 'unknown'
  item.distanceKm = rv.Distance
  item.listingCity = rv.City
  item.listingRegion = rv.State
  item.listingCountry = rv.Country
  item.listingSleepingSpots = rv.Guests
  item.starRating = rv.AverageRating
  item.numReviews = rv.NumberOfReview
  item.reviewsShown = rv.NumberOfReview > 0
  item.heroImage = rv.heroImage
  item.distanceUnit = getDistanceUnitByCountry(rv.Country) || 'unknown'

  return item
}

export const buildListingSetListings = ({ searchResults }) => {
  return searchResults.map((rv, index) => {
    return {
      ...buildListingSetItem({ rv }),
      listPosition: index + 1
    }
  })
}
