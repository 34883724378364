
import routeMixin from '@/mixins/route'

export default {
  name: 'Localization',

  mixins: [routeMixin],

  computed: {
    currentLocale() {
      return this.$i18n.locales.filter((i) => i.code === this.$i18n.locale)[0]
    },

    alternateLocale() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)[0]
    },

    showCurrencySelector() {
      return !this.$auth.loggedIn
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.$moment.locale(this.$i18n.locale)
    }
  },

  methods: {
    toggleLocale() {
      this.$router.push(this.getCurrentRouteToLocaleCode(this.alternateLocale.code))
    }
  }
}
