
import * as icons from '~/lib/fontawesome.js'

function toPascalCase(text) {
  return text.replace(/(^\w|-\w)/g, clearAndUpper)
}

function clearAndUpper(text) {
  return text.replace(/-/, '').toUpperCase()
}

export default {
  props: {
    icon: {
      type: [String, Array],
      required: true
    }
  },

  computed: {
    width() {
      return this.getIcon().icon[0] || 0
    },

    height() {
      return this.getIcon().icon[1] || 0
    },

    name() {
      return this.getIcon().iconName || ''
    },

    path() {
      return this.getIcon().icon[4]
    },

    collection() {
      return this.getIcon().prefix
    }
  },

  methods: {
    getIcon() {
      let icon = this.icon
      let collection = 'fas' // default collection

      if (Array.isArray(this.icon)) {
        collection = this.icon[0]
        icon = this.icon[1]
      }

      return icons[`${collection}${toPascalCase(icon)}`]
    }
  }
}
