
import { trackTipsAndResourcesCardClicked } from '~/lib/tracking'
import { ObserveVisibility } from 'vue-observe-visibility'

export default {
  name: 'FeaturedHostBlog',

  directives: {
    ObserveVisibility
  },

  data() {
    return {
      blogs: [],
      glide: null
    }
  },

  created() {
    this.loadContent()
  },

  beforeDestroy() {
    this.glide?.destroy()
  },

  methods: {
    loadContent() {
      const tag = this.$i18n.locale === 'fr' ? 'hash-host-tips-featured-fr' : 'hash-host-tips-featured'
      this.$external
        .$get(`${this.$config.blog.ghostApiUrl}/posts?&filter=tag:${tag}`, {
          params: {
            key: this.$config.blog.ghostApiKey
          }
        })
        .then((res) => {
          this.blogs = res.posts.slice(0, 4).map((post) => {
            const url = `${this.localePath({
              name: 'blog'
            })}/${post.slug}`
            return { title: post.title, image: post.feature_image, url }
          })
        })
        .catch((err) => {
          this.$captureError(err)
        })
    },

    trackEvent(blog) {
      trackTipsAndResourcesCardClicked({
        accountCountry: this.$auth.user?.CountryString?.toLowerCase(),
        userId: this.$auth.user?.Id,
        tipsAndResourcesCardName: blog.title,
        pageSource: this.getRouteBaseName()
      })
    },

    async mountOnVisible() {
      const { default: Glide, Swipe, Controls } = await import('@glidejs/glide/dist/glide.modular.esm')

      if (this.glide) return
      this.glide = new Glide(this.$refs.glideEl.$el, {
        type: 'carousel',
        perView: 1.3
      })

      this.glide.mount({ Swipe, Controls })
    }
  }
}
