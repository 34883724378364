
import { trackHelpCenterClicked } from '~/lib/tracking'
import ownerCommon from '~/mixins/owner-common.js'
import getUserInitials from '@/lib/getUserInitials'
import appDownload from '@/mixins/app-download'

export default {
  mixins: [ownerCommon, appDownload],

  props: {
    inboxBadge: {
      type: Number,
      default: 0
    },

    dashboardBadge: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      subNavCollapse: false
    }
  },

  computed: {
    initials() {
      if (this.$auth.loggedIn && this.$auth.user) {
        return getUserInitials(this.$auth.user)
      }
      return false
    }
  },

  methods: {
    trackHelpCenterClicked() {
      trackHelpCenterClicked({
        cta: 'header',
        pageSource: this.getRouteBaseName()
      })
    },

    logout() {
      this.$authUtils.logout()
      this.$experiment.refreshExperiments()
    },

    closeNavAndGoTo(path) {
      this.hideCollapse(() => this.$router.push(path))
    },

    hideCollapse(callback) {
      this.$emit('closeNav')

      if (callback && callback instanceof Function) {
        callback()
      }
    }
  }
}
