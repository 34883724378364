
import layoutMixin from '~/mixins/layout'
import { injectPartytownScripts } from '~/lib/partytown'
import { isLastStep } from '~/lib/rvs'
import showInsurance from '@/lib/showInsurance'

export default {
  name: 'NewListing',

  mixins: [layoutMixin],

  transition: 'new-rv-page',

  data() {
    return {
      disableButton: true
    }
  },

  head() {
    return {
      ...injectPartytownScripts({
        $config: this.$config,
        $experiment: this.$experiment,
        $cookies: this.$cookies,
        $user: this.$auth.user
      })
    }
  },

  computed: {
    isLastStep() {
      const rentalType = this.$store.state.rv.rv.RentalType
      const rvType = this.$store.state.rv.rv.RVType
      const hasInsurance = showInsurance(this.$store.state.rv.rv)

      const step = this.$route.params.field
      return isLastStep({ rentalType, rvType, hasInsurance }, step)
    }
  },

  created() {
    this.$nuxt.$on('disable-next-button', (value) => {
      this.disableButton = value
    })
  }
}
