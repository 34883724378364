
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    hideHeader: {
      type: Boolean,
      default: false
    },

    hideFooter: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    position: {
      type: String,
      default: 'bottom',
      validator: function (value) {
        return ['bottom', 'top'].includes(value)
      }
    },

    preventControlScroll: {
      type: Boolean,
      default: false
    },

    noCardBody: {
      type: Boolean,
      default: false
    },

    underAppHeader: {
      type: Boolean,
      default: false
    },

    noAnimation: {
      type: Boolean,
      default: false
    },

    modalClass: {
      type: String,
      default: ''
    },

    variant: {
      type: String,
      default: 'primary'
    },

    fullHeight: {
      type: Boolean,
      default: false
    },

    headerBottomBorder: {
      type: Boolean,
      default: false
    },

    noCloseOnBackdrop: {
      type: Boolean,
      default: false
    },

    hideHeaderClose: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      shown: this.noAnimation ? this.visible : false
    }
  },

  computed: {
    hasFooterSlot() {
      return Boolean(this.$slots['footer'])
    },

    withHeaderHeight() {
      if (this.$isServer) {
        return
      }

      const appHeader = document.querySelector('.app-header')
      const headerHeight = appHeader ? appHeader.offsetHeight : 0

      const appDownloadBanner = document.querySelector('.app-download-banner')
      const appDownloadBannerHeight = appDownloadBanner ? appDownloadBanner.offsetHeight : 0

      const style = `max-height: calc(100dvh - ${headerHeight + appDownloadBannerHeight}px - 0.25rem);`
      if (this.shown && this.position === 'top' && this.underAppHeader) {
        return `${style} top: ${headerHeight + appDownloadBannerHeight}px;`
      }
      return style
    }
  },

  watch: {
    visible: {
      handler: 'controlScroll',
      immediate: true
    }
  },

  mounted() {
    if (this.noAnimation) {
      this.controlScroll(this.visible)
    }
  },

  beforeDestroy() {
    this.controlScroll(false)
  },

  methods: {
    overlayClick(e) {
      if (!this.noCloseOnBackdrop && e?.target?.parentNode?.classList.contains('zoverlay')) {
        this.close()
      }
    },

    close() {
      this.shown = false
      setTimeout(() => {
        this.$emit('close')
      }, 150)
    },

    controlScroll(show) {
      if (!this.$isServer) {
        const bodyClasses = document.body.classList
        if (show) {
          if (!bodyClasses.contains('noscroll') && !this.preventControlScroll) {
            bodyClasses.add('noscroll')
          }
          setTimeout(() => {
            this.shown = true
          }, 150)
        } else {
          if (!this.preventControlScroll) {
            bodyClasses.remove('noscroll')
          }
        }
      }
    }
  }
}
