
export default {
  props: {
    flush: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    flushClass() {
      return this.flush ? 'zlist-group-flush' : undefined
    }
  }
}
