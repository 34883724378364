import Vue from 'vue'
import { captureError } from '~/lib/logger'
import { trackError } from '~/lib/tracking'

export default function (_, inject) {
  const $captureError = (error, context) => {
    if (process.client) {
      trackError(error, { ...context })
      captureError(error, context, { sentToDD: true })
      return
    }
    captureError(error, context)
  }

  Vue.config.errorHandler = function (err) {
    $captureError(err)
  }

  inject('captureError', $captureError)
}
