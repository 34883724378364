import { render, staticRenderFns } from "./text.vue?vue&type=template&id=6a7c48a0&scoped=true"
import script from "./text.vue?vue&type=script&lang=js"
export * from "./text.vue?vue&type=script&lang=js"
import style0 from "./text.vue?vue&type=style&index=0&id=6a7c48a0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a7c48a0",
  null
  
)

/* custom blocks */
import block0 from "~/locales/common/search/form/dates.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fvsts%2Fwork%2F1%2Fs%2Fcomponents%2Fsearch%2Fdates%2Ftext.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./text.vue?vue&type=custom&index=1&blockType=i18n&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports