import { render, staticRenderFns } from "./header.vue?vue&type=template&id=474e4bd8&scoped=true"
import script from "./header.vue?vue&type=script&lang=js"
export * from "./header.vue?vue&type=script&lang=js"
import style0 from "./header.vue?vue&type=style&index=0&id=474e4bd8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474e4bd8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoRvezy: require('/home/vsts/work/1/s/components/logo-rvezy.vue').default,ZNavbarBrand: require('/home/vsts/work/1/s/components/style-guide/navbar-brand.vue').default,Fa: require('/home/vsts/work/1/s/components/fa.vue').default,ZNavbarToggle: require('/home/vsts/work/1/s/components/style-guide/navbar-toggle.vue').default,Mobile: require('/home/vsts/work/1/s/components/app/header-nav/mobile.vue').default,Desktop: require('/home/vsts/work/1/s/components/app/header-nav/desktop.vue').default,ZNavbar: require('/home/vsts/work/1/s/components/style-guide/navbar.vue').default,DashboardNavbar: require('/home/vsts/work/1/s/components/app/dashboard-navbar.vue').default,Header: require('/home/vsts/work/1/s/components/app/header.vue').default,SearchMobileFormToggle: require('/home/vsts/work/1/s/components/search/mobile-form-toggle.vue').default,SearchMobileFiltersToggle: require('/home/vsts/work/1/s/components/search/mobile-filters-toggle.vue').default,SearchQuickFilters: require('/home/vsts/work/1/s/components/search/quick-filters.vue').default})
