import { render, staticRenderFns } from "./app-download-buttons.vue?vue&type=template&id=080e0fd4&scoped=true"
import script from "./app-download-buttons.vue?vue&type=script&lang=js"
export * from "./app-download-buttons.vue?vue&type=script&lang=js"
import style0 from "./app-download-buttons.vue?vue&type=style&index=0&id=080e0fd4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080e0fd4",
  null
  
)

/* custom blocks */
import block0 from "~/locales/common/appDownloadBanner.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fvsts%2Fwork%2F1%2Fs%2Fcomponents%2Fapp%2Fapp-download-buttons.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports