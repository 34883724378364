import { v4 as uuidv4 } from 'uuid'

export const state = () => ({
  rtSessionId: null
})

export const actions = {
  async GET_RTSESSIONID({ commit, state }) {
    await commit('SET_RTSESSIONID', state.rtSessionId || this.$cookies.get('rtsessionid') || uuidv4())
  }
}

export const mutations = {
  SET_RTSESSIONID(state, rtSessionId) {
    this.$cookies.set('rtsessionid', rtSessionId, {
      domain: this.$config.cookieDomain,
      path: '/',
      maxAge: this.$config.sessionCookieTTL
    })
    state.rtSessionId = rtSessionId
  }
}
