import { trackPageViewed } from '~/lib/tracking'

/**
 * Track pageviews on route changes.
 * This _also_ fires on initial page load.
 */
export default function ({ app, $config }) {
  app.router.afterEach((to, from) => {
    trackPageViewed({ $config, to, from })
  })
}
