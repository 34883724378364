
import { isVariant } from '~/lib/experiments/utils.js'
import { ExperimentKeys } from '~/lib/enums/index.js'

export default {
  data() {
    return {
      mobileSearchFormLoaded: false,
      showMobileSearchForm: false,
      hideDatesExperiment: true
    }
  },

  computed: {
    isDiscountDisplayFirstTimeExperiment() {
      return (
        isVariant(this.$experiment.getVariant(ExperimentKeys.rvz18749DiscountDisplayFirstTime)) &&
        this.getRouteBaseName() === 'rv-search'
      )
    }
  },

  watch: {
    '$route.query': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal && this.isDiscountDisplayFirstTimeExperiment && this.hideDatesExperiment) {
          this.hideDatesExperiment = false
        }
      }
    }
  },

  methods: {
    openMobileSearch() {
      this.mobileSearchFormLoaded = true
      this.showMobileSearchForm = !this.showMobileSearchForm
    }
  }
}
