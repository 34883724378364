
export default {
  props: {
    dates: {
      type: Object,
      default: null
    }
  },

  computed: {
    compDates() {
      return this.dates ?? this.$search.parameters.dates.dates
    },

    isDatesChanged() {
      return Boolean(this.compDates?.start && this.compDates?.end)
    },

    label() {
      if (this.isDatesChanged) {
        const start = this.$moment(this.compDates?.start)
        const end = this.$moment(this.compDates?.end)
        const sameMonth = start.month() === end.month()

        const startDateFormat = this.$t(`dates.range.start.${sameMonth ? 'short' : 'long'}`)
        const endDateFormat = this.$t(`dates.range.end.${sameMonth ? 'short' : 'long'}`)

        return `${start.format(startDateFormat)} - ${end.format(endDateFormat)}`
      }
      return this.$t('when')
    }
  }
}
