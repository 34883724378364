export const injectMaze = ({ $partytown, $config, $experiment }) => {
  const mazeFlagEnabled = $experiment.isEnabled('rvz15691MazeEnabled') ?? false

  if (!mazeFlagEnabled) {
    return
  }

  return {
    hid: 'maze',
    type: $partytown.mazeEnabled ? 'text/partytown' : 'text/javascript',
    innerHTML: injectMazeScript($config.appId)
  }
}

const injectMazeScript = (appId) => {
  return `
    (function (m, a, z, e) {
      var s, t;
      try {
        t = m.sessionStorage.getItem('maze-us');
      } catch (err) {}

      if (!t) {
        t = new Date().getTime();
        try {
          m.sessionStorage.setItem('maze-us', t);
        } catch (err) {}
      }

      s = a.createElement('script');
      s.src = z + '?t=' + t + '&apiKey=' + e;
      s.async = true;
      a.getElementsByTagName('head')[0].appendChild(s);
      m.mazeUniversalSnippetApiKey = e;
    })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', ${JSON.stringify(appId)});
  `
}
